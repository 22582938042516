import {AxiosResponse} from "axios";
import {IClient, IOkResponse, PaginatedType} from "../../../types";
import {IClientsQuery, ITopStats} from "../../../entities/client/model/types";
import {api, API_URL} from "../../config";

const BASE_PATH = "shops";

export const getClientsRequest = (
    shop_id: number,
    params: IClientsQuery
): Promise<AxiosResponse<PaginatedType<IClient>>> => {
    return api.get<PaginatedType<IClient>>(
        `${API_URL}/${BASE_PATH}/${shop_id}/loyalty/clients/list`,
        {
            params: {
                ...params,
            },
        }
    );
};

export const getTopStatsRequest = (
    period: string,
    shop_id: number
): Promise<AxiosResponse<ITopStats>> => {
    return api.get<ITopStats>(
        `${API_URL}/${BASE_PATH}/${shop_id}/loyalty/clients/topStats`,
        {
            params: {
                period,
            },
        }
    );
};

export const blockClientRequest = async (
    id: React.Key,
    shop_id: number
): Promise<AxiosResponse<IOkResponse, React.Key>> => {
    return await api.post<IOkResponse>(
        `${API_URL}/${BASE_PATH}/${shop_id}/loyalty/clients/${id}/block`
    );
};

export const unBlockClientRequest = (
    id: React.Key,
    shop_id: number
): Promise<AxiosResponse<IOkResponse, React.Key>> => {
    return api.post<IOkResponse>(
        `${API_URL}/${BASE_PATH}/${shop_id}/loyalty/clients/${id}/unblock`
    );
};

export const deleteClientRequest = (
    id: React.Key,
    shop_id: number
): Promise<AxiosResponse<IOkResponse, React.Key>> => {
    return api.delete<IOkResponse>(
        `${API_URL}/${BASE_PATH}/${shop_id}/loyalty/clients/${id}`
    );
};

// export const spentStatistic = (
//     id: number
// ): Promise<AxiosResponse<ISpentStats>> => {
//     return api.get<ISpentStats>(
//         `${API_URL}/${BASE_PATH}/${id}/cards/spendStats`
//     );
// };

// export const getPaymentHistoryRequest = (
//     query: IBaseQuery
// ): Promise<AxiosResponse<PaginatedType<IPayCardsHistory>>> => {
//     return api.get<PaginatedType<IPayCardsHistory>>(
//         `${API_URL}/cards/history`,
//         {
//             params: {
//                 ...query,
//             },
//         }
//     );
// };
