import React, {useState, useEffect} from "react";
import styled from "styled-components";
import moment from "moment";
import {Icons} from "../../assets";
import {Switch, Select, Input, DatePicker} from "antd";
import {DndProvider, useDrag, useDrop} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {authInfoSelect} from "../../entities/auth";

const ItemType = "FIELD";
const {TextArea} = Input;

interface PropsClientModal {}

moment().format();

const fields = [
    {
        id: 1,
        value: "addBonuses",
        label: "Начислить бонусы",
        basic: false,
        required: true,
    },
    {id: 2, value: "name", label: "Имя", basic: true, required: false},
    {id: 3, value: "phone", label: "Телефон", basic: true, required: false},
    {id: 4, value: "email", label: "E-mail", basic: false, required: false},
    {
        id: 5,
        value: "birthDate",
        label: "Дата рождения",
        basic: false,
        required: true,
    },
    {id: 6, value: "sex", label: "Пол", basic: false, required: true},
];

const RegFormModal: React.FC<PropsClientModal> = () => {
    const [formData, setFormData] = useState<any>(undefined);
    const [activeIndex, setActiveIndex] = useState(0);
    const {shop} = useSelector(authInfoSelect);

    const handleButtonClick = (index) => {
        setActiveIndex(index);
    };

    useEffect(() => {
        if (shop) {
            const clientsUrl = `${process.env.REACT_APP_API_URL}/shops/${shop.id}/loyalty/reg-forms/1`;
            Promise.all([
                fetch(clientsUrl, {
                    // credentials: 'include',
                    headers: {
                        accept: "application/json",
                        Authorization: `${localStorage.getItem("accessToken")}`,
                        "Content-Type": "application/json",
                    },
                })
                    .then((res) => res.json())
                    .catch(() => []),
            ]).then(([data]) => {
                data.fields = fields;
                setFormData(data);
            });
        }
    }, [shop]);

    const handleToggleOld = (checked, e) => {
        setFormData((prevState) => {
            let newState = {...prevState};
            const {fields} = newState;
            const findIndex = fields.findIndex(
                (o) => o.value === checked.value
            );
            if (findIndex !== -1) {
                fields[findIndex].required = e;
            }
            return newState;
        });
    };

    // D-N-D
    const handleToggle = (item, e) => {
        const updatedFields = formData.fields.map((field) =>
            field.id === item.id ? {...field, required: e} : field
        );
        setFormData({...formData, fields: updatedFields});
    };

    const DraggableFieldItem = ({item, index, moveField}) => {
        const ref = React.useRef(null);

        const [, drop] = useDrop({
            accept: ItemType,
            hover(draggedItem) {
                const draggedIndex = (draggedItem as {index: number}).index;
                if (draggedIndex !== index) {
                    moveField(draggedIndex, index);
                    (draggedItem as {index: number}).index = index;
                }
            },
        });

        const [{isDragging}, drag] = useDrag({
            type: ItemType,
            item: {type: ItemType, index},
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        });

        drag(drop(ref));

        return (
            <FieldItem ref={ref} style={{opacity: isDragging ? 0.5 : 1}}>
                <Icons.OptionsLines />
                <FieldName value={item.label} options={formData.fields}>
                    {item.name}
                </FieldName>
                {!item.basic && (
                    <StyledSwitch
                        checked={item.required}
                        onChange={(e) => handleToggle(item, e)}
                    />
                )}
            </FieldItem>
        );
    };

    const moveField = (fromIndex, toIndex) => {
        const updatedFields = Array.from(formData.fields);
        const [movedField] = updatedFields.splice(fromIndex, 1);
        updatedFields.splice(toIndex, 0, movedField);
        setFormData({...formData, fields: updatedFields});
    };

    const handleInputBefore = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.currentTarget.value;
        setFormData({...formData, textBefore: value});
    };

    const handleInputAfter = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.currentTarget.value;
        setFormData({...formData, textAfter: value});
    };
    return (
        <Wrapper>
            <Title>Анкета регистрации → Редактирование</Title>
            <BodyWrap>
                <SettingsWrap>
                    <SettingsBody>
                        <MenuSettings>
                            <TitleMenu>Настройки:</TitleMenu>
                            <NavWrap>
                                <NavItem
                                    $isActive={activeIndex === 0}
                                    onClick={() => handleButtonClick(0)}
                                >
                                    <span>Поля ввода</span>
                                </NavItem>
                                <NavItem
                                    $isActive={activeIndex === 1}
                                    onClick={() => handleButtonClick(1)}
                                >
                                    <span>Стилизация анкеты</span>
                                </NavItem>
                                <NavItem
                                    $isActive={activeIndex === 2}
                                    onClick={() => handleButtonClick(2)}
                                >
                                    <span>Безопасность</span>
                                </NavItem>
                                <NavItem
                                    $isActive={activeIndex === 3}
                                    onClick={() => handleButtonClick(3)}
                                >
                                    <span>Настройки</span>
                                </NavItem>
                                <Highlight
                                    style={{
                                        transform: `translateY(${
                                            activeIndex * 48 - 3
                                        }px)`,
                                    }}
                                />
                            </NavWrap>
                            <StyledButtonsWrap>
                                <StyledButtonSave>Сохранить</StyledButtonSave>
                                <Link to="/crm/reg_forms">
                                    <StyledButtonClose>
                                        Закрыть
                                    </StyledButtonClose>
                                </Link>
                            </StyledButtonsWrap>
                        </MenuSettings>
                        {activeIndex === 0 && (
                            <SettingBody0>
                                <Header>
                                    <FiledsSpan>Поля ввода</FiledsSpan>
                                    <RequiredSpan>Обязательное</RequiredSpan>
                                </Header>
                                <DndProvider backend={HTML5Backend}>
                                    <FieldsWrap>
                                        {formData &&
                                            formData.fields.map(
                                                (item, index) => (
                                                    <DraggableFieldItem
                                                        key={item.id}
                                                        item={item}
                                                        index={index}
                                                        moveField={moveField}
                                                    />
                                                )
                                            )}
                                    </FieldsWrap>
                                </DndProvider>
                                <AddButton>Добавить поле</AddButton>
                            </SettingBody0>
                        )}
                        {activeIndex === 1 && (
                            <SettingBody1>
                                <Header style={{flexDirection: "column"}}>
                                    <StyledBodyTitle>
                                        Стилизация анкеты
                                    </StyledBodyTitle>
                                    <StyledBodyDescription>
                                        Измените внешний вид анкеты под ваш
                                        бренд
                                    </StyledBodyDescription>
                                </Header>
                                <StyledBody1>
                                    <ItemsWrap1>
                                        <ItemsTitle1>
                                            Текст над анкетой
                                        </ItemsTitle1>
                                        <StyledTextArea
                                            key="textBefore"
                                            name="textBefore"
                                            value={formData.textBefore}
                                            rows={3}
                                            placeholder="Текст над анкетой"
                                            maxLength={4}
                                            onChange={handleInputBefore}
                                        />
                                    </ItemsWrap1>
                                    <ItemsWrap1>
                                        <ItemsTitle1>
                                            Текст под анкетой
                                        </ItemsTitle1>
                                        <StyledTextArea
                                            key="textAfter"
                                            name="textAfter"
                                            value={formData.textAfter}
                                            rows={3}
                                            placeholder="Текст под анкетой"
                                            maxLength={4}
                                            onChange={handleInputAfter}
                                        />
                                    </ItemsWrap1>
                                    <ItemsWrap1>
                                        <ItemsTitle1>
                                            Добавить логотип
                                        </ItemsTitle1>
                                        <StyledLoad>
                                            <span>Выбрать изображение</span>
                                        </StyledLoad>
                                    </ItemsWrap1>
                                </StyledBody1>
                            </SettingBody1>
                        )}
                        {activeIndex === 2 && (
                            <SettingBody2>
                                <Header style={{flexDirection: "column"}}>
                                    <StyledBodyTitle>
                                        Безопасность и ФЗ-152
                                    </StyledBodyTitle>
                                    <StyledBodyDescription>
                                        Укажите правила замены карт,
                                        верефикация, оферту для соблюдения
                                        ФЗ-152
                                    </StyledBodyDescription>
                                </Header>
                                <StyledBody1>
                                    <ItemsWrap2>
                                        <StyledSwitch />
                                        <ItemsTitle2>
                                            Менять номер карты после получения
                                        </ItemsTitle2>
                                    </ItemsWrap2>
                                    <ItemsWrap2>
                                        <StyledSwitch />
                                        <ItemsTitle2>
                                            Менять баркод карты после получения
                                        </ItemsTitle2>
                                    </ItemsWrap2>
                                    <ItemsWrap2>
                                        <StyledSwitch />
                                        <ItemsTitle2>
                                            Верефикация номера телефона
                                            <span>
                                                (Тарифицируется отдельно)
                                            </span>
                                        </ItemsTitle2>
                                    </ItemsWrap2>
                                    <ItemsWrap2
                                        style={{
                                            padding: 0,
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <ItemsTitle2 style={{margin: "8px"}}>
                                            Договор оферты
                                        </ItemsTitle2>
                                        <StyledOffertaWrap>
                                            <StyledInputOffertaWrap>
                                                <StyledInputOfferta placeholder="Ссылка на оферту"></StyledInputOfferta>
                                                <StyledButtonOfferta>
                                                    <span>Выбрать оферту</span>
                                                </StyledButtonOfferta>
                                            </StyledInputOffertaWrap>
                                            <FieldName2
                                                options={formData.fields}
                                            ></FieldName2>
                                        </StyledOffertaWrap>
                                    </ItemsWrap2>
                                </StyledBody1>
                            </SettingBody2>
                        )}
                        {activeIndex === 3 && (
                            <SettingBody3>
                                <Header style={{flexDirection: "column"}}>
                                    <StyledBodyTitle>
                                        Настройки анкеты
                                    </StyledBodyTitle>
                                </Header>
                                <StyledBody1>
                                    <ItemsWrap3>
                                        <StyledInput3 placeholder="Наименование анкеты"></StyledInput3>
                                    </ItemsWrap3>
                                    <ItemsWrap3>
                                        <StyledDatePicker />
                                        <StyledDatePicker />
                                    </ItemsWrap3>
                                    <ItemsWrap3>
                                        <StyledInput3 placeholder="Количество выпускаемых карт"></StyledInput3>
                                    </ItemsWrap3>
                                    <ItemsWrap3>
                                        <StyledTextArea3></StyledTextArea3>
                                    </ItemsWrap3>
                                    <ItemsWrap3>
                                        <FieldName2
                                            options={formData.fields}
                                        ></FieldName2>
                                    </ItemsWrap3>
                                    <ItemsWrap3>
                                        <StyledTextArea3></StyledTextArea3>
                                    </ItemsWrap3>
                                </StyledBody1>
                            </SettingBody3>
                        )}
                    </SettingsBody>
                </SettingsWrap>
                <ReviewWrap>
                    <ReviewHeader>
                        <ReviewLabel>Предпросмотр:</ReviewLabel>
                        <ReviewTheme>Светлая Темная</ReviewTheme>
                    </ReviewHeader>
                    {formData && (
                        <ReviewBody>
                            <TextBefore>
                                {formData && formData.textBefore}
                            </TextBefore>
                            <TextAfter>
                                {formData && formData.textAfter}
                            </TextAfter>
                            {formData.fields.map((i) => (
                                <StyledInputContainer>
                                    <input
                                        // className={error && 'errored'}
                                        type="text"
                                        name="login"
                                        value={i.text}
                                        // onChange={handleInput}
                                        required
                                    />
                                    <label
                                        className={i.text && "filled"}
                                        htmlFor="login"
                                    >
                                        {i.label}
                                    </label>
                                </StyledInputContainer>
                            ))}
                            <StyledGetAndReturnWrap>
                                <StyledGetCard>Получить карту</StyledGetCard>
                                <StyledReturn>Вернуться</StyledReturn>
                            </StyledGetAndReturnWrap>
                        </ReviewBody>
                    )}
                </ReviewWrap>
            </BodyWrap>
        </Wrapper>
    );
};

export default RegFormModal;

const StyledButtonsWrap = styled.div`
    display: flex;
    position: absolute;
    bottom: 0px;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 0px 0px 10px;
    gap: 9px;

    width: 190px;
    height: 87px;
    left: 32px;
`;

const StyledButtonSave = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 58px;
    cursor: pointer;
    width: 190px;
    height: 40px;

    /* Текст/#A7388E текст|кнопки */
    background: #a7388e;
    border-radius: 5px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
`;
const StyledButtonClose = styled.div`
    /* Закрыть */
    cursor: pointer;
    width: 59px;
    height: 28px;

    /* Текст */
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    /* identical to box height, or 200% */
    display: flex;
    align-items: center;
    text-align: center;

    /* Текст/#8F8F8F текст */
    color: #8f8f8f;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
`;
const StyledDatePicker = styled(DatePicker)`
    height: 35px;
    width: 200px;
`;
const StyledOffertaWrap = styled.div`
    display: flex;
    flex-direction: column;
`;
const StyledInputOffertaWrap = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
`;
const StyledInput3 = styled(Input)`
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;

    width: 100%;
    height: 35px;

    /* Текст/#B0B3B5 текст */
    border: 1px solid #b0b3b5;
    border-radius: 5px;
`;
const StyledInputOfferta = styled(Input)`
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;

    width: 146px;
    height: 35px;

    /* Текст/#B0B3B5 текст */
    border: 1px solid #b0b3b5;
    border-radius: 5px;
`;
const StyledButtonOfferta = styled.div`
    display: flex;
    width: 133px;
    height: 35px;
    background: #a7388e;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    /* Frame 157 */
    text-align: center;
    & span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 15px;
        color: #f7f7f7;
    }
`;
const StyledBody1 = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 8px;
`;
const StyledBodyDescription = styled.div`
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */
    display: flex;
    align-items: center;

    /* Текст/#B0B3B5 текст */
    color: #b0b3b5;
`;
const StyledBodyTitle = styled.div`
    /* Стилизация анкеты */
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    /* identical to box height, or 156% */
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    /* Текст/#8F8F8F текст */
    color: #8f8f8f;
`;
const ItemsWrap1 = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 71px;
`;
const ItemsWrap2 = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 16px;
    min-height: 50px;
`;
const ItemsWrap3 = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 16px;
    min-height: 50px;
    gap: 18px;
`;
const ItemsTitle1 = styled.div`
    margin-top: 9px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 15px;
    color: #333333;
`;

const ItemsTitle2 = styled.div`
    margin-left: 40px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 15px;
    color: #333333;
    & span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 15px;
        /* or 107% */
        display: flex;
        align-items: center;

        /* Текст/#8F8F8F текст */
        color: #8f8f8f;
    }
`;
const StyledTextArea3 = styled(TextArea)`
    min-height: 40px;
    max-height: 70px;
`;

const StyledTextArea = styled(TextArea)`
    width: 255px;
    min-height: 56px;
    max-height: 140px;
`;

const StyledLoad = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 49px;
    width: 254px;
    height: 40px;

    /* Текст/#A7388E текст|кнопки */
    background: #a7388e;
    border-radius: 5px;

    & span {
        /* Выбрать изображение */

        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
        /* identical to box height, or 200% */
        color: #ffffff;
    }
`;

const StyledGetAndReturnWrap = styled.div`
    margin: auto;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const StyledGetCard = styled.div`
    /* Получить карту */

    width: 113px;
    height: 28px;

    /* Текст */
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 28px;
    /* identical to box height, or 200% */
    display: flex;
    align-items: center;
    text-align: center;

    /* Текст/#B0B3B5 текст */
    color: #b0b3b5;

    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
`;
const StyledReturn = styled.div`
    /* Вернуться */

    width: 113px;
    height: 28px;

    /* Текст */
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 28px;
    /* identical to box height, or 200% */
    display: flex;
    align-items: center;
    text-align: center;

    /* Текст/#8F8F8F текст */
    color: #8f8f8f;

    /* Inside auto layout */
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
`;

const StyledInputContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    label {
        position: absolute;
        top: -9px;
        pointer-events: none;
        transform: translate(0px, 15px) scale(1);
        transform-origin: top left;
        transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
        color: #b0b3b5;
        font-size: 16px;
        line-height: 1;
        left: 0px;
    }
    &:focus-within label {
        transform: translate(0px, 5px) scale(0.6);
        color: #bf6dff;
    }
    input {
        /* background-color: #EEEFEF; */

        height: 32px;
        /* border-radius: 5px; */
        border: none;
        border-bottom: 1px solid #8f8f8f;
        padding: 0px 16px 0px 0px;
        font-size: 12px;
        line-height: 1;
        outline: none;
        box-shadow: none;
        transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
        font-family: Inter;
        font-size: 12px;
        font-weight: 500;
        line-height: 28px;
        text-align: left;

        &:focus {
            /* box-shadow: 0 0 0 2px #262A29; */
        }
    }

    & .errored {
        /* box-shadow: 0 0 0 2px red; */
    }

    & .filled {
        transform: translate(0px, 5px) scale(0.6);
        color: #bf6dff;
    }
`;

const AddButton = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 426px;
    height: 35px;
    margin: 20px auto;
    background: #eeefef;
    border-radius: 5px;
    cursor: pointer;
`;

const FieldName = styled(Select)`
    width: 240px;
    margin: 0px 40px;
`;
const FieldName2 = styled(Select)`
    width: 100%;
    margin: 10px 0px;
`;
const FieldItem = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 454px;
    height: 45px;
    align-items: center;
    padding: 0px 14px;
    /* & :active {
    background: #F2F2F2;

  } */
`;
const FieldsWrap = styled.div`
    display: flex;
    flex-direction: column;
`;
const FiledsSpan = styled.span`
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #8f8f8f;
    margin: 0 29px;
`;
const RequiredSpan = styled.span`
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #b0b3b5;
    margin: 0 29px;
`;
const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const SettingBody0 = styled.div`
    /* Frame 427321935 */
    display: flex;
    flex-direction: column;
    width: 480px;
    height: 486px;
    left: 279px;
    top: 18px;
    margin-bottom: 20px;
    padding: 28px 13px;
    background: #ffffff;
    box-shadow: 0px 4px 13.4px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
`;
const SettingBody1 = styled.div`
    /* Frame 427321935 */
    display: flex;
    flex-direction: column;
    width: 480px;
    height: 486px;
    left: 279px;
    top: 18px;
    margin-bottom: 20px;
    padding: 28px 13px;
    background: #ffffff;
    box-shadow: 0px 4px 13.4px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
`;
const SettingBody2 = styled.div`
    /* Frame 427321935 */
    display: flex;
    flex-direction: column;
    width: 480px;
    height: 486px;
    left: 279px;
    top: 18px;
    margin-bottom: 20px;
    padding: 28px 13px;
    background: #ffffff;
    box-shadow: 0px 4px 13.4px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
`;
const SettingBody3 = styled.div`
    /* Frame 427321935 */
    display: flex;
    flex-direction: column;
    width: 480px;
    height: 486px;
    left: 279px;
    top: 18px;
    margin-bottom: 20px;
    padding: 28px 13px;
    background: #ffffff;
    box-shadow: 0px 4px 13.4px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
`;

const TitleMenu = styled.div`
    /* Настройки: */

    width: 144px;
    height: 28px;
    margin-left: 32px;
    margin-top: 23px;
    margin-bottom: 16px;
    /* Заголовок */
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 28px;
    /* identical to box height, or 112% */
    display: flex;
    align-items: center;

    color: #f7f7f7;
`;

const Highlight = styled.div`
    /* Frame 64 */

    width: 217px;
    height: 34px;

    /* Текст/#A7388E текст|кнопки */
    background: #a7388e;
    box-shadow: 0px 3px 3.4px rgba(0, 0, 0, 0.21);
    border-radius: 5px;
    position: absolute;
    transform: translateX(0);
    transition: transform 0.3s ease;
`;

const NavItem = styled.div<{
    $isActive: boolean;
}>`
    width: 136px;
    height: 28px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    /* identical to box height, or 200% */
    display: flex;
    align-items: center;
    z-index: 1;
    /* Текст/#FFFFFF 50% текст|иконки */
    color: ${(props) =>
        props.$isActive ? "#F7F7F7" : "rgba(255, 255, 255, 0.5)"};
    transition: color 0.5s ease;
    cursor: pointer;
`;

const NavWrap = styled.div`
    /* Frame 74 */

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px 13px;
    gap: 20px;

    width: 243px;
    height: 246px;
    left: 0px;
    top: 67px;

    background: #3d277e;
    box-shadow: 0px 4px 13.4px rgba(0, 0, 0, 0.05);
    border-radius: 0px 10px 10px 0px;
`;

const MenuSettings = styled.div`
    /* Frame 427321934 */
    display: flex;
    flex-direction: column;
    width: 243px;
    height: 486px;
    left: 0px;
    top: calc(50% - 486px / 2);
    position: relative;
    /* #2E1C65 хедер|футер|иконки */
    background: #2e1c65;
    box-shadow: 0px 4px 13.4px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
`;

const SettingsBody = styled.div`
    /* Поля ввода */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 35px;
    width: 788px;
    height: 522px;
    left: 277px;
    top: 256px;
    padding: 18px;
    background: #f7f7f7;
    box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
`;

const SettingsWrap = styled.div``;

const ReviewLabel = styled.div`
    /* Предпросмотр: */
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 28px;
    /* identical to box height, or 200% */
    display: flex;
    align-items: center;
    text-transform: uppercase;

    /* Текст/#8F8F8F текст */
    color: #8f8f8f;
`;
const ReviewTheme = styled.div`
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 28px;
    /* identical to box height, or 200% */
    color: #b0b3b5;
`;
const ReviewHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 35px;
    align-items: center;
`;

const TextAfter = styled.div`
    width: 274px;
    height: 28px;
    margin-top: 20px;

    /* Текст */
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    /* identical to box height, or 200% */
    display: flex;
    align-items: center;

    /* Текст/#B0B3B5 текст */
    color: #b0b3b5;
`;

const TextBefore = styled.div`
    width: 274.35px;
    height: 28px;

    /* Заголовок */
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 28px;
    /* identical to box height, or 112% */
    text-align: center;

    /* Текст/#8F8F8F текст */
    color: #8f8f8f;
`;
const ReviewBody = styled.div`
    /* Frame 427321949 */
    display: flex;
    flex-direction: column;
    width: 332px;
    height: 529px;
    background: #ffffff;
    box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    padding: 48px 9px 48px 29px;
    overflow-y: auto; /* Добавляет вертикальную полосу прокрутки */
    overflow-x: none; /* Добавляет вертикальную полосу прокрутки */

    /* Стили для полосы прокрутки */
    &::-webkit-scrollbar {
        width: 4px; /* Ширина полосы прокрутки */
    }

    &::-webkit-scrollbar-track {
        background: #fff; /* Цвет фона полосы прокрутки */
        border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
        background: #9481cd; /* Цвет бегунка полосы прокрутки */
        border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #bf6dff; /* Цвет бегунка при наведении */
    }

    /* Стили для Firefox */
    scrollbar-width: thin; /* Толщина полосы прокрутки */
    scrollbar-color: #9481cd #fff; /* Цвета бегунка и фона полосы прокрутки */
`;
const ReviewWrap = styled.div`
    display: flex;
    flex-direction: column;
`;

const BodyWrap = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 60px;
`;
const Title = styled.div`
    /* Анкета регистрации → Редактирование */
    margin-top: 50px;
    margin-bottom: 30px;
    width: 502px;
    height: 28px;
    left: 277px;
    top: 160px;

    /* Заголовок */
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 28px;
    /* identical to box height, or 112% */
    display: flex;
    align-items: center;

    /* Текст/#333333 текст|кнопки */
    color: #333333;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* min-width: 100%; */
    background-color: #fff;
    /* box-sizing: border-box; */

    /* Auto layout */
    padding: 0px 0px 0px;

    width: 800px;
    height: calc(100vh - 120px);
    /* Текст/#FFFFFF текст|иконки|кнопки */
    background: #f7f7f7;
    /* #D6D6D6 графики */
    /* border: 0.5px solid #D6D6D6; */
    /* box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.08); */
    border-radius: 5px;
`;

const StyledSwitch = styled(Switch)`
    .ant-switch {
        width: 60px;
        height: 30px;
        border-radius: 50%;
        background-color: #cd6ee5 !important;
    }
    .ant-switch .ant-switch-checked {
        background-color: #cd6ee5 !important;
    }
    .ant-switch-inner-handle {
        width: 60px;
        height: 30px;
        border-radius: 14px;
        background-color: white;
    }
    .ant-switch-inner {
        left: calc(100% - 28px);
        background-color: ${(props) =>
            props.checked ? "#CD6EE5" : "gray"} !important;
    }
`;
