import {createContext, useContext} from "react";
import {IDateRangeType, IUser} from "../types";

export type Theme = "light" | "dark";
export interface User {
    email: string;
    roles: string;
}

export interface ApplicationInfo {
    theme: Theme;
    setTheme: (theme: Theme) => void;
    //user: IUser | null;
    // setUser: (user: IUser) => void;
    onLogin: (user: IUser) => void;
    onLogout: () => void;
    // shop: IShop | null;
    //  setShop: (shop: IShop) => void;
    inventGroups: any;
    setInventGroups: (inventGroupsItem: any) => void;
    rangeStart: string | undefined;
    setRangeStart: (date: string | undefined) => void;
    rangeEnd: string | undefined;
    setRangeEnd: (date: string | undefined) => void;
    periodDate: string | undefined;
    setSetPeriodDate: (date: string | undefined) => void;
    dateFilterMain: string | undefined;
    setDateFilterMain: (v: string | undefined) => void;
    dateRange: IDateRangeType | undefined;
    setDateRange: (v: any) => void;
    lastShift: string | undefined;
    setLastShift: (v: string | undefined) => void;
}

const ApplicationContext = createContext<ApplicationInfo>({
    theme: "light",
    setTheme: () => {},
    // user: null,
    // setUser: () => {},
    onLogin: () => {},
    onLogout: () => {},
    //  shop: null,
    //  setShop: () => {},
    inventGroups: undefined,
    setInventGroups: () => {},
    rangeStart: "2024-05-01",
    setRangeStart: () => {},
    rangeEnd: "2024-05-07",
    setRangeEnd: () => {},
    periodDate: "2024-05-07",
    setSetPeriodDate: () => {},
    dateFilterMain: "2024-05-07",
    setDateFilterMain: () => {},
    dateRange: {
        from: undefined,
        to: undefined,
    },
    setDateRange: () => {},
    lastShift: "2024-05-07",
    setLastShift: () => {},
});

export const useApplication = () => useContext(ApplicationContext);

export default ApplicationContext;
