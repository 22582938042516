import {useRef, useState, useEffect} from "react";
import styled from "styled-components";
import {IBookingObj} from "../../../types";
import {Icons} from "../../../assets";
import {useSelector} from "react-redux";
import {authInfoSelect} from "../../../entities/auth";

const apiUrl = process.env.REACT_APP_API_URL;

interface IProps {
    record: IBookingObj;
}

// {
//     request: 0,
//     declined: 1,
//     approved: 2,
//     complete: 3,
//     canceled: 4
// }

const selectOptions = [
    {key: "request", value: "request", label: "Ожидание"},
    {key: "ok", value: "ok", label: "Отклонить"},
    // { key: 'canceled', value: 'cancel', label: 'Отклонить' },
    {key: "approve", value: "approve", label: "Подтвердить"},
    {key: "complete", value: "complete", label: "Завершить"},
    {key: "cancel", value: "cancel", label: "Отказать"},
];

const StatusSelector = (props: IProps) => {
    const {shop} = useSelector(authInfoSelect);
    const {record} = props;
    const [switchIsOpen, setSwitchIsOpen] = useState(false);
    const [selectorValue, setSelectorValue] = useState<number>(
        record.state || 0
    );

    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (selectorValue !== 0 && record && shop) {
            const bookingUpdateUrl = `${apiUrl}/shops/${shop.id}/booking/${record.id}/${selectOptions[selectorValue].value}`;
            const bodyReq = {
                state: selectorValue,
            };
            fetch(bookingUpdateUrl, {
                // credentials: 'include',
                method: "POST",
                body: JSON.stringify(bodyReq),
                headers: {
                    accept: "application/json",
                    Authorization: `${localStorage.getItem("accessToken")}`,
                    "Content-Type": "application/json",
                },
            })
                .then((res) => res.json())
                .catch(() => []);
        }
    }, [selectorValue, record, shop]);

    const swithHandler = (key: number) => {
        if (key !== selectorValue) {
            setSelectorValue(key);
        }
        setSwitchIsOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                setSwitchIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    return (
        <NoiseNameContainer
            key={"container"}
            id={"container"}
            onClick={(event) => event.stopPropagation()}
        >
            <TitleLabel
                onClick={() => {
                    setSwitchIsOpen(!switchIsOpen);
                }}
            >
                {selectOptions[selectorValue].label}
            </TitleLabel>
            <NoiseSelectorBtn>
                {switchIsOpen ? <Icons.IconArrowUp /> : <Icons.IconArrowDown />}
            </NoiseSelectorBtn>
            {switchIsOpen && (
                <SelectorDropdown ref={dropdownRef}>
                    {selectOptions?.map((item, ind) => {
                        return ind === selectorValue ? null : (
                            <>
                                <Br />
                                <TitleLabel
                                    decl={item.key === "discard"}
                                    key={ind}
                                    onClick={() => swithHandler(ind)}
                                >
                                    <span>{item.label}</span>
                                </TitleLabel>
                            </>
                        );
                    })}
                </SelectorDropdown>
            )}
        </NoiseNameContainer>
    );
};

export default StatusSelector;

const Br = styled.div`
    width: 98px;
    height: 1px;
    border: 1px solid #eeefef;
`;
const TitleLabel = styled.div<{
    decl?: boolean;
}>`
    /* Frame 427322058 */

    /* box-sizing: border-box; */

    width: 133px;
    height: 38px;
    /* #8F8F8F 30% */
    /* Подтвержден */

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    /* identical to box height, or 200% */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    /* Текст/#333333 текст|кнопки */
    color: #333333;

    & span {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 28px;
        /* identical to box height, or 200% */
        color: ${({decl}) => (decl ? "#DD1A6A" : "#333333")};
    }
`;

const SelectorDropdown = styled.div`
    width: 133px;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    justify-content: center;
    z-index: 8;
    padding-top: 10px;
    align-items: center;
    top: 30px;
    left: -1px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: 1px solid rgba(143, 143, 143, 0.3);
    border-top: none;
    border-radius: 0 0 5px 5px;

    /* border-radius: 8px;
  box-shadow: 0px 0px 8px 0px rgb(158 158 158 / 40%); */

    ::-webkit-scrollbar {
        width: 14px;
        /* border-radius: 8px; */
        background-color: inherit;
    }

    ::-webkit-scrollbar-thumb {
        height: 140px;
        background-color: #e7e7e7;
        /* border-radius: 8px;
    border: 5px solid #fff; */
    }
`;

const NoiseSelectorBtn = styled.div`
    position: absolute;
    right: 6px;
    top: 4px;
    & svg path {
        fill: #a7a7a7;
    }
`;

const NoiseNameContainer = styled.div`
    /* Frame 195 */
    width: 133px;
    height: 38px;
    justify-content: space-between;
    /* Текст/#FFFFFF текст|иконки|кнопки */
    background: #ffffff;
    box-shadow: 0px 2px 15.2px rgba(0, 0, 0, 0.11);
    border-radius: 5px;
    position: relative;
    display: flex;
    font-weight: 600;
    font-size: 13px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #1f1e24;
    /* text-transform: capitalize; */
    cursor: pointer;
    border: 1px solid #d6dce9;
    border-radius: 8px;
`;
