import React, {useState, useEffect, useRef, useCallback} from "react";
import styled from "styled-components";
import moment from "moment";
import {Table, Select} from "antd";
import type {TableColumnsType, GetProp, TableProps} from "antd";
import {io, Socket} from "socket.io-client";
import {Icons} from "../../assets";
import Highlighter from "react-highlight-words";
import StatusSelector from "./components/StatusSelector";
import HeaderReservationPage from "./components/HeaderReservationPage";
import {IPromouter, IBookingObj} from "../../types";
import ReservationModal from "./components/ReservationModal";
import DialogModal from "./components/DialogModal";
import dayjs from "dayjs";
import {useSelector} from "react-redux";
import {authInfoSelect} from "../../entities/auth";

type TablePaginationConfig = Exclude<
    GetProp<TableProps, "pagination">,
    boolean
>;

const apiUrl = process.env.REACT_APP_API_URL;

interface TableParams {
    pagination?: TablePaginationConfig;
    sortField?: string;
    sortOrder?: string;
    filters?: Parameters<GetProp<TableProps, "onChange">>[1];
}

moment().format();

const ReservationPage = () => {
    const {shop} = useSelector(authInfoSelect);
    const [openedPopup, setOpenedPopup] = useState(false);
    const [activeModal, setActiveModal] = useState<string>("settings");
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<IBookingObj[] | undefined>([]);
    const [sortedData, setSortedData] = useState<IBookingObj[] | undefined>([]);
    const [selectedRecord, setSelectedRecord] = useState<
        IBookingObj | undefined
    >(undefined);
    const [searchText, setSearchText] = useState("");
    const [valueBulkSelector, setValueBulkSelector] = useState<
        string | undefined
    >(undefined);

    const [openedDialog, setOpenedDialog] = useState<boolean>(false);
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });

    const getData = useCallback(() => {
        if (shop) {
            const clientsUrl = `${apiUrl}/shops/${shop.id}/booking`;
            Promise.all([
                fetch(clientsUrl, {
                    // credentials: 'include',
                    headers: {
                        accept: "application/json",
                        Authorization: `${localStorage.getItem("accessToken")}`,
                        "Content-Type": "application/json",
                    },
                })
                    .then((res) => res.json())
                    .catch(() => []),
            ]).then(([data]) => {
                if (data && data.rows && Array.isArray(data.rows)) {
                    setData(data.rows);
                } else setData([]);
            });
        }
    }, [shop]);

    const ws = useRef<Socket | null>(null);

    useEffect(() => {
        // Проверка, если соединение уже существует
        if (!ws.current) {
            // Создание нового WebSocket-соединения
            ws.current = io(process.env.REACT_APP_SOCKET_SERVER_URL, {
                autoConnect: false,
                transports: ["websocket"],
                // path: '/socket.io',
                secure: false,
                auth: {
                    token: localStorage.getItem("accessToken"),
                },
            });

            ws.current.emit("join", 1, (res) => {
                console.log("WebSocketd", res);
            });
            ws.current.on("connect", () => {
                console.log("WebSocket connection established");
            });

            ws.current.on("disconnect", () => {
                console.log("WebSocket connection closed");
            });
            ws.current.on("connect_error", (error) => {
                console.error("WebSocket error:", error);
            });
            ws.current.emit("join", 30, (res) => {
                console.log("WebSocketd", res);
            });
            ws.current.on("booking-create", (res) => {
                console.log("booking-create", res);
                getData();
            });
            ws.current.on("booking-update", (res) => {
                console.log("booking-update", res);
                getData();
            });
            ws.current.connect();
        } else {
        }

        // Очистка при размонтировании компонента
        return () => {
            if (ws.current) {
                ws.current.close();
                ws.current = null;
            }
        };
    }, [getData]);
    useEffect(() => {
        if (data) {
            const sorted = data.sort(
                (a, b) =>
                    new Date(b.createdAt).getTime() -
                    new Date(a.createdAt).getTime()
            );
            setSortedData(sorted);
        } else setSortedData([]);
    }, [data]);

    useEffect(() => {
        if (loading) {
            getData();
            setLoading(false);

            setLoading(false);
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: 200,
                    // 200 is mock data, you should read it from server
                    // total: data.totalCount,
                },
            });
        }
    }, [loading, tableParams, getData]);

    useEffect(() => {
        setLoading(true);
    }, []);

    const getColumnSearchProps = (dataIndex) => ({
        // ... (остальные свойства)
        render: (text) =>
            searchText ? (
                <Highlighter
                    highlightStyle={{backgroundColor: "#ffc069", padding: 0}}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                text
            ),
    });

    // useEffect(() => {
    //   console.log('selectedRowKeys', selectedRowKeys);
    // }, [selectedRowKeys])

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const modalVisibilityToggler = async (
        modalType: string,
        currentItem: any
    ) => {
        setOpenedPopup(false);
        setOpenedDialog(false);
    };

    const columns: TableColumnsType = [
        {
            title: "№",
            width: 40,
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            render: (record) => (
                <a onClick={(e) => handleRowClick(record, e)}>{record.id}</a>
            ),
        },
        {
            title: "Дата",
            width: 80,
            render: (record: IBookingObj) => {
                return (
                    <span>
                        {record && record.bookingStart
                            ? dayjs(record.bookingStart).format("DD.MM.YYYY")
                            : "-"}
                    </span>
                );
            },
        },
        {
            title: "Время",
            width: 80,
            render: (record: IBookingObj) => {
                return (
                    <span>
                        {record && record.bookingStart
                            ? dayjs(record.bookingStart).format("HH:mm")
                            : "-"}
                    </span>
                );
            },
        },
        {
            title: "Имя",
            dataIndex: "personName",
            width: 140,
            sorter: (a, b) =>
                a.personName && b.personName
                    ? a.personName.localeCompare(b.personName)
                    : 0,
            ...getColumnSearchProps("personName"),
        },
        {
            title: "Телефон",
            dataIndex: "personPhone",
            width: 130,
            sorter: (a, b) => a.personPhone - b.personPhone,
            ...getColumnSearchProps("personPhone"),
        },
        {
            title: "К-во гостей",
            dataIndex: "guestsCount",
            width: 110,
            sorter: (a, b) => a.cntHostes - b.cntHostes,
            ...getColumnSearchProps("phone"),
        },
        {
            title: "Помещение",
            width: 110,
            render: (o) => {
                return (
                    <StyledRoseSpan>
                        {o.hall?.name ? o.hall.name : "не выбрано"}
                    </StyledRoseSpan>
                );
            },
        },
        {
            title: "№ стола",
            width: 110,
            render: (o) => {
                return (
                    <StyledRoseSpan>
                        {o.table?.name ? o.table.name : "не выбран"}
                    </StyledRoseSpan>
                );
            },
        },
        {
            title: "Стоимость",
            width: 90,
            // align: 'right',
            // className: 'headerCenter',
            render: (o: IBookingObj) => (
                <>
                    {o && o.prePaid ? (
                        <Sspan>
                            {new Intl.NumberFormat("ru-RU", {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                            }).format(Number(o.prePaid))}
                        </Sspan>
                    ) : (
                        <span>-</span>
                    )}
                    /
                    {o && o.table && o.table.price ? (
                        <Nspan>
                            {new Intl.NumberFormat("ru-RU", {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                            }).format(Number(o.table.price))}
                        </Nspan>
                    ) : (
                        <span>-</span>
                    )}
                </>
            ),
        },
        {
            title: "Промо",
            dataIndex: "promouter",
            width: 110,
            render: (o: IPromouter) => {
                return o ? <>{o.firstName}</> : <></>;
            },
        },
        {
            title: "Подтверждение",
            width: 110,
            render: (o: IBookingObj) => {
                switch (o.status) {
                    case "declined":
                        return (
                            <StyledNotAcceptedLabel>
                                <span>Отклонен</span>
                            </StyledNotAcceptedLabel>
                        );
                    case "approved":
                        return (
                            <StyledAcceptedLabel>
                                <span>Подтвержден</span>
                            </StyledAcceptedLabel>
                        );
                    case "complete":
                        return (
                            <StyledCompletedLabel>
                                <span>Выполнен</span>
                            </StyledCompletedLabel>
                        );
                    case "canceled":
                        return (
                            <StyledNotAcceptedLabel>
                                <span>Отказ</span>
                            </StyledNotAcceptedLabel>
                        );
                    default:
                        return <StatusSelector record={o} />;
                }
            },
        },
    ];

    const optionsSelect = [
        {value: "delete", label: "Удалить"},
        {value: "dublicate", label: "Дублировать"},
        // { value: 'confirm', label: 'Подтвердить'},
        // { value: 'decline', label: 'Отказать'},
    ];

    const onChangeBulkSelector = (value: any) => {
        setValueBulkSelector(value);
    };

    const bulkHandler = () => {
        setOpenedDialog(true);
    };
    const renderTableFooter = () => {
        return (
            <FooterWrap>
                <ActionWrap>
                    <StyledSelect
                        options={optionsSelect}
                        onChange={(v) => onChangeBulkSelector(v)}
                    />
                    <StyledActionButton onClick={bulkHandler}>
                        <span>Применить</span>
                    </StyledActionButton>
                    {/* <StyledCheckbox><span>для всех</span></StyledCheckbox> */}
                </ActionWrap>
            </FooterWrap>
        );
    };

    const handleTableChange: TableProps["onChange"] = (
        pagination,
        filters,
        sorter
    ) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        // `dataSource` is useless since `pageSize` changed
    };

    const handleRowClick = (record, e) => {
        if (record) {
            setActiveModal("settings");
            setSelectedRecord(record);
            setOpenedPopup(true);
        }
    };

    const handleNew = () => {
        setActiveModal("new");
        setOpenedPopup(true);
    };

    return (
        <Wrapper>
            <Header>
                <HeaderTitle>Бронирование</HeaderTitle>
            </Header>
            <HeaderReservationPage data={sortedData} />
            <SearchWrap>
                <SearchInput
                    placeholder="Поиск..."
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                />
                <ButtonBlok>
                    <StyledAddButton onClick={handleNew}>
                        <Icons.AddGreyPlusInCircle />
                        <span>Добавить</span>
                    </StyledAddButton>
                    {/* <StyledButton><Icons.IconSearchVect2 /></StyledButton> */}
                    <StyledButton>
                        <Icons.IconSearchVect1 />
                    </StyledButton>
                </ButtonBlok>
            </SearchWrap>
            <TableWrapper>
                <Table
                    size="small"
                    rowSelection={rowSelection}
                    columns={columns}
                    rowKey={(rec) => rec.id}
                    dataSource={sortedData}
                    onChange={handleTableChange}
                    // onRow={(record) => ({
                    //   onClick: (e) => handleRowClick(record, e),
                    // })}
                    // pagination={{ pageSize: 10, position: ['bottomCenter']  }}
                    footer={renderTableFooter}
                />
            </TableWrapper>
            <Overlay display={openedPopup || openedDialog ? "block" : "none"} />
            {openedPopup && (
                <ReservationModal
                    modalVisibilityToggler={modalVisibilityToggler}
                    activeModal={activeModal}
                    record={selectedRecord}
                />
            )}
            {openedDialog && valueBulkSelector && (
                <DialogModal
                    modalVisibilityToggler={modalVisibilityToggler}
                    data={sortedData}
                    activeDialog={valueBulkSelector}
                    selectedIds={selectedRowKeys}
                />
            )}
        </Wrapper>
    );
};

export default ReservationPage;

const FooterWrap = styled.div`
    display: flex;
    flex-direction: raw;
    padding-top: 0px;
`;

const ActionWrap = styled.div`
    display: flex;
    flex-direction: raw;
    justify-content: flex-start;
    gap: 10px;
`;
const StyledSelect = styled(Select)`
    /* Frame 148 */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* padding: 5px 10px; */
    gap: 10px;

    width: 175px;
    height: 34px;
`;
const StyledActionButton = styled.div`
    /* Frame 37 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    gap: 10px;

    width: 98px;
    height: 34px;

    /* Текст/#A7388E текст|кнопки */
    background: #a7388e;
    border-radius: 5px;

    cursor: pointer;
    & span {
        /* Применить */

        width: 78px;
        height: 28px;

        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 28px;
        /* identical to box height, or 200% */
        display: flex;
        align-items: center;

        /* #F7F7F7 основной фон */
        color: #f7f7f7;

        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 0;
    }
`;
const StyledAcceptedLabel = styled.div`
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    width: 133px;
    height: 38px;
    border: 1px solid rgba(143, 143, 143, 0.3);
    border-radius: 5px;
    align-items: center;
    & span {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 28px;
        color: green;
    }
`;
const StyledCompletedLabel = styled.div`
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    width: 133px;
    height: 38px;
    border: 1px solid rgba(143, 143, 143, 0.3);
    border-radius: 5px;
    align-items: center;
    & span {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 28px;
        color: blue;
    }
`;
const StyledNotAcceptedLabel = styled.div`
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    width: 133px;
    height: 38px;
    border: 1px solid rgba(143, 143, 143, 0.3);
    border-radius: 5px;
    align-items: center;
    & span {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 28px;
        color: #dd1a6a;
    }
`;

const StyledRoseSpan = styled.span`
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: #a7388e;
`;

const StyledAddButton = styled.div`
    display: flex;
    flex-direction: raw;
    align-items: center;
    padding: 3px 25px;
    gap: 10px;
    cursor: pointer;
    width: 170px;
    height: 34px;

    /* Текст/#FFFFFF текст|иконки|кнопки */
    background: #ffffff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.08);
    border-radius: 5px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    color: #8f8f8f;
    & svg {
        /* image 39 (Traced) */
        width: 20px;
        height: 20px;

        /* Текст/#8F8F8F текст */
        color: #8f8f8f;
    }
`;
const Sspan = styled.span`
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 28px;
    /* identical to box height, or 200% */
    text-align: right;

    /* Текст/#333333 текст|кнопки */
    color: #333333;
`;

const Nspan = styled.span`
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    /* identical to box height, or 200% */
    text-align: right;

    /* Текст/#8F8F8F текст */
    color: #8f8f8f;
`;
const SearchWrap = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;
const SearchInput = styled.input`
    width: 1135px;
    padding-left: 20px;
    /* Frame 91 */

    height: 33px;

    /* Текст/#FFFFFF текст|иконки|кнопки */
    background: #ffffff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.08);
    border-radius: 5px;

    border: none;
`;
const ButtonBlok = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 12px;
    /* width: 160px; */
`;
const StyledButton = styled.div`
    width: 33px;
    height: 33px;
    background: #cd6ee5;
    box-sizing: border-box;
    border: 0.6875px solid #cd6ee5;
    box-shadow: -1.375px 1.375px 4.2625px 1.375px rgba(0, 0, 0, 0.24);
    border-radius: 2.75px;
    padding: 5px;
    cursor: pointer;
`;

const TableWrapper = styled.div`
    padding-bottom: 20px;
    margin-top: 0px;
    /* font-size: 14px !important; */

    .ant-table-wrapper {
        min-height: 100%;
    }
    .ant-table-footer {
        position: fixed;
        bottom: 0;
    }
    .ant-table-thead {
        line-height: 50px; /* Установите такое же значение, как и высота, для вертикального центрирования содержимого */
        /* Rectangle 91 */
        height: 45px !important;
        /* font-size: 14px !important; */
        background: rgba(148, 129, 205, 0.25);
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
        border-radius: 5px 5px 0px 0px;
    }
    .ant-table-thead > tr > th::before {
        content: none !important;
    }
    .ant-table-cell {
        border-right: none !important;
        border-left: none !important; /* Убираем вертикальные границы между ячейками */
        font-size: 14px !important;
    }

    .ant-table-row .ant-table-cell {
        height: 46px !important;
        /* 79991233232 */
        /* font-family: 'Inter'; */
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
        /* identical to box height, or 200% */
        align-items: center;

        /* Текст/#333333 текст|кнопки */
        color: #333333;

        & svg {
            margin-top: 9px;
        }
    }

    & td.leftAlign {
        text-align: left !important;
        padding-left: 5px !important;
    }

    & th.headerCenter {
        text-align: center !important;
        height: 45px !important;
    }

    .ant-pagination {
        position: fixed;
        width: 140px !important;
        bottom: 10px;
        margin-bottom: 0px !important;
        right: calc((100vw - 1366px) / 2);
        /* width: 100%; */
        text-align: center;
    }
`;

const HeaderTitle = styled.div`
    /* Клиенты */
    margin-top: 38px;
    margin-bottom: 26px;
    width: 110px;
    height: 28px;
    /* Заголовок */
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 27px;
    line-height: 28px;
    /* identical to box height, or 112% */
    align-items: center;

    /* Текст/#333333 текст|кнопки */
    color: #333333;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* min-width: 100%; */
    width: 100%;
    min-height: calc(100vh - 110px);
    top: 0px;
    gap: 16px;
    /* background-color: #FFFFFF; */

    .ant-table-row {
        height: 14px !important;
        padding: 2px !important; /* Установите желаемую высоту строки */
    }

    .ant-table-cell {
        height: 14px !important;
        padding: 2px !important; /* Установите желаемую высоту строки */
    }

    .ant-table-tbody > tr > td {
        background-color: #ffffff;
        font-family: Inter;
        border: 1px solid #efefef;
        color: #333333;
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
        text-align: left;
        padding: 2px;
        height: 12px;
    }

    .ant-table-summary {
        background-color: #9481cd40;
        font-family: "Inter";
        font-size: 14px;
        font-weight: 600;
        line-height: 16.8px;
        text-align: left;
    }

    .ant-table-thead > tr > th {
        background-color: #9481cd40;
        font-family: "Inter";
        font-size: 12px;
        font-weight: 600;
        line-height: 13.2px;
        text-align: left;
    }
`;

const Header = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    top: 0px;
    margin-bottom: 30px;
    /* gap: 30px; */
`;

const Overlay = styled.div<{
    display: string;
}>`
    display: ${({display}) => display};
    position: absolute;
    top: -1px;
    left: -1px;
    z-index: 1000;
    width: 100vw;
    height: 150vh;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(1px);
    transition: width 0.3s;
`;
