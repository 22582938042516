import React, { useState } from 'react';
import { Input, Button } from 'antd';

const NameSearchFilter = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
    const [searchText, setSearchText] = useState(selectedKeys[0] || '');

    const handleSearch = () => {
        setSelectedKeys([searchText]);
        confirm();
    };

    const handleReset = () => {
        setSearchText('');
        clearFilters();
    };

    return (
        <div style={{ padding: 8 }}>
            <Input
                placeholder="Поиск по имени"
                value={searchText}
                onChange={e => setSearchText(e.target.value)}
                style={{ marginBottom: 8, display: 'block' }}
            />
            <Button
                type="primary"
                onClick={handleSearch}
                size="small"
                style={{ width: 90, marginRight: 8 }}
            >
                Поиск
            </Button>
            <Button onClick={handleReset} size="small" style={{ width: 90 }}>
                Сброс
            </Button>
        </div>
    );
};

export default NameSearchFilter;