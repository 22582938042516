import {useState, useEffect, useMemo, useRef} from "react";
import styled from "styled-components";
import {Icons} from "../../../assets";
import {useSelector} from "react-redux";
import {authInfoSelect} from "../../../entities/auth";

const selectOptions = [
    {key: "day", value: "day", label: "За день"},
    {key: "week", value: "week", label: "За неделю"},
    {key: "mounth", value: "mounth", label: "За месяц"},
    {key: "year", value: "year", label: "За год"},
];

const PromoutersHeaderInfo = () => {
    const {shop} = useSelector(authInfoSelect);
    const [selectValue, setSelectValue] = useState<string>("week");
    const [switchIsOpen, setSwitchIsOpen] = useState(false);
    const [selectorIndex, setSelectorIndex] = useState(0);

    const [data, setData] = useState<any>([]);

    const urlForRemains = useMemo(() => {
        const url = `${process.env.REACT_APP_API_URL}/shops/${shop?.id}/loyalty/clients/topStats?period=${selectValue}`;

        return url;
    }, [selectValue, shop]);

    useEffect(() => {
        Promise.all([
            fetch(urlForRemains, {
                // credentials: 'include',
                headers: {
                    accept: "application/json",
                    Authorization: `${localStorage.getItem("accessToken")}`,
                    "Content-Type": "application/json",
                },
            })
                .then((res) => res.json())
                .catch(() => []),
        ]).then(([dataFetch]) => {
            if (dataFetch) {
                setData(dataFetch);
            } else setData([]);
        });
    }, [urlForRemains]);

    const swithHandler = (key: number) => {
        setSwitchIsOpen(false);

        if (key !== selectorIndex) {
            setSelectValue(selectOptions[key].value);
            setSelectorIndex(key);
        } else {
            setSwitchIsOpen(false);
        }
    };

    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                setSwitchIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    return (
        <HeaderInfoWrap>
            {selectOptions && (
                <NoiseNameContainer
                    onClick={() => {
                        setSwitchIsOpen(!switchIsOpen);
                    }}
                >
                    <CardTextArea>
                        <CardText>Период статистики</CardText>
                        <CardTitle>
                            {selectOptions[selectorIndex].label}
                        </CardTitle>
                    </CardTextArea>
                    <NoiseSelectorBtn>
                        {switchIsOpen ? (
                            <Icons.IconArrowUp />
                        ) : (
                            <Icons.IconArrowDown />
                        )}
                    </NoiseSelectorBtn>
                    {switchIsOpen && (
                        <SelectorDropdown ref={dropdownRef}>
                            {selectOptions?.map((label, ind) => {
                                return (
                                    <SelectorNoiseNameContainer
                                        key={label.key}
                                        onClick={() => swithHandler(ind)}
                                    >
                                        <span>{label.label}</span>
                                    </SelectorNoiseNameContainer>
                                );
                            })}
                        </SelectorDropdown>
                    )}
                </NoiseNameContainer>
            )}
            <CardInfo>
                <CardTextArea>
                    <CardText>Всего</CardText>
                    <CardTitle>{data.registrations}</CardTitle>
                </CardTextArea>
            </CardInfo>
            <CardInfo>
                <CardTextArea>
                    <CardText>Ожидание</CardText>
                    <CardTitle>
                        {new Intl.NumberFormat("ru-RU", {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                        }).format(Number(data.balance))}
                    </CardTitle>
                </CardTextArea>
            </CardInfo>
            <CardInfo>
                <CardTextArea>
                    <CardText>Подтвержденные</CardText>
                    <CardTitle>
                        {new Intl.NumberFormat("ru-RU", {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                        }).format(Number(data.bonus))}
                    </CardTitle>
                </CardTextArea>
            </CardInfo>
            <CardInfo>
                <CardTextArea>
                    <CardText>Заблокированные</CardText>
                    <CardTitle>
                        {new Intl.NumberFormat("ru-RU", {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                        }).format(Number(data.discount))}
                    </CardTitle>
                </CardTextArea>
            </CardInfo>
        </HeaderInfoWrap>
    );
};

export default PromoutersHeaderInfo;

const SelectorDropdown = styled.div`
    width: 257px;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    z-index: 8;
    padding-top: 15px;
    top: 88px;
    left: 0px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 0px 0px 8px 8px;

    box-shadow: 4px 7px 10px rgba(0, 0, 0, 0.1);

    ::-webkit-scrollbar {
        width: 14px;
        border-radius: 8px;
        background-color: inherit;
    }

    ::-webkit-scrollbar-thumb {
        height: 140px;
        background-color: #e7e7e7;
    }
`;

const NoiseSelectorBtn = styled.div`
    position: absolute;
    right: 10px;
    top: 15px;
    & svg path {
        fill: #a7a7a7;
    }
`;

const SelectorNoiseNameContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: left;
    text-align: left;
    width: 257px;
    padding: 0px;
    border-radius: 8px;
    color: #1f1e24;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.02em;
    cursor: pointer;
    margin-top: 0px;
    padding-left: 10px;

    :hover {
        background-color: #f5f5f5;
    }
`;

const NoiseNameContainer = styled.div`
    /* Frame 195 */

    width: 257px;
    height: 92px;
    justify-content: space-between;
    /* Текст/#FFFFFF текст|иконки|кнопки */
    background: #ffffff;
    // box-shadow: 0px 2px 15.2px rgba(0, 0, 0, 0.11);
    border-radius: 5px;
    position: relative;
    display: flex;
    font-weight: 600;
    font-size: 13px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #1f1e24;
    /* text-transform: capitalize; */
    cursor: pointer;
    // border: 1px solid #d6dce9;
    border-radius: 8px;

    & span {
        width: 212px;
    }
`;

const HeaderInfoWrap = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* padding: 16px;
  background: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.08);
  border-radius: 5px; */
    /* gap: 60px; */
`;

const CardInfo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* width: 170px;
  height: 63px; */
`;

const CardTextArea = styled.div`
    display: flex;
    flex-direction: column;
    /* Frame 195 */
    padding-top: 12px;
    padding-left: 17px;
    padding-right: 17px;
    width: 257px;
    height: 92px;

    /* Текст/#FFFFFF текст|иконки|кнопки */
    background: #ffffff;
    box-shadow: 0px 2px 15.2px rgba(0, 0, 0, 0.11);
    border-radius: 5px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
`;

const CardTitle = styled.div`
    width: 170px;
    height: 45px;

    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 44px;
    /* identical to box height, or 202% */
    display: flex;
    align-items: center;

    /* Текст/#333333 текст|кнопки */
    color: #333333;
`;

const CardText = styled.div`
    height: 23px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 23px;
    /* identical to box height, or 164% */
    display: flex;
    align-items: center;

    /* Текст/#333333 текст|кнопки */
    color: #333333;
`;
