import {useState, useMemo, FC} from "react";
import styled from "styled-components";
import {ISelector} from "../../../../types";
import {selectOptions} from "../../../../utils/const";
import useFetchData from "../../../../hooks/useFetchData";
import NotifyPeriodSelect from "./NotifyPeriodSelect";
import {useSelector} from "react-redux";
import {authInfoSelect} from "../../../../entities/auth";

interface INotifyResponse {
    shedule: number;
    finished: number;
    cancel: number;
    sended: number;
}

const PushNotifyHeader: FC = () => {
    const [selectValue, setSelectValue] = useState<ISelector>(selectOptions[0]);
    const {shop} = useSelector(authInfoSelect);
    const urlForRemains = useMemo(() => {
        if (shop) {
            return `${process.env.REACT_APP_API_URL}/shops/${shop?.id}/loyalty/notify/topStats?period=${selectValue.value}`;
        }
    }, [selectValue, shop]);

    const {data} = useFetchData<INotifyResponse>(urlForRemains, [
        urlForRemains,
    ]);

    return (
        <HeaderInfoWrap>
            <CardInfo>
                <CardTextArea>
                    <NotifyPeriodSelect
                        options={selectOptions}
                        setSelectValue={setSelectValue}
                        value={selectValue}
                    />
                    <CardText>период статистики</CardText>
                </CardTextArea>
                <CardDesc></CardDesc>
            </CardInfo>
            <CardInfo>
                <CardTextArea>
                    <CardTitle>{data?.shedule}</CardTitle>
                    <CardText>запланировано</CardText>
                </CardTextArea>
            </CardInfo>
            <CardInfo>
                <CardTextArea>
                    <CardTitle>{data?.sended}</CardTitle>
                    <CardText>отправлено</CardText>
                </CardTextArea>
            </CardInfo>
            <CardInfo>
                <CardTextArea>
                    <CardTitle>{data?.finished}</CardTitle>
                    <CardText>доставлено</CardText>
                </CardTextArea>
            </CardInfo>
        </HeaderInfoWrap>
    );
};

export default PushNotifyHeader;

const HeaderInfoWrap = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const CardInfo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 24%;
`;

const CardTextArea = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 92px;
    background: #ffffff;
    box-shadow: 0px 2px 15.2px rgba(0, 0, 0, 0.11);
    border-radius: 5px;
    flex: none;
    order: 0;
    flex-grow: 0;
`;

const CardTitle = styled.div`
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 34px;
    align-items: center;
    padding: 16px 0px 0px 26px;
    color: #333333;
`;

const CardText = styled.div`
    font-family: "Inter";
    padding-left: 26px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    align-items: center;
    color: #8f8f8f;
`;

const CardDesc = styled.div`
    top: 16px;
    right: 10px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    align-items: center;
    text-align: right;
    color: #8f8f8f;
`;
