import {createAsyncThunk} from "@reduxjs/toolkit";
import {axiosErrorHandler} from "../../config";
import {IClient, PaginatedType} from "../../../types";
import {IClientsQuery, ITopStats} from "../../../entities/client/model/types";
import {getClientsRequest, getTopStatsRequest} from "../../services/clientsApi";

export const getClients = createAsyncThunk<
    PaginatedType<IClient>,
    {shop_id: number; params: IClientsQuery}
>(
    "@@client/getClients",
    async (
        {shop_id, params}: {shop_id: number; params: IClientsQuery},
        {rejectWithValue}
    ) => {
        try {
            const {data} = await getClientsRequest(shop_id, params);
            return data;
        } catch (err) {
            return rejectWithValue(axiosErrorHandler(err));
        }
    }
);

export const getTopStats = createAsyncThunk<
    ITopStats,
    {period: string; shop_id: number}
>(
    "@@client/getTopStats",
    async (
        {period, shop_id}: {period: string; shop_id: number},
        {rejectWithValue}
    ) => {
        try {
            const {data} = await getTopStatsRequest(period, shop_id);
            return data;
        } catch (err) {
            return rejectWithValue(axiosErrorHandler(err));
        }
    }
);
