import {styled} from "styled-components";
import {Icons} from "../../../../assets";
import {Breakpoint, TableColumnsType, Tooltip} from "antd";
import moment from "moment";
import {ManOutlined, WomanOutlined} from "@ant-design/icons";
import {IClient} from "../../../../types";
import {StyledTemplate} from "./StyledTemplate";
import {ITemaplate} from "../../../../entities/card/types";

export const getClientColumns = (
    handlerClickId: (
        id: number,
        e: React.MouseEvent<HTMLSpanElement, MouseEvent>
    ) => void,
    screens: Partial<Record<Breakpoint, boolean>>
): TableColumnsType<IClient> => {
    return [
        {
            title: "Статус",
            dataIndex: "isActive",
            align: "center",
            width: "6%",
            fixed: screens.xs ? "left" : false,
            responsive: ["xl", "lg", "md", "sm", "xs"],
            filters: [
                {text: "Заблокирован", value: 0},
                {text: "Активен", value: 1},
            ],
            render: (act) => (
                <span>
                    {act ? <Icons.CircleCheck /> : <Icons.CircleQuestion />}
                </span>
            ),
        },
        {
            title: "№",
            dataIndex: "id",
            width: "6%",
            responsive: ["xl", "lg", "md", "sm", "xs"],
            fixed: screens.xs ? "left" : false,
            sorter: true,
            render: (id) => (
                <StyledLink
                    onClick={(e) => handlerClickId(id, e)}
                    role="button"
                    tabIndex={0}
                >
                    {id}
                </StyledLink>
            ),
        },

        {
            title: "Создано",
            dataIndex: "createdAt",
            responsive: ["xl", "lg", "md", "sm", "xs"],
            width: "9%",
            render: (record) => {
                return (
                    <span>
                        {record ? moment.utc(record).format("DD.MM.YYYY") : "-"}
                    </span>
                );
            },
        },
        {
            title: "ФИО",
            dataIndex: "fio",
            responsive: ["xl", "lg", "md", "sm", "xs"],
            width: "15%",
            sorter: true,
            render: (fio) => {
                const [lastName, firstName] = fio.split(" ");
                return <CSpan>{`${lastName} ${firstName}`}</CSpan>;
            },
        },
        {
            title: "E-mail",
            dataIndex: "email",
            responsive: ["xl", "lg", "md", "sm", "xs"],
            width: "16%",
            sorter: true,
            render: (email) => {
                return (
                    <Tooltip title={email}>
                        {email?.length > 25
                            ? `${email.slice(0, 22)}...`
                            : email}
                    </Tooltip>
                );
            },
        },
        {
            title: "Пол",
            dataIndex: "sex",
            responsive: ["xl", "lg", "md", "sm", "xs"],
            width: "4%",
            filters: [
                {
                    text: "муж",
                    value: 1,
                },
                {
                    text: "жен",
                    value: 2,
                },
            ],
            onFilter: (value, record) => record.sex === value,
            render: (sex) => {
                return sex === 1 ? (
                    <ManOutlined style={{color: "#52c41a"}} />
                ) : (
                    <WomanOutlined style={{color: "#eb2f96"}} />
                );
            },
        },
        {
            title: "Телефон",
            dataIndex: "phone",
            responsive: ["xl", "lg", "md", "sm", "xs"],
            width: "9%",
            sorter: true,
        },
        {
            title: "Макет",
            width: "20%",
            dataIndex: "templates",
            responsive: ["xl", "lg", "md", "sm", "xs"],
            render: (templates: ITemaplate[]) => (
                <>
                    {templates.map((template, ind) => (
                        <StyledTemplate
                            key={template.id}
                            name={template.name as string}
                            ind={ind}
                        />
                    ))}
                </>
            ),
        },
        {
            title: "Дата рожд..",
            width: "10%",
            responsive: ["xl", "lg", "md", "sm", "xs"],
            dataIndex: "birthdate",
            sorter: true,
        },
        {
            title: "Бонусы",
            dataIndex: "bonus",
            responsive: ["xl", "lg", "md", "sm", "xs"],
            width: "6%",
            render: (o: string[]) => (
                <>
                    {o ? (
                        <Sspan>
                            {new Intl.NumberFormat("ru-RU", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }).format(Number(o))}
                        </Sspan>
                    ) : (
                        <Nspan>
                            {new Intl.NumberFormat("ru-RU", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }).format(Number(0))}
                        </Nspan>
                    )}
                </>
            ),
        },
        {
            title: "Баланс",
            dataIndex: "balance",
            responsive: ["xl", "lg", "md", "sm", "xs"],
            width: "6%",
            render: (o: string[]) => (
                <>
                    {o ? (
                        <Sspan>
                            {new Intl.NumberFormat("ru-RU", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }).format(Number(o))}
                        </Sspan>
                    ) : (
                        <Nspan>
                            {new Intl.NumberFormat("ru-RU", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }).format(Number(0))}
                        </Nspan>
                    )}
                </>
            ),
        },
    ];
};

const Sspan = styled.span`
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 28px;
    text-align: right;
    color: #333333;
`;

const Nspan = styled.span`
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    text-align: right;
    color: #8f8f8f;
`;

const StyledLink = styled.a`
    cursor: pointer;
`;
const CSpan = styled.div`
    // border: 1px solid red;
`;
