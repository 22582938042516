import { ENotifyStatus } from '../enums'

export const renderNotifyStatus = (status: number) => {
  switch(status) {
    case 0 : 
        return ENotifyStatus.READY
    case 1:
        return ENotifyStatus.RUNNED
    case  2:
        return ENotifyStatus.CANCELLED
    case 3:
        return ENotifyStatus.FINISHED
    default:
        return ENotifyStatus.READY
    
  }
}