import React, {useRef, useState, useEffect} from "react";
import styled from "styled-components";

import {Icons} from "../../../assets";

interface IISelector {
    key: string;
    value: string;
    label: string;
}

interface IProps {
    options: IISelector[];
    setSelectValue: any;
    value: IISelector;
}

const PeriodSelector = (props: IProps) => {
    const {options, setSelectValue, value} = props;
    const [switchIsOpen, setSwitchIsOpen] = useState(false);

    const dropdownRef = useRef<HTMLDivElement>(null);

    const swithHandler = (key: IISelector) => {
        if (key.value !== value.value) {
            setSelectValue(key);
        }
        setSwitchIsOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                setSwitchIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    return (
        <SelectorWrap switch={switchIsOpen}>
            <TitleLabel
                onClick={() => {
                    setSwitchIsOpen(!switchIsOpen);
                }}
            >
                {value.label}
            </TitleLabel>
            <NoiseSelectorBtn>
                {switchIsOpen ? <Icons.IconArrowUp /> : <Icons.IconArrowDown />}
            </NoiseSelectorBtn>
            {switchIsOpen && (
                <SelectorDropdown ref={dropdownRef}>
                    {options?.map((item) => {
                        return false ? null : (
                            <React.Fragment key={item.key}>
                                <TitleLabel
                                    key={item.key}
                                    onClick={() => swithHandler(item)}
                                >
                                    <span>{item.label}</span>
                                </TitleLabel>
                            </React.Fragment>
                        );
                    })}
                </SelectorDropdown>
            )}
        </SelectorWrap>
    );
};

export default PeriodSelector;

const TitleLabel = styled.div`
    /* За сегодня */
    padding-left: 16px;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 34px;
    display: flex;
    align-items: center;

    /* Текст/#333333 текст|кнопки */
    color: #333333;
    width: 100%;
    height: 38px;
    /* #8F8F8F 30% */
    /* Подтвержден */

    justify-content: flex-start;
    text-align: center;

    /* Текст/#333333 текст|кнопки */
    color: #333333;

    & span {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 28px;
        /* identical to box height, or 200% */
        color: #333333;
    }
`;

const SelectorDropdown = styled.div`
    width: 100%;
    max-height: 300px;
    /* overflow-y: auto; */
    /* overflow-x: hidden; */
    position: absolute;
    justify-content: center;
    z-index: 8;
    padding-left: 10px;
    align-items: center;
    /* top: 30px; */
    display: flex;
    flex-direction: column;
    background-color: #fff;
    /* border: 1px solid rgba(143, 143, 143, 0.3);
  border-top: none;
  border-radius: 0 0 8px 8px; */
    border-radius: 8px;
    box-shadow: 0px 0px 8px 0px rgb(158 158 158 / 40%);
    ::-webkit-scrollbar {
        width: 14px;
        /* border-radius: 8px; */
        background-color: inherit;
    }

    ::-webkit-scrollbar-thumb {
        height: 140px;
        background-color: #e7e7e7;
        /* border-radius: 8px;
    border: 5px solid #fff; */
    }
`;

const NoiseSelectorBtn = styled.div`
    position: absolute;
    right: 16px;
    top: 4px;
    & svg path {
        fill: #a7a7a7;
    }
`;

const SelectorWrap = styled.div<{
    switch: boolean;
}>`
    /* Frame 195 */
    width: 100%;
    height: 38px;
    justify-content: flex-start;
    /* Текст/#FFFFFF текст|иконки|кнопки */
    background: #ffffff;
    /* box-shadow: ${(props) =>
        props.switch ? "0px 2px 15.2px rgba(0, 0, 0, 0.11)" : "none"}; */
    /* border-top: ${(props) =>
        props.switch ? "1px solid #D6DCE9" : "none"};  ;
  border-left: ${(props) => (props.switch ? "1px solid #D6DCE9" : "none")};  ;
  border-right: ${(props) =>
        props.switch ? "1px solid #D6DCE9" : "none"};  ; */
    border-radius: 8px 8px 0px 0px;
    position: relative;
    right: 16px;
    display: flex;
    font-weight: 600;
    font-size: 13px;
    line-height: 26px;
    /* letter-spacing: 0.02em; */
    color: #1f1e24;
    /* text-transform: capitalize; */
    cursor: pointer;
`;
