import React, {useState, useEffect} from "react";
import styled from "styled-components";
import moment from "moment";
import {Table, Typography, Select, Checkbox, Switch} from "antd";
import type {TableColumnsType, GetProp, TableProps} from "antd";
// import ClientModal from './components/ClientModal'
import {Icons} from "../../assets";
import Highlighter from "react-highlight-words";
import HeaderInfo from "./components/HeaderInfo";
import {authInfoSelect} from "../../entities/auth";
import {useSelector} from "react-redux";

type TablePaginationConfig = Exclude<
    GetProp<TableProps, "pagination">,
    boolean
>;

interface TableParams {
    pagination?: TablePaginationConfig;
    sortField?: string;
    sortOrder?: string;
    filters?: Parameters<GetProp<TableProps, "onChange">>[1];
}

moment().format();

const AfishiPage = () => {
    const {shop} = useSelector(authInfoSelect);
    const [openedSettingsPopup, setOpenedSettingsPopup] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<any[]>([]);
    const [dataEvents, setDataEvents] = useState<any[]>([]);
    const [unformDataEvents, setUnformDataEvents] = useState<any>([]);
    const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
    const [sortedData, setSortedData] = useState<any[]>([]);
    const [searchText, setSearchText] = useState("");
    const [selectValue, setSelectValue] = useState<number>(1);

    const getFetshdata = async (url: string, setF: any) => {
        return Promise.all([
            await fetch(url, {
                // credentials: 'include',
                headers: {
                    accept: "application/json",
                    Authorization: `${localStorage.getItem("accessToken")}`,
                    "Content-Type": "application/json",
                },
            })
                .then((res) => res.json())
                .catch((err) => {
                    console.log(err);

                    return [];
                }),
        ]).then(([data]) => {
            if (data) {
                setF(data);
            }
        });
    };

    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });

    useEffect(() => {
        setSortedData(data);
    }, [data]);

    useEffect(() => {
        if (unformDataEvents && unformDataEvents.rows)
            setDataEvents(unformDataEvents.rows);
    }, [unformDataEvents]);

    useEffect(() => {
        if (loading && shop) {
            setLoading(false);
            const promoUrl = `${process.env.REACT_APP_API_URL}/shops/${shop.id}/promo`;
            getFetshdata(promoUrl, setData);
            const eventsUrl = `${process.env.REACT_APP_API_URL}/shops/${shop.id}/promo/events`;
            getFetshdata(eventsUrl, setUnformDataEvents);
            setLoading(false);
            // setTableParams({
            //     ...tableParams,
            //     pagination: {
            //       ...tableParams.pagination,
            //       total: 200,
            //       // 200 is mock data, you should read it from server
            //       // total: data.totalCount,
            //     },
            //   });
        }
    }, [loading, shop]);

    useEffect(() => {
        setLoading(true);
    }, []);

    const getColumnSearchProps = (dataIndex) => ({
        // ... (остальные свойства)
        render: (text) =>
            searchText ? (
                <Highlighter
                    highlightStyle={{backgroundColor: "#ffc069", padding: 0}}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                text
            ),
    });

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        renderCell: (checked, record, index, originNode) => (
            <StyledRowSelection>
                {originNode}
                <span>
                    <Icons.ShelterGray />
                </span>
            </StyledRowSelection>
        ),
    };

    const columns: TableColumnsType = [
        {
            title: "Дата",
            dataIndex: "startDate",
            width: 120,
            render: (record) => {
                return (
                    <span>
                        {record ? moment.utc(record).format("DD.MM.YYYY") : "-"}
                    </span>
                );
            },
        },

        {
            title: "Событие",
            dataIndex: "name",
            width: 250,
            sorter: (a, b) => (a.fio && b.fio ? a.fio.localeCompare(b.fio) : 0),
            ...getColumnSearchProps("name"),
        },
        {
            title: "Описание",
            dataIndex: "desc",
            width: 400,
            ...getColumnSearchProps("desc"),
            render: (text, record, index) => (
                <ExpWrap key={index}>
                    <Typography.Paragraph
                        ellipsis={{
                            rows: 1,
                            expandable: true,

                            expanded: expandedIndex === index,
                            onExpand: (_, info) =>
                                setExpandedIndex(info.expanded ? index : null),
                        }}
                        copyable
                    >
                        {text}
                    </Typography.Paragraph>
                    {/* <Button onClick={() => setExpandedIndex(expandedIndex === index ? null : index)}>
          {expandedIndex === index ? 'Меньше' : 'Больше'}
        </Button> */}
                </ExpWrap>
            ),
        },
        {
            title: "Дресс-код",
            dataIndex: "drescode",
            width: 120,
            align: "center",
            render: (value) => {
                return (
                    <Switch
                        checked={value}
                        // onChange={() => setExpanded((c) => !c)}
                        style={{flex: "none"}}
                    />
                );
            },
        },
        {
            title: "Контент",
            dataIndex: "phone",
            width: 120,
            ...getColumnSearchProps("phone"),
        },
    ];

    const optionsSelect = [
        {value: 1, label: "Заблокировать / активировать"},
        {value: 2, label: "Удалить"},
        {value: 3, label: "Пополнить бонусы"},
        {value: 4, label: "Списать бонусы"},
        {value: 5, label: "Отправить уведомление"},
    ];

    const onChangeSelect = (value) => {
        setSelectValue(value);
    };
    const renderTableFooter = () => {
        return (
            <FooterWrap>
                <ActionWrap>
                    <StyledSelect
                        options={optionsSelect}
                        value={selectValue}
                        onChange={onChangeSelect}
                    />
                    <StyledActionButton>
                        <span>Применить</span>
                    </StyledActionButton>
                    <StyledCheckbox>
                        <span>для всех</span>
                    </StyledCheckbox>
                </ActionWrap>
                {/* Отфильтровано: {sortedData.length}/{data.length} |
      Выделено: {selectedRowKeys.length} */}
            </FooterWrap>
        );
    };

    const handleTableChange: TableProps["onChange"] = (
        pagination,
        filters,
        sorter
    ) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        // `dataSource` is useless since `pageSize` changed
    };

    const overlayOnClick = () => {
        setOpenedSettingsPopup(false);
    };

    return (
        <Wrapper>
            <Header>
                <HeaderTitle>Афиши</HeaderTitle>
            </Header>
            <HeaderInfo promoInfo={data} />
            <SearchWrap>
                <SearchInput
                    placeholder="Поиск..."
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                />
                <ButtonBlok>
                    <StyledButton>
                        <Icons.AddGreyPlusInCircle />
                        Добавить
                    </StyledButton>
                </ButtonBlok>
            </SearchWrap>
            <TableWrapper>
                <Table
                    size="small"
                    rowSelection={rowSelection}
                    columns={columns}
                    rowKey={(rec) => rec.id}
                    dataSource={dataEvents}
                    onChange={handleTableChange}
                    pagination={{pageSize: 10}}
                    footer={renderTableFooter}
                />
            </TableWrapper>
            <Overlay
                onClick={overlayOnClick}
                display={openedSettingsPopup ? "block" : "none"}
            />
            {/* {openedSettingsPopup && (
        <ClientModal selectedClient={selectedClient} setOpeningSettingsPopup={setOpenedSettingsPopup} />
      )} */}
        </Wrapper>
    );
};

export default AfishiPage;

const ExpWrap = styled.div`
    gap: 12px;
    max-width: 400px;
`;

const FooterWrap = styled.div`
    display: flex;
    flex-direction: raw;
`;

const StyledRowSelection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    & svg {
        margin-left: 4px;
        /* color: #000; */
        width: 20px;
        /* fill: black; */
    }
`;

const ActionWrap = styled.div`
    display: flex;
    flex-direction: raw;
    justify-content: flex-start;
    gap: 10px;
`;

const StyledSelect = styled(Select)`
    /* Frame 148 */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* padding: 5px 10px; */
    gap: 10px;

    width: 175px;
    height: 38px;
`;
const StyledActionButton = styled.div`
    /* Frame 37 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    gap: 10px;

    width: 98px;
    height: 38px;

    /* Текст/#A7388E текст|кнопки */
    background: #a7388e;
    border-radius: 5px;

    cursor: pointer;
    & span {
        /* Применить */

        width: 78px;
        height: 28px;

        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 28px;
        /* identical to box height, or 200% */
        display: flex;
        align-items: center;

        /* #F7F7F7 основной фон */
        color: #f7f7f7;

        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 0;
    }
`;
const StyledCheckbox = styled(Checkbox)`
    display: flex;
    align-items: center;
`;

const SearchWrap = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;
const SearchInput = styled.input`
    width: 1135px;
    padding-left: 20px;
    /* Frame 91 */

    height: 33px;

    /* Текст/#FFFFFF текст|иконки|кнопки */
    background: #ffffff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.08);
    border-radius: 5px;

    border: none;
`;
const ButtonBlok = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* width: 160px; */
`;
const StyledButton = styled.div`
    /* Frame 193 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 3px 9px;
    gap: 11px;

    width: 216px;
    height: 34px;

    /* Текст/#FFFFFF текст|иконки|кнопки */
    background: #ffffff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.08);
    border-radius: 5px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

    cursor: pointer;
    & span {
    }

    & svg {
        color: #8f8f8f;
        width: 20px;
    }
`;

const TableWrapper = styled.div`
    margin-top: 10px;
    .ant-table-thead {
        height: 50px; /* Вы можете установить желаемую высоту */
        line-height: 50px; /* Установите такое же значение, как и высота, для вертикального центрирования содержимого */
        /* Rectangle 91 */

        background: rgba(148, 129, 205, 0.25);
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
        border-radius: 5px 5px 0px 0px;
    }
    .ant-table-thead > tr > th::before {
        content: none !important;
    }
    .ant-table-cell {
        border-right: none !important;
        border-left: none !important; /* Убираем вертикальные границы между ячейками */
    }

    .ant-table-cell {
        font-size: 14px !important;
    }
    .ant-table-row .ant-table-cell {
        height: 46px !important;
        /* 79991233232 */
        /* font-family: 'Inter'; */
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
        /* identical to box height, or 200% */
        align-items: center;

        /* Текст/#333333 текст|кнопки */
        color: #333333;

        & svg {
            margin-top: 9px;
        }
    }

    & td.leftAlign {
        text-align: left !important;
        padding-left: 5px !important;
    }

    & th.headerCenter {
        text-align: center !important;
    }
`;

const HeaderTitle = styled.div`
    /* Клиенты */
    margin-top: 38px;
    width: 110px;
    height: 28px;
    /* Заголовок */
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 28px;
    /* identical to box height, or 112% */
    align-items: center;

    /* Текст/#333333 текст|кнопки */
    color: #333333;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* min-width: 100%; */
    width: 100%;
    min-height: 90%;
    top: 0px;
    gap: 16px;
    background-color: #f7f7f7;

    .ant-table-row {
        height: 14px !important;
        padding: 2px !important; /* Установите желаемую высоту строки */
    }

    .ant-table-cell {
        height: 14px !important;
        padding: 2px !important; /* Установите желаемую высоту строки */
    }

    .ant-table-tbody > tr > td {
        background-color: #ffffff;
        font-family: Inter;
        border: 1px solid #efefef;
        color: #333333;
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
        text-align: left;
        padding: 2px;
        height: 12px;
    }

    .ant-table-summary {
        background-color: #9481cd40;
        font-family: "Inter";
        font-size: 14px;
        font-weight: 600;
        line-height: 16.8px;
        text-align: left;
    }

    .ant-table-thead > tr > th {
        background-color: #9481cd40;
        font-family: "Inter";
        font-size: 12px;
        font-weight: 600;
        line-height: 13.2px;
        text-align: left;
    }
`;

const Header = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    top: 0px;
    margin-bottom: 20px;
    /* gap: 30px; */
`;

const Overlay = styled.div<{
    display: string;
}>`
    display: ${({display}) => display};
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(1px);
`;
