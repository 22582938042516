import {useEffect, useState} from "react";
import {Menu, Dropdown, Avatar} from "antd";
import {UserOutlined, LogoutOutlined, SettingFilled} from "@ant-design/icons";
import {styled} from "styled-components";
import {Icons} from "../../assets";
import useAuth from "../../hooks/useAuth";
import {Link, useNavigate} from "react-router-dom";
import {IUser} from "../../types";
import {AppDispatch} from "../../config/store";
import {useDispatch} from "react-redux";
import {handleRemoveToken} from "../../entities/auth";

const DropdownMenu = () => {
    const dispatch = useDispatch<AppDispatch>();
    const [visible, setVisible] = useState(false);
    const {setAuth}: any = useAuth();
    const [userInfo, setUserInfo] = useState<IUser | null>(null);
    const navigate = useNavigate();

    const handleMenuClick = (e) => {
        if (e.key === "logout") {
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("user");
            localStorage.removeItem("role");
            localStorage.removeItem("shop");
            dispatch(handleRemoveToken());
            // Перенаправление на страницу логинизации
            setAuth(undefined);
            navigate("/login");
        }
    };

    useEffect(() => {
        const user = localStorage.getItem("user");
        if (user) {
            setUserInfo(JSON.parse(user));
        }
    }, [setUserInfo]);

    const menu = (
        <WrapDropdown>
            <WrapUserCard>
                <Icons.IconHumanAddFill />
                <WrapTitle>
                    <SubTitleCard>{userInfo?.title}</SubTitleCard>
                    <TitleCard>{`${userInfo?.firstname} ${userInfo?.lastname}`}</TitleCard>
                    <StyledButton>
                        <span>
                            <Link
                                style={{
                                    textDecoration: "none",
                                    color: "inherit",
                                }}
                                to="/settings"
                            >
                                Редактировать профиль
                            </Link>
                        </span>
                    </StyledButton>
                </WrapTitle>
            </WrapUserCard>
            <Menu onClick={handleMenuClick}>
                {/* <Menu.Item key="addBalance" icon={<WalletOutlined />}>
                    Пололнить баланс
                </Menu.Item> */}
                {/* <Menu.Item key="notification" icon={<BellFilled />}>
                    Уведомления
                </Menu.Item> */}
                <Menu.Item key="help" icon={<UserOutlined />}>
                    <a
                        href="https://t.me/proga_support"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Помощь менеджера
                    </a>
                </Menu.Item>
                <Menu.Divider />
                {/* <Menu.Item key="faq" icon={<UserOutlined />}>
                    Справка
                </Menu.Item> */}
                <Menu.Item key="settings" icon={<SettingFilled />}>
                    <Link to="/settings"> Настройки</Link>
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item key="logout" icon={<LogoutOutlined />}>
                    Выйти
                </Menu.Item>
            </Menu>
        </WrapDropdown>
    );

    return (
        <Dropdown overlay={menu} onOpenChange={setVisible} open={visible}>
            <Avatar style={{cursor: "pointer"}} icon={<UserOutlined />} />
        </Dropdown>
    );
};

export default DropdownMenu;

const WrapDropdown = styled.div`
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 100%;
    top: 55px;
    left: 1343px;
    gap: 0px;
    border-radius: 5px;
    border: 0.5px 0px 0px 0px;
    opacity: 0px;
    background: #ffffff;

    border: 0.5px solid #d6d6d6;
    /* padding: 30px 28px; */
    box-shadow: 0px 2px 15.2px 0px #0000001c;
`;

const WrapUserCard = styled.div`
    height: 180px;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    padding: 30px 28px 0px 28px;

    & svg path {
        fill: #e0e0e0;
        scale: 1.8;
    }

    & svg {
        width: 56px;
        height: 56px;
    }
`;

const WrapTitle = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
`;

const TitleCard = styled.div`
    //width: 126px;
    //height: 28px;
    opacity: 0px;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
`;
const SubTitleCard = styled.div`
    opacity: 0px;
    font-family: "Inter";
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
`;

const StyledButton = styled.div`
    width: 166px;
    height: 30px;
    gap: 0px;
    border-radius: 5px;
    opacity: 0px;
    padding-top: 2px;
    text-align: center;

    background: #a7388e;

    & span {
        font-family: "Inter";
        font-size: 12px;
        font-weight: 400;
        line-height: 28px;
        text-align: center;
        color: #ffffff;
    }
`;
