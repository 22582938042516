import { styled } from 'styled-components'
import { Table, TableColumnsType } from 'antd';
import { columnsPromoReportTable } from './columns';

interface TableData {
  key: React.Key;
  SUM: number;
  CURRENCYTYPE?: string;
  CURRENCY?: string;
  ORIGINALSUN?: number;
  SHIFTDATE?: string;
  nested?: TableData[];
}

const columns: TableColumnsType<TableData> = columnsPromoReportTable;

const PromoReportTable = ({ dataValues }) => {
  
  return (
    <StyledTable>
      <Table
        pagination={false}
        dataSource={dataValues}
        columns={columns}
        size="small"
        summary={(pageData) => {
          let totalAmount10 = 0;
          let totalAmount15 = 0;
          let totalAmount20 = 0;
          let totalAmount25 = 0;
          let totalAmount30 = 0;
          pageData.forEach(({ amount10, amount15, amount20, amount25, amount30, amount35, amount}: any) => {
            totalAmount10 += amount10;
            totalAmount15 += amount10 * amount15 / 100;
            totalAmount20 += amount20;
            totalAmount25 += amount20 * amount25 / 100;
            totalAmount30 += amount30;
          });

        return (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>Всего</Table.Summary.Cell>
              <Table.Summary.Cell index={1}><StyledSum>{new Intl.NumberFormat('ru-RU').format(Number(totalAmount10))}</StyledSum></Table.Summary.Cell>
              <Table.Summary.Cell index={1}><StyledSum>{new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(totalAmount15 / totalAmount10 * 100))} %</StyledSum></Table.Summary.Cell>
              <Table.Summary.Cell index={2}><StyledSum>{new Intl.NumberFormat('ru-RU').format(Number(totalAmount20))}</StyledSum></Table.Summary.Cell>
              <Table.Summary.Cell index={1}><StyledSum>{new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(totalAmount25 / totalAmount20 * 100))} %</StyledSum></Table.Summary.Cell>
              <Table.Summary.Cell index={3}><StyledSum>{new Intl.NumberFormat('ru-RU').format(Number(totalAmount30))}</StyledSum></Table.Summary.Cell>
            </Table.Summary.Row>
           
          </>
        );
      }}
      />
    </StyledTable>
	)
}

export default PromoReportTable;


const StyledTable = styled.div`
  .ant-table-thead > tr > th {
    background-color: #9481CD40;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    text-align: left;

  }

  .ant-table-footer {
    background-color: #9481CD40;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    text-align: left;

  }

  
  table>tbody>tr>td{
    background-color: #ffffff;
    font-family: Inter;
    border: 1px solid #EFEFEF;
    color: #333333;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
    height: 30px;

  }

  .ant-table-summary {
    background-color: #9481CD40;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    text-align: left;

  }

  
  
`

const StyledSum = styled.div`
  text-align: right;
  padding-right: 20%;
`
