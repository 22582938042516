import {CSSProperties} from "react";
import {IOption, ISelector} from "../../types";
import {EClientsAction, ENotifyAction, ENotifyType} from "../enums";

export const limitNotify = 25;
export const limitClients = 50;
export const limitCardTrans = 6;
export const limitCardChanges = 2;

export const clientNotifyOptions: IOption[] = [
    {value: 0, label: "Клиентам", key: ENotifyType.CLIENT_ID},
    {value: 1, label: "Обладателям макетов", key: ENotifyType.TEMPLATE_ID},
    {value: 2, label: "Обладателям карт", key: ENotifyType.CARD_ID},
    {value: 3, label: "Всем клиентам", key: ENotifyType.ALL},
];

export const optionsSelect: {value: number; label: EClientsAction}[] = [
    {value: 1, label: EClientsAction.BLOCK},
    {value: 2, label: EClientsAction.ACTIVE},
    {value: 3, label: EClientsAction.SEND_NOTIFICATION},
    {value: 4, label: EClientsAction.ADD_BONUSES},
    {value: 5, label: EClientsAction.DEDUCT_BONUSES},
    {value: 6, label: EClientsAction.DELETE},
];

export const optionsNotifySelect: {value: number; label: ENotifyAction}[] = [
    {value: 1, label: ENotifyAction.CANCEL},
    {value: 2, label: ENotifyAction.DELETE},
    {value: 3, label: ENotifyAction.DUBBLE},
    {value: 4, label: ENotifyAction.EDIT},
];

export const selectOptions: ISelector[] = [
    {key: "day", value: "day", label: "За сегодня"},
    {key: "week", value: "week", label: "За неделю"},
    {key: "mounth", value: "mounth", label: "За месяц"},
    {key: "year", value: "year", label: "За год"},
];

export const getHeader = (
    method: "GET" | "POST" | "PUT" | "PATCH",
    data?: unknown
) => {
    const header: RequestInit = {
        method,
        headers: {
            accept: "application/json",
            Authorization: `${localStorage.getItem("accessToken") || ""}`,
            "Content-Type": "application/json",
        },
    };

    if (data) {
        header.body = JSON.stringify(data);
    }

    return header;
};

export const cssOverride: CSSProperties = {
    position: "absolute",
    top: "50%",
    left: "50%",
};
export const customTheme = {
    token: {
        colorPrimary: "#A7388E",
        controlOutline: "rgba(167, 56, 142, 0.2)",
        boxShadow: "0 1px 1px rgba(167, 56, 142, 0.15)",
        boxShadowSecondary: "0 1px 2px rgba(167, 56, 142, 0.25)",
    },
    components: {
        Select: {
            optionSelectedBg: "rgba(167, 56, 142, 0.1)",
            optionActiveBg: "rgba(167, 56, 142, 0.1)",
            optionSelectedIconColor: "#A7388E",
            colorPrimary: "rgba(167, 56, 142, 1)",
            colorPrimaryHover: "rgba(167, 56, 142, 0.8)",
        },
    },
};
