import React, {useEffect, useState, useMemo} from "react";
import RadialBarChart from "./RadialBar";
import styled from "styled-components";
import {useApplication} from "../../../context/ApplicationContext";
import StatusSelector from "./components/StatusSelector";
import {useSelector} from "react-redux";
import {authInfoSelect} from "../../../entities/auth";

interface ICategory {
    borderColor?: string;
    color?: string;
    id: number;
    name_invented: string;
    shop_id?: number;
    state?: any;
}

const initial_category = {
    id: 0,
    name_invented: "Все категории",
};

function Plag6() {
    const {dateRange} = useApplication();
    const {shop} = useSelector(authInfoSelect);
    const [data, setData] = useState<any>([]);
    const [categories, setCategories] = useState<ICategory[] | undefined>(
        undefined
    );
    const [selectGroup, setSelectGroup] = useState<ICategory | undefined>(
        initial_category
    );

    const urlForRemains = useMemo(() => {
        const from =
            dateRange && dateRange.from ? dateRange.from : "2024-01-01";
        const to = dateRange && dateRange.to ? dateRange.to : "2024-11-01";
        const url = `${process.env.REACT_APP_API_URL}/shops/${
            shop?.id
        }/menu/top/totals?from=${from}&to=${to}&limit=6&category_id=${
            selectGroup && selectGroup.id !== 0 ? selectGroup.id : null
        }`;

        return url;
    }, [dateRange, selectGroup, shop]);

    useEffect(() => {
        Promise.all([
            fetch(urlForRemains, {
                // credentials: 'include',
                headers: {
                    accept: "application/json",
                    Authorization: `${localStorage.getItem("accessToken")}`,
                    "Content-Type": "application/json",
                },
            })
                .then((res) => res.json())
                .catch(() => []),
        ])
            .then(([dataFetch]) => {
                if (dataFetch && Array.isArray(dataFetch)) {
                    setData(dataFetch);
                } else setData([]);
            })
            .catch(() => setData([]));
    }, [urlForRemains]);

    useEffect(() => {
        if (shop) {
            const urlForCategories = `${process.env.REACT_APP_API_URL}/shops/${shop?.id}/inventedCategory`;
            Promise.all([
                fetch(urlForCategories, {
                    // credentials: 'include',
                    headers: {
                        accept: "application/json",
                        Authorization: `${localStorage.getItem("accessToken")}`,
                        "Content-Type": "application/json",
                    },
                })
                    .then((res) => res.json())
                    .catch(() => []),
            ])
                .then((rest) => {
                    if (rest && rest[0]) {
                        setCategories([initial_category, ...rest[0]]);
                    }
                })
                .catch(() => setCategories([]));
        }
    }, [shop]);

    useEffect(() => {
        if (categories && Array.isArray(categories)) {
            setSelectGroup(categories[0]);
        }
    }, [categories]);

    const colors = [
        "#DD1A6A",
        "#FBB500",
        "#2B1ECA",
        "#BF6DFF",
        "#A7388E",
        "#6B00F3",
    ];

    return (
        <Plag6Wrap>
            <HeaderWrap>
                <Header>Популярные товары</Header>
                {categories && (
                    <StatusSelector
                        record={categories}
                        setSelect={setSelectGroup}
                        select={selectGroup}
                    />
                )}
            </HeaderWrap>
            <PlaginsWrap>
                {data.map((i, ind) => {
                    const {name} = i;

                    return (
                        <RadialWrap key={`radialmap-${ind}`}>
                            <RadialBarChart
                                percentage={i.percent}
                                colorDut={colors[ind]}
                                text={i.name}
                                add={i.count}
                            />{" "}
                            {/* Пример с 75% заполнением */}
                            <StyledName>{name.substring(0, 20)}</StyledName>
                        </RadialWrap>
                    );
                })}
            </PlaginsWrap>
        </Plag6Wrap>
    );
}

export default Plag6;

const HeaderWrap = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;
const StyledName = styled.div`
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
`;
const Plag6Wrap = styled.div`
    height: 100%;
`;
const Header = styled.div`
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 25px;
    /* identical to box height, or 112% */
    display: flex;
    align-items: center;

    /* Текст/#333333 текст|кнопки */
    color: #333333;
`;
const PlaginsWrap = styled.div`
    display: flex;
    flex-direction: raw;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 15px 10px;
    margin-top: 15px;

    //  border: 1px solid black;
`;
const RadialWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 30%;
    height: 90%;
    align-items: center;
    text-align: center;
    // border: 1px solid black;

    & span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 28px;
        color: #333333;
    }
`;
