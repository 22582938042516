import {combineReducers} from "@reduxjs/toolkit";
import {authReducer} from "../../entities/auth";
import {cardReducer} from "../../entities/card/model/card.slice";
import {notifyReducer} from "../../entities/notify/model/notify.slice";
import {clientReducer} from "../../entities/client";

export const rootReducer = combineReducers({
    auth: authReducer,
    card: cardReducer,
    notify: notifyReducer,
    client: clientReducer,
});
