import {createAsyncThunk} from "@reduxjs/toolkit";
import {getMeRequest, loginRequest} from "../../services/authApi";
import {
    handleRemoveToken,
    ICheckResponce,
    ILoginRequest,
    IRespLogin,
    setError,
} from "../../../entities/auth";
import {axiosErrorHandler} from "../../config";

export const authLogin = createAsyncThunk<IRespLogin, ILoginRequest>(
    "@@auth/authLogin",
    async (
        {checkResponse, ...args}: ILoginRequest,
        {rejectWithValue, dispatch}
    ) => {
        try {
            const {data, status} = await loginRequest(args);
            if (checkResponse && status) {
                checkResponse(status);
            }
            localStorage.setItem("accessToken", data.token.accessToken);
            localStorage.setItem("refreshToken", data.token.refreshToken);
            localStorage.setItem("user", JSON.stringify(data.user)); // TO DO: delete
            localStorage.setItem("shop", JSON.stringify(data.shops[0])); // TO DO: delete

            return data;
        } catch (err: any) {
            dispatch(setError(err?.response?.data?.message));
            return rejectWithValue(axiosErrorHandler(err));
        }
    }
);

export const getMe = createAsyncThunk<IRespLogin, ICheckResponce>(
    "@@auth/getMe",
    async ({checkResponse}: ICheckResponce, {rejectWithValue, dispatch}) => {
        try {
            const {data, status} = await getMeRequest();
            if (checkResponse && status) {
                checkResponse(status);
            }
            localStorage.setItem("accessToken", data.token.accessToken);
            localStorage.setItem("refreshToken", data.token.refreshToken);
            localStorage.setItem("user", JSON.stringify(data.user)); // TO DO: delete
            localStorage.setItem("shop", JSON.stringify(data.shops[0])); // TO DO: delete
            return data;
        } catch (err) {
            localStorage.removeItem("@auth");
            dispatch(handleRemoveToken());
            return rejectWithValue(axiosErrorHandler(err));
        }
    }
);
