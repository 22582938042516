import React, {useEffect, useState} from "react";
import styled from "styled-components";
import PeriodSelector from "./PeriodSelector";
import { IBookingObj } from "../../../types";
import { filterAndCountByState } from "./const";

interface IProps {
  data: IBookingObj[] | undefined;
}

interface IISelector {
  key: string
  value: string
  label: string
}
const selectOptions: IISelector[] = [
  { key: 'day', value: 'day', label: 'За день' },
  { key: 'week', value: 'week', label: 'За неделю' },
  { key: 'mounth', value: 'mounth', label: 'За месяц' },
  { key: 'year', value: 'year', label: 'За год' },
]

// const selectOptions = [
//   { key: 'request', value: 'request', label: 'Ожидание' },
//   { key: 'ok', value: 'ok', label: 'Отклонить' },
//   // { key: 'canceled', value: 'cancel', label: 'Отклонить' },
//   { key: 'approve', value: 'approve', label: 'Подтвердить' },
//   { key: 'complete', value: 'complete', label: 'Завершить' },
//   { key: 'cancel', value: 'cancel', label: 'Отказать' },
// ]

const HeaderReservationPage: React.FC<IProps> = ({ data }) => {
  const [selectValue, setSelectValue] = useState<IISelector>(selectOptions[0])
  const [stat, setStat] = useState({ 0: 0, 1: 0, 2: 0, 3: 0, 4: 0 })
  useEffect(() => {
    if (data && selectValue) {
      const statistic = filterAndCountByState(data, selectValue.value)
      setStat(statistic)
    }
  }, [data, selectValue])
  const amount = stat[0] + stat[1] + stat[2] + stat[3] + stat[4]
  const canceled = stat[1] + stat[4]
  return (
    <HeaderReservationPageWrap>
        <CardInfo>
          <CardTextArea>
            <CardText>период статистики</CardText>
            <PeriodSelector options={selectOptions} setSelectValue={setSelectValue} value={selectValue} />
          </CardTextArea>
        </CardInfo>
      
        <CardInfo>
          <CardTextArea>
            <CardText>Всего</CardText>
          <CardTitle>{amount}</CardTitle>
          </CardTextArea>
        </CardInfo>
      
        <CardInfo>
          <CardTextArea>
            <CardText>Ожидание</CardText>
          <CardTitle>{stat[0]}</CardTitle>
          </CardTextArea>
        </CardInfo>
      
        <CardInfo>
          <CardTextArea>
            <CardText>Подтвержденных</CardText>
          <CardTitle>{stat[2]}</CardTitle>
          </CardTextArea>
        </CardInfo>
      
        <CardInfo>
          <CardTextArea>
            <CardText>Отказ</CardText>
          <CardTitle>{canceled}</CardTitle>
          </CardTextArea>
        </CardInfo>
      
      </HeaderReservationPageWrap>
  )
}

export default HeaderReservationPage;

const HeaderReservationPageWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* background: #fff; */
  /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.08); */
  /* border-radius: 5px; */
  /* gap: 60px; */
`


const CardInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* padding-top: 12px;
  padding-left: 17px; */
  /* width: 170px;
  height: 63px; */
`

const CardTextArea = styled.div`
  display: flex;
  flex-direction: column;
  /* Frame 195 */
  padding-top: 12px;
  padding-left: 17px;
  width: 257px;
  height: 92px;

  /* Текст/#FFFFFF текст|иконки|кнопки */
  background: #FFFFFF;
  box-shadow: 0px 2px 15.2px rgba(0, 0, 0, 0.11);
  border-radius: 5px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;

`

const CardTitle = styled.div`
  /* За сегодня */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 34px;
  /* identical to box height, or 112% */
  align-items: center;

  /* Текст/#333333 текст|кнопки */
  color: #333333;

`

const CardText = styled.div`
  width: 132px;
  height: 28px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  align-items: center;
  color: #8F8F8F;
`
