import {styled} from "styled-components";
import {Icons} from "../../assets";
import {FC, useEffect, useState} from "react";
import PushNotifyHeader from "./components/content/PushNotifyHeader";
import {Table, TableProps, Select} from "antd";
import {cssOverride, limitNotify, optionsNotifySelect} from "../../utils/const";
import {ENotifyAction} from "../../utils/enums";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "../../config/store";
import {getNotifys, getTemplates} from "../../api/thunks/notify";
import {notifySelect, setEditNotify} from "../../entities/notify";
import PushNotifyUpdateModal from "./components/content/PushNotifyUpdateModal";
import {INotifyRequest} from "../../entities/notify/types";
import {
    cancelNotifyReuest,
    deleteNotifyReuest,
} from "../../api/services/notifyApi";
import {PuffLoader} from "react-spinners";
import PushNotifyModal from "./components/content/PushNotifyModal";
import {authInfoSelect} from "../../entities/auth";
import {notifyColumns} from "./components/content/notifyColumns";
import {TableParams} from "../../types";

const PushNotifyPage: FC = () => {
    const {shop} = useSelector(authInfoSelect);
    const [searchText, setSearchText] = useState<string>("");
    const [selectValue, setSelectValue] = useState<number>(1);
    const [selectAction, setSelectAction] = useState<ENotifyAction>(
        ENotifyAction.CANCEL
    );
    const [show, setShow] = useState<boolean>(false);
    const [showCreateNotify, setShowCreateNotify] = useState<boolean>(false);
    const [isRetry, setIsRetry] = useState<boolean>(false);
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: limitNotify,
        },
        filters: {},
    });

    const [selectedId, setSelectedId] = useState<React.Key[]>([]);
    const {notifys: notifications, loading, total} = useSelector(notifySelect);
    const dispatch = useDispatch<AppDispatch>();
    const isOpenEdit = selectAction === ENotifyAction.EDIT;
    const isOpenRetry = selectAction === ENotifyAction.DUBBLE;

    const getUniqueTemplates = (data: INotifyRequest[]): number[] => {
        const templatesSet = new Set();
        data?.forEach((record) => {
            record?.templates?.forEach((template) => {
                if (template && template.name) {
                    templatesSet.add(template.name);
                }
            });
        });
        return Array.from(templatesSet) as number[];
    };

    const uniqueTemplates = getUniqueTemplates(notifications) || [];

    const handleTableChange: TableProps["onChange"] = (pagination, filters) => {
        setTableParams((prev) => ({
            ...prev,
            pagination,
            filters,
        }));
    };

    const rowSelection = {
        onChange: (
            selectedRowKeys: React.Key[],
            selectedRows: INotifyRequest[]
        ) => {
            setSelectedId(selectedRowKeys);
        },
    };

    const onChangeSelect = (value: number) => {
        setSelectValue(value);
        setSelectAction(optionsNotifySelect[value - 1].label);
    };

    const fetchNotifys = () => {
        if (!shop) return;
        const {current, pageSize} = tableParams.pagination!;
        dispatch(
            getNotifys({
                shop_id: shop.id,
                params: {
                    limit: pageSize!,
                    offset: (current! - 1) * pageSize!,
                },
            })
        );
    };

    const handleApplyAction = async () => {
        if (selectAction === ENotifyAction.CANCEL && shop?.id && selectedId) {
            await Promise.all(
                selectedId?.map(
                    async (item) =>
                        await cancelNotifyReuest({
                            shop_id: shop.id,
                            notify_id: item,
                        })
                )
            ).then(() => fetchNotifys());
        } else if (
            selectAction === ENotifyAction.DELETE &&
            shop?.id &&
            selectedId
        ) {
            await Promise.all(
                selectedId?.map(
                    async (item) =>
                        await deleteNotifyReuest({
                            shop_id: shop.id,
                            notify_id: item,
                        })
                )
            ).then(() => fetchNotifys());
        } else if (selectAction === ENotifyAction.DUBBLE) {
            setIsRetry(true);
            setShow(true);
            dispatch(setEditNotify(selectedId[0]));
        } else if (selectAction === ENotifyAction.EDIT) {
            setShow(true);
            dispatch(setEditNotify(selectedId[0]));
        }
    };

    const overlayOnClick = () => {
        setShowCreateNotify(false);
        handleCloseModal();
    };

    const handleCloseModal = () => {
        setShow(false);
        setSelectAction(ENotifyAction.CANCEL);
        setSelectValue(1);
    };

    useEffect(() => {
        if (shop) {
            const {current, pageSize} = tableParams.pagination!;
            dispatch(getTemplates(shop.id));
            dispatch(
                getNotifys({
                    shop_id: shop.id,
                    params: {
                        limit: pageSize!,
                        offset: (current! - 1) * pageSize!,
                    },
                })
            );
        }
    }, [shop, dispatch, tableParams.pagination]);

    if (loading === "loading") {
        return <PuffLoader color="#E65E20" cssOverride={cssOverride} />;
    }

    const renderTableFooter = () => {
        return (
            <FooterWrap>
                <ActionWrap>
                    <StyledSelect
                        options={optionsNotifySelect}
                        value={selectValue}
                        onChange={(val) => onChangeSelect(val as number)}
                    />
                    <StyledActionButton onClick={handleApplyAction}>
                        <span>Применить</span>
                    </StyledActionButton>
                </ActionWrap>
            </FooterWrap>
        );
    };

    return (
        <Wrapper>
            <Header>
                <HeaderTitle>Push-уведомления</HeaderTitle>
            </Header>
            <PushNotifyHeader />
            <SearchWrap>
                <SearchInput
                    placeholder="Поиск..."
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                />

                <ButtonBlok>
                    <StyledButton
                        onClick={() => setShowCreateNotify(!showCreateNotify)}
                    >
                        <Icons.IconSearchVect4 />
                    </StyledButton>
                </ButtonBlok>
            </SearchWrap>
            <TableWrapper>
                <Table
                    size="small"
                    rowSelection={rowSelection}
                    columns={notifyColumns}
                    rowKey={(rec: INotifyRequest) => rec.id?.toString() ?? ""}
                    dataSource={notifications}
                    onChange={handleTableChange}
                    pagination={{
                        current: tableParams.pagination?.current,
                        pageSize: tableParams.pagination?.pageSize,
                        total,
                    }}
                    footer={renderTableFooter}
                />
            </TableWrapper>
            <Overlay
                onClick={overlayOnClick}
                display={show || showCreateNotify ? "block" : "none"}
            />
            {(isOpenEdit || isOpenRetry) && show && (
                <PushNotifyUpdateModal
                    template_ids={selectedId}
                    modalVisibilityToggler={() => handleCloseModal()}
                    isRetry={isRetry}
                />
            )}
            {showCreateNotify && (
                <PushNotifyModal
                    selectedClientData={{
                        template_id: uniqueTemplates,
                        client_id: [],
                    }}
                    modalVisibilityToggler={() => setShowCreateNotify(false)}
                />
            )}
        </Wrapper>
    );
};

export default PushNotifyPage;

const HeaderTitle = styled.div`
    margin-top: 38px;
    height: 28px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 28px;
    align-items: center;
    color: #333333;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    min-height: 90%;
    top: 0px;
    gap: 16px;
    background-color: #f7f7f7;

    .ant-table-row {
        height: 14px !important;
        padding: 2px !important;
    }

    .ant-table-cell {
        height: 14px !important;
        padding: 2px !important;
    }

    .ant-table-tbody > tr > td {
        background-color: #ffffff;
        font-family: Inter;
        border: 1px solid #efefef;
        color: #333333;
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
        text-align: left;
        padding: 2px;
        height: 12px;
    }

    .ant-table-summary {
        background-color: #9481cd40;
        font-family: "Inter";
        font-size: 14px;
        font-weight: 600;
        line-height: 16.8px;
        text-align: left;
    }

    .ant-table-thead > tr > th {
        background-color: #9481cd40;
        font-family: "Inter";
        font-size: 12px;
        font-weight: 600;
        line-height: 13.2px;
        text-align: left;
    }
`;

const Header = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    top: 0px;
    margin-bottom: 20px;
`;
const SearchWrap = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;

const SearchInput = styled.input`
    width: 100%;
    padding-left: 20px;
    height: 33px;
    background: #ffffff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.08);
    border-radius: 5px;

    border: none;
`;
const ButtonBlok = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 12px;
`;
const StyledButton = styled.div`
    width: 33px;
    height: 33px;
    background: #cd6ee5;
    box-sizing: border-box;
    border: 0.6875px solid #cd6ee5;
    box-shadow: -1.375px 1.375px 4.2625px 1.375px rgba(0, 0, 0, 0.24);
    border-radius: 2.75px;
    padding: 5px;
    cursor: pointer;
`;

const TableWrapper = styled.div`
    margin-top: 10px;
    .ant-table-thead {
        height: 50px;
        line-height: 50px;
        background: rgba(148, 129, 205, 0.25);
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
        border-radius: 5px 5px 0px 0px;
    }
    .ant-table-thead > tr > th::before {
        content: none !important;
    }
    .ant-table-cell {
        border-right: none !important;
        border-left: none !important;
        font-size: 14px !important;
    }

    .ant-table-row .ant-table-cell {
        height: 46px !important;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
        align-items: center;
        color: #333333;

        & svg {
            margin-top: 9px;
        }
    }

    & td.leftAlign {
        text-align: left !important;
        padding-left: 5px !important;
    }

    & th.headerCenter {
        text-align: center !important;
    }
`;
const FooterWrap = styled.div`
    display: flex;
    flex-direction: raw;
`;

const ActionWrap = styled.div`
    display: flex;
    flex-direction: raw;
    justify-content: flex-start;
    gap: 10px;
`;

const StyledSelect = styled(Select)`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 175px;
    height: 38px;
`;
const StyledActionButton = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    gap: 10px;
    width: 98px;
    height: 38px;
    background: #a7388e;
    border-radius: 5px;

    cursor: pointer;
    & span {
        width: 78px;
        height: 28px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 28px;
        display: flex;
        align-items: center;
        color: #f7f7f7;
        flex: none;
        order: 0;
        flex-grow: 0;
    }
`;
const Overlay = styled.div<{
    display: string;
}>`
    display: ${({display}) => display};
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(1px);
`;
