import {ReactNode} from "react";
import {Outlet} from "react-router-dom";
import Navbar from "./navbar/Navbar";
import styled from "styled-components";
// import useAuth from "../hooks/useAuth";

interface PropsLayout {
    children: ReactNode;
    sidemenu: any;
}
const Layout = ({children, sidemenu}: PropsLayout) => {
    // const auth = useAuth();

    return (
        <StyledContainer className="App">
            <Navbar sidemenu={sidemenu} />
            <StyledContent>
                {/* <StyledSidebar>
            <Sidebar sidemenu={sidemenu} />
          </StyledSidebar> */}
                <StyledChildren>
                    {children}
                    <Outlet />
                </StyledChildren>
            </StyledContent>
        </StyledContainer>
    );
};

const StyledContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    background: #f7f7f7;
`;

const StyledContent = styled.div`
    display: flex;
    width: calc(100% - 2rem);
    max-width: 1380px;
    margin: 0 auto;
    background: #f7f7f7;
`;

const StyledChildren = styled.div`
    display: flex;
    width: 100%;
    background: #f7f7f7;
    padding-bottom: 50px;
`;

export default Layout;
