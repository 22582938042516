import {FC, useState} from "react";
import {Bar} from "react-chartjs-2";
import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import {barsColorArray} from "../../../config/colors";
import {LegendContainer} from "./LegendContainer";
import styled from "styled-components";
import {IBarCharCard} from "../../../types";
import {getRandomColors} from "../../../utils/functions/ getRundomNumber";

ChartJS.register(
    BarElement,
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend
);

const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false, // Отключаем стандартную легенду
        },
    },
    scales: {
        // x: {
        //   type: 'category' as const,
        //   barPercentage: 0.8, // Настройка ширины баров
        //   categoryPercentage: 0.5,// Настройка расстояния между барами
        //   offset: true, // Добавляем отступы между группами столбиков

        // },
        y: {
            ticks: {
                maxTicksLimit: 5, // Установите максимальное количество меток на оси Y
            },
        },
    },
};

const BarChart: FC<IBarCharCard> = ({labels, datasets}) => {
    const [hiddenDatasets, setHiddenDatasets] = useState<number[]>([]);

    if (labels && datasets[0]?.data?.length > 0) {
        const datas = {
            labels: labels,
            datasets: datasets.map((item, ind) => {
                const color = getRandomColors(barsColorArray, 20);
                return {
                    label: item.label,
                    data: item.data,
                    borderColor: color,
                    backgroundColor: color,
                    borderRadius: item.borderRadius,
                    barThickness: item.barThickness,
                    fill: false,
                    spanGaps: false, // Убедитесь, что пропуски не заполняются
                    hidden: hiddenDatasets.includes(ind),
                };
            }),
        };

        return (
            <Wrap>
                {" "}
                {/* Используем Flexbox */}
                <LegendContainer
                    datas={datas}
                    setHiddenDatasets={setHiddenDatasets}
                    hiddenDatasets={hiddenDatasets}
                />
                <Bar data={datas} options={options} />
            </Wrap>
        );
    }
    return <TextBox>Нет данных</TextBox>;
};

export default BarChart;

const Wrap = styled.div`
    height: 320px;
`;
const TextBox = styled.div`
    font-size: 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`;
