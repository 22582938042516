import { useState, useEffect } from 'react';
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Table, Tag, Select } from 'antd'
import type { TableColumnsType, GetProp, TableProps } from 'antd';
import Highlighter from 'react-highlight-words';
import { Icons } from '../../assets';
import { ManOutlined, WomanOutlined } from '@ant-design/icons'
import moment from 'moment';

type TablePaginationConfig = Exclude<GetProp<TableProps, 'pagination'>, boolean>;

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}

const fields = [
  { id: 1, value: 'addBonuses', label: 'Скопировать', basic: false, required: true },
  { id: 2, value: 'name', label: 'Удалить', basic: true, required: false },
  { id: 3, value: 'phone', label: 'Заблокировать', basic: true, required: false },
  { id: 3, value: 'phone', label: 'Активировать', basic: true, required: false },
]
let findForm = {}
const RegFormsPage = () => {
  const [openedSettingsPopup, setOpenedSettingsPopup] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any[]>([]);
  const [searchText, setSearchText] = useState('');
  const [selectedClient, setSelectedClient] = useState<any>(undefined)

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const dataArray = [
    {
    "id": 1,
    "fields": [
        {
            "name": "lastname",
            "title": "Фамилия",
            "type": "text",
            "max": 20,
            "required": true
        },
        {
            "name": "firstname",
            "title": "Имя",
            "type": "text",
            "max": 20,
            "required": true
        },
        {
            "name": "surname",
            "title": "Отчество",
            "type": "text",
            "max": 20,
            "required": false
        },
        {
            "name": "email",
            "title": "Email",
            "type": "text",
            "max": 50,
            "required": true
        },
        {
            "name": "birthdate",
            "title": "Дата роджения",
            "type": "date",
            "max": 10,
            "required": true
        },
        {
            "name": "sex",
            "title": "Пол",
            "type": "radio",
            "required": true,
            "items": [
                {
                    "value": 1,
                    "text": "Мужской"
                },
                {
                    "value": 2,
                    "text": "Женский"
                }
            ]
        }
    ],
    "textAfter": "Заполните анкету для получения карты",
    "textBefore": "МИКС Afterparty",
    "template": {
        "name": "Women card 3",
        "displayName": "WOMEN CARD",
        "textColor": "#ffffff",
        "backgroundColor": "#2A001C",
        "imagePath": "card_templates/women/Template.png"
      },
      "createdAt": '2024-09-12 22:00'
}

  ]

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  
  const getColumnSearchProps = dataIndex => ({
  // ... (остальные свойства)
    render: text =>
      searchText ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  useEffect(() => {
    if (loading) {
      setLoading(false);
      const clientsUrl = `${process.env.REACT_APP_API_URL}/shop/loyalty/registration/forms`
      Promise.all([
        fetch(clientsUrl, {
          // credentials: 'include',
          headers: {
            'accept': 'application/json',
            'Authorization': `${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json',

          }
        })
          .then(res => {
            return res.json()})
          .catch(() => {
            console.log('error');
            return []
          }),
      ])
        .then(([data]) => {
          if (data && Array.isArray(data) && data.length > 0) {
            setData(data) 
          } else setData([])
            setData(dataArray) 
        })
      setLoading(false);
    }
  }, [loading])

  const columns: TableColumnsType = [
    {
      title: '№',
      dataIndex: 'id',
      width: 20,
      sorter: (a, b) => a.id - b.id,
      ...getColumnSearchProps('id'),
      render: (id) => <Link to={`/crm/reg_form?reg=${id}`}>
        <a onClick={()=>handlerClickId(id)}>
          {searchText ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={id.toString()}
                />
            
                
            ) : (
              id
            )}
        </a>
      </Link>
    },
    {
      title: 'Дата создания',
      dataIndex: 'createdAt',
      width: 80,
      render: ((record) => {
        return <span>{record ? moment.utc(record).format('DD.MM.YYYY HH:mm') : '-'}</span>
      }),
    },
    {
      title: 'Тип карты',
      dataIndex: 'template',
      align: 'center',
      width: 35,
      render:(obj: any) => (
              <Tag color={obj.backgroundColor}>
                {obj.displayName && obj.displayName.toUpperCase()}
              </Tag>
            )
      
        
    },
    {
      title: 'СТАТУС',
      dataIndex: 'isActive',
      align: 'center',
      width: 35,
      filters: [
        { text: 'Активен', value: true },
        { text: 'Неактивен', value: false },
      ],
      onFilter: (value, record) => record.isActive === value,
      render: (act) => (true)
        ? <Icons.CircleCheck />
        : <Icons.CircleQuestion />
    },
    {
      title: 'Последнее изменение',
      dataIndex: 'createdAt',
      width: 80,
      render: ((record) => {
        return <span>{record ? moment.utc(record).format('DD.MM.YYYY HH:mm') : '-'}</span>
      }),
    },
  ];
  
  const handlerClickId = (id) => {
    const findClient = data.find(o => o.id === id)
    if (findClient) {
      setSelectedClient(findClient)
      setOpenedSettingsPopup(true)
    }
    
  }
  
  const handleTableChange: TableProps['onChange'] = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
  };

  const FieldName = styled(Select)`
    width: 240px;
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    gap: 10px;

    width: 175px;
    height: 38px;

    /* Текст/#8F8F8F текст */
    border: 1px solid #8F8F8F;
    border-radius: 5px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

  `

  const renderTableFooter = () => {
    return (
      <StyledFooterWrap>
        <FieldName value={''} options={fields}>выбор действия...</FieldName>
        <FooterBut>Применить</FooterBut>
        <div>
          Отфильтровано: {dataArray.length}/{data.length} |
          Выделено: {selectedRowKeys.length}

        </div>
      </StyledFooterWrap>
    );
  };

  const FooterBut = styled.div`
    /* Frame 37 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    gap: 10px;

    width: 98px;
    height: 38px;

    /* Текст/#A7388E текст|кнопки */
    background: #A7388E;
    border-radius: 5px;


  `
  const StyledFooterWrap = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    /* Frame 117 */
    padding: 30px;
    height: 90px;
    gap: 10px;
    background: #FFFFFF;
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 5px 5px;

  `
  return (
    <Wrapper>
      <Header>
        <Title>
          Формы регистрации
        </Title>
      </Header>
      <SearchWrap>
        <SearchInput
          placeholder='Поиск...'
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
        />
      </SearchWrap>
      <TableWrapper>
        <Table
          size='small'
          rowSelection={rowSelection}
          columns={columns}
          rowKey={(rec) => rec.id}
          dataSource={dataArray}
          onChange={handleTableChange}
          pagination={{ pageSize: 10 }}
          footer={renderTableFooter}
        />

      </TableWrapper>
      {/* {data.map(i => (
        <Link to={`/crm/reg_form?reg=${i.id}`}>
          <CardReg
            $backgroundColor={i.template.backgroundColor}
            $textColor={i.template.textColor}
          >
            {i.template.displayName}
          </CardReg>
        </Link>
      ))} */}
    </Wrapper>
   
  )
}

export default RegFormsPage;

const SearchWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`
const SearchInput = styled.input`
  width: 100%;
  padding-left: 20px;
  /* Frame 91 */

  height: 33px;

  /* Текст/#FFFFFF текст|иконки|кнопки */
  background: #FFFFFF;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.08);
  border-radius: 5px;

  border: none;

`

const TableWrapper = styled.div`
  margin-top: 10px;
  /* Frame 128 */
  
  .ant-table-thead {
    height: 50px; /* Вы можете установить желаемую высоту */
    line-height: 50px; /* Установите такое же значение, как и высота, для вертикального центрирования содержимого */
    /* Rectangle 91 */
    
    background: rgba(148, 129, 205, 0.25) !important;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25) !important;
    border-radius: 5px 5px 0px 0px !important;
    /* background: rgba(148, 129, 205, 0.25);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px 5px 0px 0px; */
  }
  .ant-table-thead>tr>th::before {
    content: none !important;
  }
  .ant-table-cell {
    border-right: none !important; 
    border-left: none !important;/* Убираем вертикальные границы между ячейками */
  }

  .ant-table-row .ant-table-cell {
    height: 46px !important; 
    /* 79991233232 */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    /* identical to box height, or 200% */
    align-items: center;

    /* Текст/#333333 текст|кнопки */
    color: #333333;

    & svg {
      margin-top: 9px;
    }
  }

  & td.leftAlign {
    text-align: left !important;
    padding-left: 5px !important;
  }

  & th.headerCenter {
    text-align: center !important;
  }
  & .ant-table-footer {
    padding: 0px !important;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* min-width: 100%; */
  width: 100%;
  min-height: 600px;
  height: calc(100vh - 120px);
  top: 0px;
  background-color: #fff;
  padding: 16px 0px 16px 0px;
`

const Header = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  top: 0px;
  margin-top: 20px;
  margin-bottom: 0px;
  margin-left: 40px;
  /* gap: 30px; */
`

const Title = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #000000;
`

const CardReg = styled.div<{
  $textColor?: string
  $backgroundColor?: string
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
  width: 250px;
  height: 150px;
  color: ${props => props.$textColor};
  background-color: ${props => props.$backgroundColor};
  cursor: pointer;
`

const Sspan = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height, or 200% */
  text-align: right;

  /* Текст/#333333 текст|кнопки */
  color: #333333;
`

const Nspan = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height, or 200% */
  text-align: right;

  /* Текст/#8F8F8F текст */
  color: #8F8F8F;


`
