export function isAllow(rules: object, path: string, mode: string | undefined) : boolean {
    if (!mode)
        return false
    const m = mode.trim().toLocaleLowerCase()
    const modeRead = m.indexOf('r') > -1
    const modeWrite = m.indexOf('w') > -1

    if (!modeRead && !modeWrite)
        return false

    let nodes = path.trim().toLocaleLowerCase().split('.')
    let allow: any = false

    while (nodes.length) {
        let node: string | undefined = nodes.shift()
        if (node && node.length !== undefined){
            allow = processRule(node.trim())
            if (allow === 'all')
                return true
    
            if (!allow)
                return false
        } else 
            break
    }
   
    return allow

    function processRule(p: string): boolean | string {
        const rule: object | string | undefined = rules[p]
        if (!rule)
            return false

        if (typeof (rule) === 'string') {
            const r = rule.trim().toLocaleLowerCase()
            let allowAll = r.indexOf('*') > -1
            if (allowAll)
                return 'all'

            let allowRead = r.indexOf('r') > -1
            if (allowRead && modeRead)
                return true

            let allowWrite = r.indexOf('w') > -1
            if (allowWrite && modeWrite)
                return true

            return false
        }

        if (typeof (rule) === 'object') {
          rules = rule
          return true
        }
        return false
    }

}