import {Tag} from "antd";

export const StyledTemplate = ({
    name,
    ind,
}: {
    name: string;
    ind: number;
}): JSX.Element => {
    const displayName = typeof name === "string" ? name : "";
    let color =
        displayName && displayName.length > 10
            ? displayName && displayName.length > 13
                ? "volcano"
                : "cyan"
            : displayName && displayName.length === 10
            ? "red"
            : displayName && displayName.length < 7
            ? "blue"
            : "green";
    return (
        <Tag color={color} key={ind}>
            {displayName && displayName.toUpperCase()}
        </Tag>
    );
};
