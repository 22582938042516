import React, {FC, useEffect, useState} from "react";
import styled from "styled-components";
import {FormikValues, useFormik} from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import {registerLocale, setDefaultLocale} from "react-datepicker";
import {ru} from "date-fns/locale/ru";
import {Checkbox} from "antd";
import type {CheckboxProps} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {cardSelect} from "../../../../entities/card";
import {notifySelect} from "../../../../entities/notify";
import {AppDispatch} from "../../../../config/store";
import {getNotifys, updateNotify} from "../../../../api/thunks/notify";
import {INotifyUpdateRequest} from "../../../../api/services/notifyApi/types";
import {authInfoSelect} from "../../../../entities/auth";
import {limitNotify} from "../../../../utils/const";
import {isNotEmptyArray} from "../../../../utils/functions";
import {StyledTemplate} from "./StyledTemplate";

registerLocale("ru", ru);
setDefaultLocale("ru");

interface SelectOption {
    label: string;
    value: number;
}

interface IPropsNotifytUpdateModal {
    template_ids: React.Key[];
    modalVisibilityToggler: () => void;
    isRetry: boolean;
}

const PushNotifyUpdateModal: FC<IPropsNotifytUpdateModal> = ({
    template_ids,
    modalVisibilityToggler,
    isRetry,
}) => {
    const {shop} = useSelector(authInfoSelect);
    const [startDate, setStartDate] = useState<Date>(new Date());
    const [showErrors, setShowErrors] = useState<boolean>(false);
    const [select, setSelect] = useState<number>(0);
    const [checked, setChecked] = useState<boolean>(false);
    const {templates} = useSelector(cardSelect);
    const [templatesData, setTemplatesData] = useState<SelectOption[]>([]);
    const {editNotify} = useSelector(notifySelect);
    const dispatch = useDispatch<AppDispatch>();

    const initialValues = {
        text: editNotify?.text,
        global: "",
    };

    const onSubmit = (values: FormikValues) => {
        if (!shop || !templates?.length) {
            formik.setErrors({global: "Выберите магазин перед отправкой"});
            return;
        }
        const now = new Date();
        const {text} = values;

        const body: INotifyUpdateRequest = {
            text,
            state: 1,
            filter: {
                template_id: [templates[select].id],
            },
        };

        if (checked) {
            if (startDate.getTime() > now.getTime()) {
                body.date = startDate.toISOString();
            } else {
                formik.setErrors({global: "Выберите время позднее текущего"});
                return;
            }
        }

        dispatch(
            updateNotify({shop_id: shop.id, notify_id: [template_ids[0]], body})
        )
            .then(() =>
                dispatch(
                    getNotifys({
                        shop_id: shop.id,
                        params: {limit: limitNotify, offset: 0},
                    })
                )
            )
            .then(() => modalVisibilityToggler());
    };

    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object({
            text: Yup.string()
                .max(240)
                .required("Напишите сообщение, которое хотите оправить"),
        }),
        onSubmit,
    });

    const onChange: CheckboxProps["onChange"] = (e) => {
        setChecked(e.target.checked);
    };

    const ErrorDisplay = ({errors, touched}) => {
        return (
            <div>
                {Object.keys(errors).map((key) =>
                    touched[key] && errors[key] ? (
                        <ErrorBox className="error" key={key}>
                            {errors[key]}
                        </ErrorBox>
                    ) : null
                )}
            </div>
        );
    };

    useEffect(() => {
        if (
            editNotify &&
            isNotEmptyArray(editNotify.filter?.template_id) &&
            isNotEmptyArray(templates)
        ) {
            let data: SelectOption[] = [];
            templates.forEach((item) => {
                if (editNotify.filter?.template_id?.includes(item.id)) {
                    data.push({label: item.name, value: item.id});
                }
            });
            setTemplatesData(data);
        }
    }, [editNotify, templates]);
    console.log("edit=>", editNotify);

    useEffect(() => {
        if (
            editNotify?.date &&
            editNotify?.templates &&
            templates?.length > 0
        ) {
            setStartDate(new Date(editNotify.date));
            const index = templates?.findIndex(
                (item) =>
                    item.displayName === editNotify.templates[0].displayName
            );

            if (index !== -1) {
                setSelect(index);
            }
        }
    }, [editNotify, templates]);

    return (
        <Wrapper>
            <HeaderTitle>
                <Title>Изменить push-уведомление</Title>
                <CloseBtn onClick={modalVisibilityToggler}>Закрыть</CloseBtn>
            </HeaderTitle>
            <FormContainer
                onSubmit={(event) => {
                    formik.handleSubmit(event);
                    setShowErrors(true);
                }}
            >
                <LeftBox>
                    <FormItemContainer>
                        <span>Макеты</span>
                        <WrapSelect>
                            {templatesData?.map((item, index) => {
                                return (
                                    <StyledTemplate
                                        key={item.value}
                                        name={item.label as string}
                                        ind={index}
                                    />
                                );
                            })}
                        </WrapSelect>
                        <FormItemContainer>
                            <CustomCheckbox onChange={onChange}>
                                Запланировать отправку на дату и время
                            </CustomCheckbox>
                            {showErrors && (
                                <ErrorDisplay
                                    errors={formik.errors}
                                    touched={formik.touched}
                                />
                            )}
                        </FormItemContainer>
                    </FormItemContainer>
                    <FormTextAriaContainer>
                        <span>Текст</span>
                        <TextAria
                            name="text"
                            value={formik.values.text}
                            required={
                                !!formik.errors.text && !!formik.touched.text
                            }
                            disabled={isRetry}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="Напишите ваш текст"
                            $hasError={
                                !!(formik.errors.text && formik.touched.text)
                            }
                        />
                    </FormTextAriaContainer>
                    <StyledFooter>
                        <StyledButtonSend type="submit">
                            <span>{isRetry ? "Отправить" : "Изменить"}</span>
                        </StyledButtonSend>
                    </StyledFooter>
                </LeftBox>
                <RightBox>
                    <DatePickerWrapper>
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date!)}
                            locale="ru"
                            timeInputLabel="Время:"
                            dateFormat="MM/dd/yyyy h:mm aa"
                            showTimeInput
                            previousMonthButtonLabel="Предыдущий"
                            nextMonthButtonLabel="Следующий"
                            calendarClassName="custom-datepicker"
                            inline
                        />
                    </DatePickerWrapper>
                </RightBox>
            </FormContainer>
        </Wrapper>
    );
};

export default PushNotifyUpdateModal;

const Wrapper = styled.div`
    overflow: hidden;
    display: flex;
    position: fixed;
    flex-direction: column;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    padding: 10px 25px 20px 25px;
    width: 40vw;
    height: 60vh;
    background: #f7f7f7;
    border-radius: 5px;
`;
const WrapSelect = styled.div`
    display: flex;
    align-self: flex-start;
    flex-wrap: wrap;
    gap: 5px 5px;
    width: 100%;
`;

const HeaderTitle = styled.div`
    width: 100%;
    font-family: "Inter";
    display: flex;
    justify-content: space-between;
    color: #333333;
    height: 45px;
`;
const Title = styled.div`
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    display: flex;
    align-items: flex-end;
`;
const CloseBtn = styled.button`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #8f8f8f;
    border: none;
    background: #f7f7f7;
    display: flex;
    align-items: flex-start;
    padding-top: 5px;
    &:hover {
        color: #5e5e5e;
    }
`;
const FormContainer = styled.form`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 21px 0px 0px 0px;
    height: 80%;
`;
const LeftBox = styled.div`
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    & span {
        font-size: 14px;
        font-weight: 400;
        line-height: 15px;
        color: #333333;
    }
`;
const RightBox = styled.div`
    width: 45%;
    height: 100%;
`;
const StyledFooter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 40px;
    align-items: center;
    position: absolute;
    bottom: 20px;
    right: 25px;
    left: 25px;
`;

const StyledButtonSend = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 45%;
    height: 100%;
    background: #a7388e;
    border-radius: 5px;
    border: none;
    & span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
        text-align: center;
        color: #ffffff;
    }
    &:hover {
        background: #97267d;
    }
`;
const CustomCheckbox = styled(Checkbox)`
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #a7388e !important;
        border-color: #a7388e !important;
    }

    .ant-checkbox-inner {
        border-color: #333333 !important;
    }
`;

const FormItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 9px;
`;
const FormTextAriaContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    & span {
        margin-bottom: 8px;
        font-size: 14px;
        font-weight: 400;
        line-height: 15px;
        color: #333333;
    }
`;

const TextAria = styled.textarea<{
    $hasError?: boolean;
}>`
    position: relative;
    width: 100%;
    height: 153px;
    padding: 10px 10px 0 12px;
    border-radius: 5px;
    color: #1f1e24;
    border: 1px solid ${({$hasError}) => ($hasError ? "red" : "#D6DCE9")};
    font-family: "Inter";
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    border: none;
    background: #eeefef;
    &:focus {
        border: 1px solid #e65e20;
        border-color: #e65e20;
        outline: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    &:hover {
        border: 1px solid #e65e20;
        border-color: #e65e20;
        outline: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    &:-webkit-autofill {
        -webkit-transition: "background-color 9999s ease-out";
        -webkit-transition-delay: 9999s;
    }

    ::placeholder {
        color: #b0b3b5;
    }

    :-ms-input-placeholder {
        color: #b0b3b5;
    }

    ::-ms-input-placeholder {
        color: #b0b3b5;
    }

    &:invalid {
        border: 1px solid #e65e20;
    }
`;

const DatePickerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    .react-datepicker {
        position: relative;
        width: 100%;
        height: 100%;
        max-width: 350px;
        border-radius: 8px;
        background: #f7f7f7;
        box-shadow: none;
        padding: 10px;
    }

    .react-datepicker__month-container {
        width: 100%;
        height: 85%;
        text-align: center;
    }
    .react-datepicker__navigation--previous span,
    .react-datepicker__navigation--next span {
        display: none;
    }

    .react-datepicker__navigation {
        font-size: 20px;
        background-color: #f7f7f7;
        cursor: pointer;
        position: absolute;
        top: 22px;
        color: #d6d6d6;
        font-weight: 700;
        border: none;
    }
    .react-datepicker__navigation--previous {
        left: 10px;
        padding: 0px 20px;
        text-align: left;
    }
    .react-datepicker__navigation--next {
        right: 10px;
        padding: 0px 20px;
        text-align: right;
    }

    .react-datepicker__navigation--previous::before {
        content: "<";
    }

    .react-datepicker__navigation--next::before {
        content: ">";
    }

    .react-datepicker__header {
        border-bottom: 1px solid #ccc;
        height: 30%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .react-datepicker__week {
        display: flex;
        justify-content: space-around;
    }

    .react-datepicker__day-names {
        display: flex;
        justify-content: space-around;
    }
    .react-datepicker__aria-live {
        display: none;
    }

    .react-datepicker__day {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 2px;
        cursor: pointer;
        border-radius: 4px;
        transition: background 0.2s ease-in-out;
    }

    .react-datepicker__day--selected {
        background: #a7388e !important;
        color: white;
    }

    .react-datepicker__input-time-container {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .react-datepicker__time-box {
        border-bottom-right-radius: 0rem;
    }

    .react-datepicker-time__input-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 35px;
        color: #216ba5;
    }
    .react-datepicker-time__caption {
        width: 100%;
        text-align: center;
        font-weight: 600;
        font-size: 16px;
        line-height: 32px;
    }

    .react-datepicker-time__input {
        width: 150px;
        height: 100%;
        text-align: center;
        background-color: #f7f7f7;
        border-radius: 5px;
        box-shadow: none;
    }
`;
const ErrorBox = styled.div`
    color: red;
    font-size: 12px;
`;
