import React, {useState, useEffect} from "react";
import styled, {css} from "styled-components";
import moment from "moment";
import {DatePicker} from "antd";
import {FormikValues, useFormik} from "formik";
import * as Yup from "yup";
import {IBookingObj} from "../../../types";
import UniversalSelector from "./UniversalSelector";
import NewStatusSelector from "./NewStatusSelector";
import dayjs from "dayjs";
import {useSelector} from "react-redux";
import {authInfoSelect} from "../../../entities/auth";

const apiUrl = process.env.REACT_APP_API_URL;

interface PropsClientModal {
    record?: IBookingObj | undefined;
    activeModal: string;
    modalVisibilityToggler: any;
}

interface ITable {
    desc: number;
    id: number;
    name: string;
    number: string;
    price: number;
    room: any;
}
interface IHall {
    key?: number;
    value?: any;
    desc: string;
    floor: string;
    id: number;
    name: string;
    number: any;
    shop_id: string;
    state: number;
    tables: ITable[];
}
moment().format();

const selectOptions = [
    {key: "request", value: "request", label: "Ожидание"},
    {key: "ok", value: "ok", label: "Отклонить"},
    // { key: 'canceled', value: 'cancel', label: 'Отклонить' },
    {key: "approve", value: "approve", label: "Подтвердить"},
    {key: "complete", value: "complete", label: "Завершить"},
    {key: "cancel", value: "cancel", label: "Отказать"},
];

const formatPhoneNumber = (value) => {
    if (!value) return value;

    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 2) return "+7 (" + phoneNumber;
    if (phoneNumberLength < 5) return "+7 (" + phoneNumber.slice(1, 4) + ") ";
    if (phoneNumberLength < 8)
        return (
            "+7 (" + phoneNumber.slice(1, 4) + ") " + phoneNumber.slice(4, 7)
        );
    if (phoneNumberLength < 10)
        return (
            "+7 (" +
            phoneNumber.slice(1, 4) +
            ") " +
            phoneNumber.slice(4, 7) +
            "-" +
            phoneNumber.slice(7, 9)
        );

    return (
        "+7 (" +
        phoneNumber.slice(1, 4) +
        ") " +
        phoneNumber.slice(4, 7) +
        "-" +
        phoneNumber.slice(7, 9) +
        "-" +
        phoneNumber.slice(9, 11)
    );
};

const ErrorDisplay = ({errors, touched}) => {
    return (
        <div>
            {Object.keys(errors).map((key) =>
                touched[key] && errors[key] ? (
                    <div style={{color: "red"}} key={key} className="error">
                        {errors[key]}
                    </div>
                ) : null
            )}
        </div>
    );
};

const ErrorDisplayLocation = ({hall, table}) => {
    return (
        <div>
            {!hall && (
                <div style={{color: "red"}} key={"ErrorLocationHall"}>
                    Выберите помещение
                </div>
            )}
            {!table && (
                <div style={{color: "red"}} key={"ErrorLocationTable"}>
                    Выберите стол
                </div>
            )}
        </div>
    );
};

const ReservationModal: React.FC<PropsClientModal> = ({
    record,
    activeModal,
    modalVisibilityToggler,
}) => {
    const isNew: boolean = activeModal === "new";
    const {shop} = useSelector(authInfoSelect);
    const [dataHalls, setDataHalls] = useState<IHall[]>([]);
    const [currentHall, setCurrentHall] = useState<IHall | undefined>(
        undefined
    );
    const [dataTables, setDataTables] = useState<ITable[]>([]);
    const [currentTable, setCurrentTable] = useState<ITable | undefined>(
        undefined
    );
    const [status, setStatus] = useState<number>(record ? record.state : 0);
    const [minPrePaid, setMinPrePaid] = useState(0);
    const [showErrors, setShowErrors] = useState(false);
    const [showErrorsLocation, setShowErrorsLocation] = useState(false);
    const [dateTime, setDateTime] = useState<any>(
        isNew
            ? dayjs().set("hour", 0).set("minute", 0).set("second", 0)
            : record?.bookingStart
    ); // необходимо, что бы не сбрасывалось на текущее время в календаре

    const initialValues =
        !isNew && record
            ? {
                  state: record.state,
                  bookingStart: record.bookingStart,
                  personName: record.personName,
                  personPhone: record.personPhone,
                  guestsCount: record.guestsCount,
                  prePaid: record.prePaid,
              }
            : {
                  state: 3,
                  bookingStart: dayjs().set("minute", 0).set("second", 0),
                  personName: "",
                  personPhone: "",
                  guestsCount: 1,
                  prePaid: 0,
              };

    const getDataHalls = (url: string, setData: any) => {
        Promise.all([
            fetch(url, {
                // credentials: 'include',
                headers: {
                    accept: "application/json",
                    Authorization: `${localStorage.getItem("accessToken")}`,
                    "Content-Type": "application/json",
                },
            })
                .then((res) => res.json())
                .catch(() => []),
        ]).then(([data]) => {
            if (data && Array.isArray(data)) {
                setData(data);
                if (isNew) {
                    setCurrentHall(data.find((o) => o.id === 1));
                    setCurrentTable(undefined);
                }
            } else setData([]);
        });
    };

    useEffect(() => {
        if (record && dataHalls && !currentHall) {
            isNew
                ? setCurrentHall(dataHalls.find((o) => o.id === 1))
                : setCurrentHall(
                      dataHalls.find((o) => o.id === record.hall_id)
                  );
        }
        if (record && dataTables && !currentTable) {
            setCurrentTable(dataTables.find((o) => o.id === record.table_id));
        }
    }, [record, dataHalls, currentHall, dataTables, currentTable, isNew]);

    useEffect(() => {
        if (
            currentHall &&
            currentHall.tables &&
            currentHall.tables.length > 0
        ) {
            setDataTables(currentHall.tables);
        } else setDataTables([]);
        setCurrentTable(undefined);
    }, [currentHall]);

    useEffect(() => {
        if (currentTable && currentTable.price) {
            setMinPrePaid(currentTable.price);
            if (formik.values.prePaid < currentTable.price) {
                formik.setFieldValue("prePaid", currentTable.price);
            }
        } else setMinPrePaid(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTable]);

    useEffect(() => {
        if (shop) {
            getDataHalls(
                `${apiUrl}/shops/${shop.id}/booking/halls`,
                setDataHalls
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shop]);

    const unformatPhoneNumber = (value) => {
        return value.replace(/[^\d]/g, "");
    };

    //* формик и сабмит
    const onSubmit = (values: FormikValues) => {
        if (!shop) {
            return;
        }
        setShowErrors(true);

        const url = isNew
            ? `${apiUrl}/shops/${shop.id}/booking`
            : `${apiUrl}/shops/${shop.id}/booking/${record && record.id}`;
        const method = isNew ? "POST" : "PUT";

        let bodyReq = {...values};
        bodyReq.personePhone = unformatPhoneNumber(values.personPhone);

        if (record && status !== record.state) {
            const bookingUpdateUrl = `${apiUrl}/shops/${shop.id}/booking/${record.id}/${selectOptions[status].value}`;
            const bodyReq = {
                state: status,
            };

            fetch(bookingUpdateUrl, {
                // credentials: 'include',
                method: "POST",
                body: JSON.stringify(bodyReq),
                headers: {
                    accept: "application/json",
                    Authorization: `${localStorage.getItem("accessToken")}`,
                    "Content-Type": "application/json",
                },
            })
                .then((res) => res.json())
                .then((data) => {
                    setShowErrors(false);
                    // setCurrentHall(undefined)
                    setCurrentTable(undefined);
                })
                .catch(() => []);
        }
        bodyReq.state = record && status !== record.state ? status : 0;
        bodyReq.bookingStart = dateTime;
        if (currentHall) bodyReq.hall_id = currentHall.id;
        if (currentTable) bodyReq.table_id = currentTable.id;
        if (bodyReq.hall_id && bodyReq.table_id) {
            fetch(url, {
                method: method,
                body: JSON.stringify(bodyReq),
                headers: {
                    accept: "application/json",
                    Authorization: `${localStorage.getItem("accessToken")}`,
                    "Content-Type": "application/json",
                },
            })
                .then(() => {
                    formik.resetForm();
                    setCurrentTable(undefined);
                    setShowErrorsLocation(false);
                    modalVisibilityToggler("accept", null);
                })
                .catch((e) => console.error(`ERROR: ${e.message}`));
        } else setShowErrorsLocation(true);
    };

    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object({
            bookingStart: Yup.date().required("Дата должна быть выбрана"),
            personName: Yup.string()
                .min(2, "Минимум 2 символов")
                .required("Имя гостя является обязательным полем"),
            personPhone: Yup.string()
                .matches(
                    /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/,
                    "Некорректный номер телефона"
                )
                .required("Номер телефона обязателен"),
            // personEmail: Yup.string(),
            // personComment: Yup.string(),
            guestsCount: Yup.number()
                .min(1, "Минимум 1 гость")
                .required("Укажите количество гостей"),
            prePaid: Yup.number()
                .min(minPrePaid, `Минимальная сумма депозита ${minPrePaid}`)
                .required('Поле "Депозит" обязательно для заполнения'),
            // finishPrice: Yup.number(),
        }),
        onSubmit,
    });
    //* ------------------

    //* функционал DatePicker
    const handleDateChange = (date) => {
        setDateTime(dayjs(date));
        formik.setFieldValue("dateTime", date);
    };

    const disabledHours = () => {
        const hours: number[] = [];
        for (let i = 13; i < 23; i++) {
            hours.push(i);
        }
        return hours;
    };

    const disablePastDates = (current) => {
        // Не разрешать выбор дат до сегодняшнего дня
        return current && current < dayjs().startOf("day");
        // ниже что бы не разрешать определенные дни
        // return current && (current < dayjs().startOf('day') || current.day() < 5);
    };
    //* -------------------

    useEffect(() => {
        const formattedPhone = formatPhoneNumber(
            formik.initialValues.personPhone
        );
        formik.setFieldValue("personPhone", formattedPhone);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, ["formik.initialValues.personPhone", formik.initialValues.personPhone]);

    const handleChangePhone = (e) => {
        const {value} = e.target;
        const formattedValue = formatPhoneNumber(value);
        formik.setFieldValue("personPhone", formattedValue);
    };

    const handleCancel = (e: React.FormEvent) => {
        modalVisibilityToggler("cancel", null);
        setCurrentTable(undefined);
    };

    return (
        <Wrapper>
            <NavbarModalWrapper>
                <StyledForm
                    onSubmit={(event) => {
                        formik.handleSubmit(event);
                        setShowErrors(true);
                    }}
                >
                    <FormItemContainer>
                        <HeaderTitle>
                            {isNew
                                ? "Создание нового бронирования"
                                : `Редактирование бронирования (id=${record?.id})`}
                        </HeaderTitle>
                    </FormItemContainer>
                    <FormItemContainer>
                        <Label>Начало резерва</Label>
                        <InputStyled
                            name="dateTime"
                            value={dateTime ? dayjs(dateTime) : null}
                            onChange={handleDateChange}
                            disabledDate={disablePastDates}
                            showTime={{
                                format: "HH:mm",
                                minuteStep: 30,
                                disabledHours: disabledHours,
                            }}
                            format="YYYY-MM-DD HH:mm"
                            defaultPickerValue={dayjs()
                                .set("minute", 0)
                                .set("second", 0)}
                            hasError={
                                !!(
                                    formik.errors.bookingStart &&
                                    formik.touched.bookingStart
                                )
                            }
                        />
                    </FormItemContainer>

                    {!isNew && (
                        <FormItemContainer>
                            <Label>Статус</Label>
                            {dataTables && (
                                <NewStatusSelector
                                    record={record}
                                    status={status}
                                    setStatus={setStatus}
                                />
                            )}
                        </FormItemContainer>
                    )}
                    <FormItemContainer>
                        <Label>Имя гостя</Label>
                        <Input
                            type="text"
                            name="personName"
                            value={formik.values.personName}
                            required={
                                !!formik.errors.personName &&
                                !!formik.touched.personName
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            $hasError={
                                !!(
                                    formik.errors.personName &&
                                    formik.touched.personName
                                )
                            }
                        />
                    </FormItemContainer>
                    <FormItemContainer>
                        <Label>Номер телефона</Label>
                        <Input
                            type="text"
                            name="personPhone"
                            value={formik.values.personPhone}
                            required={
                                !!formik.errors.personPhone &&
                                !!formik.touched.personPhone
                            }
                            onChange={handleChangePhone}
                            onBlur={formik.handleBlur}
                            placeholder="+7 (___) ___-__-__"
                            $hasError={
                                !!(
                                    formik.errors.personPhone &&
                                    formik.touched.personPhone
                                )
                            }
                        />
                    </FormItemContainer>
                    <FormItemContainer>
                        <Label>Помещение</Label>
                        {dataHalls && (
                            <UniversalSelector
                                options={dataHalls.map((o) => {
                                    return {...o, key: o.id, value: o.id};
                                })}
                                setSelectValue={setCurrentHall}
                                value={currentHall}
                                showError={
                                    !!(showErrorsLocation && !currentHall)
                                }
                            />
                        )}
                    </FormItemContainer>
                    <FormItemContainer>
                        <Label>Стол</Label>
                        {dataTables && (
                            <UniversalSelector
                                options={dataTables.map((o) => {
                                    return {...o, key: o.id, value: o.id};
                                })}
                                setSelectValue={setCurrentTable}
                                value={currentTable}
                                showError={
                                    !!(showErrorsLocation && !currentTable)
                                }
                            />
                        )}
                    </FormItemContainer>
                    <FormItemContainer>
                        <Label>Количество гостей</Label>
                        <Input
                            type="number"
                            name="guestsCount"
                            value={formik.values.guestsCount}
                            required={
                                !!formik.errors.guestsCount &&
                                !!formik.touched.guestsCount
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            $hasError={
                                !!(
                                    formik.errors.guestsCount &&
                                    formik.touched.guestsCount
                                )
                            }
                        />
                    </FormItemContainer>
                    <FormItemContainer>
                        <Label>Депозит</Label>
                        <Input
                            type="number"
                            name="prePaid"
                            value={formik.values.prePaid}
                            required={
                                !!formik.errors.prePaid &&
                                !!formik.touched.prePaid
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            $hasError={
                                !!(
                                    formik.errors.prePaid &&
                                    formik.touched.prePaid
                                )
                            }
                        />
                    </FormItemContainer>
                    <FormItemContainer>
                        {showErrors && (
                            <ErrorDisplay
                                errors={formik.errors}
                                touched={formik.touched}
                            />
                        )}
                    </FormItemContainer>
                    <FormItemContainer>
                        {showErrorsLocation && (
                            <ErrorDisplayLocation
                                hall={currentHall}
                                table={currentTable}
                            />
                        )}
                    </FormItemContainer>
                    {/* Добавьте остальные поля формы аналогично */}
                    <StyledFooter>
                        <StyledButtonClose onClick={handleCancel}>
                            Закрыть
                        </StyledButtonClose>
                        <StyledButtonSave type="submit">
                            <span>{isNew ? "Создать" : "Сохранить"}</span>
                        </StyledButtonSave>
                    </StyledFooter>
                </StyledForm>
            </NavbarModalWrapper>
        </Wrapper>
    );
};

export default ReservationModal;

const InputStyled = styled(DatePicker)<{hasError?: boolean}>`
    border-color: ${(props) => (props.hasError ? "red" : "#d9d9d9")};
    z-index: 1800;
    margin-left: 20px;
    /* width: 100%; */
    .ant-picker-clear {
        position: absolute;
        right: -14px;
        top: -4px;
        /* transform: translateY(-50%); */
    }
    .ant-picker-suffix {
        position: absolute;
        right: -14px;
        top: -11px;
        /* transform: translateY(-50%); */
    }
`;

const StyledButtonClose = styled.div`
    /* Закрыть */

    width: 59px;
    height: 28px;

    /* Текст */
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    /* identical to box height, or 200% */
    display: flex;
    align-items: center;
    text-align: center;

    /* Текст/#8F8F8F текст */
    color: #8f8f8f;
    cursor: pointer;
`;

const HeaderTitle = styled.div`
    /* Клиенты */
    /* margin-top: 38px;
  margin-bottom: 26px; */
    /* width: 110px; */
    height: 28px;
    /* Заголовок */
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    /* identical to box height, or 112% */
    align-items: center;

    /* Текст/#333333 текст|кнопки */
    color: #333333;
`;

const StyledButtonSave = styled.button`
    /* Frame 37 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 58px;
    gap: 10px;
    cursor: pointer;
    width: 195px;
    height: 40px;

    /* Текст/#A7388E текст|кнопки */
    background: #a7388e;
    border-radius: 5px;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;

    & span {
        /* Сохранить */
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
        /* identical to box height, or 200% */
        align-items: center;
        text-align: center;

        /* Текст/#FFFFFF текст|иконки|кнопки */
        color: #ffffff;
    }
`;

const StyledFooter = styled.div`
    /* Frame 40 */
    display: flex;
    gap: 34px;
    flex-direction: row;
    justify-content: flex-end;
    height: 40px;
    margin-top: 16px;
    align-items: center;
    position: absolute;
    bottom: 10px;
    right: 10px;
`;

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
`;
const Wrapper = styled.div`
    overflow: hidden;
    display: flex;
    position: fixed;
    flex-direction: row;
    justify-content: flex-start;
    /* min-width: 100%; */
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background-color: #fff;
    /* box-sizing: border-box; */

    /* Auto layout */
    padding: 30px 20px;

    width: 600px;
    height: 605px;
    /* Текст/#FFFFFF текст|иконки|кнопки */
    background: #f7f7f7;
    /* #D6D6D6 графики */
    /* border: 0.5px solid #D6D6D6; */
    /* box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.08); */
    border-radius: 5px;
`;

const NavbarModalWrapper = styled.div`
    width: 560px;
    height: 46px;
    margin-bottom: 18px;
    gap: 20px;
`;

const FormItemContainer = styled.div<{
    password?: boolean;
}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    /* z-index: 0; */
    & svg {
        position: absolute;
        right: 17px;
        top: 15px;
        cursor: pointer;
    }

    ${({password}) =>
        password &&
        css`
            display: flex;
            gap: 10px;
        `}
`;

const Input = styled.input<{
    password?: boolean;
    $hasError?: boolean;
}>`
    margin-left: 20px;
    position: relative;
    width: 400px;
    height: 44px;
    padding: 0 17px 0 18px;
    border-radius: 8px;
    color: #1f1e24;
    border: 1px solid ${(props) => (props.$hasError ? "red" : "#D6DCE9")};
    font-weight: 600;
    font-size: 13px;
    line-height: 26px;
    /* z-index: 1; */

    ${({password}) =>
        password &&
        css`
            width: 156px;
        `}

    &:focus {
        border: 1px solid #e65e20;
        border-color: #e65e20;
        outline: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    &:hover {
        border: 1px solid #e65e20;
        border-color: #e65e20;
        outline: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    &:-webkit-autofill {
        -webkit-transition: "background-color 9999s ease-out";
        -webkit-transition-delay: 9999s;
    }

    ::placeholder {
        color: #a5a5a5;
    }

    :-ms-input-placeholder {
        color: #a5a5a5;
    }

    ::-ms-input-placeholder {
        color: #a5a5a5;
    }

    &:invalid {
        border: 1px solid #e65e20;
    }
`;

const Label = styled.label`
    font-weight: 600;
    width: 115px;
    font-size: 11px !important;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #000;
    margin-left: 20px;
    & span {
        color: #e65e20;
        margin-left: 4px;
    }
`;
