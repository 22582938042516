import {
    createSlice,
    PayloadAction,
    ActionReducerMapBuilder,
} from "@reduxjs/toolkit";
import {RootState} from "../../../config/store";
import {IClient, ISelector, PaginatedType} from "../../../types";
import {IClientState, ITopStats} from "./types";
import {selectOptions} from "../../../utils/const";
import {getClients, getTopStats} from "../../../api/thunks/clients";

const initialState: IClientState = {
    clients: [],
    topStats: null,
    selectedClient: null,
    selectValue: selectOptions[0],
    count: 0,
    total: 0,
    loading: "idle",
    error: null,
};

const clientSlice = createSlice({
    name: "@@client",
    initialState,
    reducers: {
        clearClienttate: () => {
            return initialState;
        },
        setSelectValue: (
            state: IClientState,
            {payload}: PayloadAction<ISelector>
        ) => {
            state.selectValue = payload;
        },
        setBloket: (
            state: IClientState,
            {payload}: PayloadAction<{id: number; isActive: boolean}>
        ) => {
            const {id, isActive} = payload;
            const index = state.clients.findIndex((item) => item.id === id);
            state.clients[index].isActive = isActive;
        },
        setRemove: (state: IClientState, {payload}: PayloadAction<number>) => {
            state.clients.filter((item) => item.id !== payload);
        },
        setSelectedClient: (
            state: IClientState,
            {payload}: PayloadAction<IClient>
        ) => {
            state.selectedClient = payload;
        },
    },

    extraReducers: (builder: ActionReducerMapBuilder<IClientState>) => {
        builder
            .addCase(
                getClients.fulfilled,
                (
                    state: IClientState,
                    {payload}: PayloadAction<PaginatedType<IClient>>
                ) => {
                    state.clients = payload.rows;
                    state.total = payload.count;
                }
            )
            .addCase(
                getTopStats.fulfilled,
                (state: IClientState, {payload}: PayloadAction<ITopStats>) => {
                    state.topStats = payload;
                }
            )

            .addMatcher(
                (action) => action.type.endsWith("/pending"),
                (state) => {
                    state.loading = "loading";
                    state.error = null;
                }
            )
            .addMatcher(
                (action) => action.type.endsWith("/rejected"),
                (state, action: PayloadAction<string>) => {
                    state.loading = "idle";
                }
            )
            .addMatcher(
                (action) => action.type.endsWith("/fulfilled"),
                (state) => {
                    state.loading = "idle";
                }
            );
    },
});

export const {
    clearClienttate,
    setSelectValue,
    setBloket,
    setRemove,
    setSelectedClient,
} = clientSlice.actions;
export const clientReducer = clientSlice.reducer;

export const clientSelect = (state: RootState) => state.client;
