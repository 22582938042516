import {createAsyncThunk} from "@reduxjs/toolkit";
import {
    ICard,
    ICardSpendStats,
    IClientCardHistory,
} from "../../../entities/card/types";
import {
    getAllCardsSpendStatsRequest,
    getCardSpendStatsRequest,
    getClientCardChangesReq,
    getClientCardReq,
    getClientCardTransactReq,
} from "../../services/cardApi";
import {axiosErrorHandler} from "../../config";
import {IBaseQuery, PaginatedType} from "../../../types";

export const getClientCardTransactions = createAsyncThunk<
    PaginatedType<IClientCardHistory>,
    {shop_id: number; client_id: number; query: IBaseQuery}
>(
    "@@card/getClientCardTransactions",
    async (
        {
            shop_id,
            client_id,
            query,
        }: {shop_id: number; client_id: number; query: IBaseQuery},
        {rejectWithValue}
    ) => {
        try {
            const {data} = await getClientCardTransactReq(
                client_id,
                shop_id,
                query
            );
            return data;
        } catch (err) {
            return rejectWithValue(axiosErrorHandler(err));
        }
    }
);

export const getClientCardChanges = createAsyncThunk<
    PaginatedType<IClientCardHistory>,
    {shop_id: number; client_id: number; query: IBaseQuery}
>(
    "@@card/getClientCardChanges",
    async (
        {
            shop_id,
            client_id,
            query,
        }: {shop_id: number; client_id: number; query: IBaseQuery},
        {rejectWithValue}
    ) => {
        try {
            const {data} = await getClientCardChangesReq(
                client_id,
                shop_id,
                query
            );
            return data;
        } catch (err) {
            return rejectWithValue(axiosErrorHandler(err));
        }
    }
);

export const getClientCards = createAsyncThunk<
    ICard[],
    {shop_id: number; client_id: number}
>(
    "@@card/getClientCards",
    async (
        {shop_id, client_id}: {shop_id: number; client_id: number},
        {rejectWithValue}
    ) => {
        try {
            const {data} = await getClientCardReq(client_id, shop_id);
            return data;
        } catch (err) {
            return rejectWithValue(axiosErrorHandler(err));
        }
    }
);

export const getCardSpendStats = createAsyncThunk<
    ICardSpendStats,
    {shop_id: number; client_id: number; card_id: number}
>(
    "@@card/getCardSpendStats",
    async (
        {
            shop_id,
            client_id,
            card_id,
        }: {shop_id: number; client_id: number; card_id: number},
        {rejectWithValue}
    ) => {
        try {
            const {data} = await getCardSpendStatsRequest(
                card_id,
                shop_id,
                client_id
            );
            return data;
        } catch (err) {
            return rejectWithValue(axiosErrorHandler(err));
        }
    }
);

export const getAllCardsSpendStats = createAsyncThunk<
    ICardSpendStats,
    {shop_id: number; client_id: number}
>(
    "@@card/getAllCardsSpendStats",
    async (
        {shop_id, client_id}: {shop_id: number; client_id: number},
        {rejectWithValue}
    ) => {
        try {
            const {data} = await getAllCardsSpendStatsRequest(
                shop_id,
                client_id
            );
            return data;
        } catch (err) {
            return rejectWithValue(axiosErrorHandler(err));
        }
    }
);
