import {useState} from "react";
import dayjs from "dayjs";
import {LegendContainer} from "./LegendContainer";
import {linesColorArray} from "../../../config/colors";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import {Line} from "react-chartjs-2";
import {styled} from "styled-components";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const footer = (tooltipItems) => {
    let sum = 0;

    tooltipItems.forEach(function (tooltipItem) {
        sum += tooltipItem.parsed.y;
    });
    return "Сумма: " + new Intl.NumberFormat("ru-RU").format(sum);
};

export function LineChart({dataSource}) {
    const [hiddenDatasets, setHiddenDatasets] = useState<number[]>([]);
    if (dataSource) {
        const {range, values} = dataSource;
        const datas = {
            labels: range,
            datasets: values.map((item, ind) => {
                return {
                    label: item.key,
                    data: item.values,
                    borderColor: linesColorArray[ind].borderColor,
                    backgroundColor: linesColorArray[ind].backgroundColor,
                    fill: false,
                    spanGaps: false, // Убедитесь, что пропуски не заполняются
                    hidden: hiddenDatasets.includes(ind),
                };
            }),
        };
        const options = {
            responsive: true,
            maintainAspectRatio: false, //отключено пропорциональное изменение сторон
            tension: 0.4,

            interaction: {
                mode: "index" as "index",
            },

            plugins: {
                tooltip: {
                    mode: "index" as "index", //вот тут прикол в том, что библиотека не распознает простое строковое значение
                    intersect: true,
                    usePointStyle: true,
                    callbacks: {
                        footer: footer,
                    },
                },
                legend: {
                    display: false, // Отключаем стандартную легенду
                    // position: 'top' as const,
                    // labels: {
                    //   usePointStyle: true,
                    //   pointStyle: 'rectRounded',

                    // }
                },
            },
            title: {
                display: false,
                text: "Chart.js Line Chart",
            },
            elements: {
                point: {
                    hoverRadius: 6,
                    backgroundColor: "#ffffff",
                    borderWidth: 1,
                    hoverBorderWidth: 2,
                },
            },
            scales: {
                x: {
                    beginAtZero: true,
                    ticks: {
                        callback: (value, index, values) => {
                            const rangeLength =
                                index % (Math.floor(range.length / 8) + 1);
                            return rangeLength === 0
                                ? dayjs(range[value]).format("DD.MM")
                                : "";
                        },
                    },
                },
                y: {
                    beginAtZero: true,
                    suggestedMin: 0,
                },
            },
        };

        return (
            <LegendWrap>
                <LegendContainer
                    datas={datas}
                    setHiddenDatasets={setHiddenDatasets}
                    hiddenDatasets={hiddenDatasets}
                />
                <Line options={options} data={datas} />
            </LegendWrap>
        );
    }
    return <></>;
}

const LegendWrap = styled.div`
    height: 83%;
`;
