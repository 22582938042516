function getRandomNumber(max: number, exclude: Set<number>): number {
    let num: number;
    do {
      num = Math.floor(Math.random() * (max + 1));
    } while (exclude.has(num));
    return num;
  }
  
  export function getRandomColors(colors: string[], count: number): string[] {
    if (count > colors.length) {
      throw new Error("Запрашиваемое количество больше доступных цветов");
    }
  
    const selectedColors: string[] = [];
    const usedIndices = new Set<number>();
  
    while (selectedColors.length < count) {
      const randomIndex = getRandomNumber(colors.length - 1, usedIndices);
      usedIndices.add(randomIndex);
      selectedColors.push(colors[randomIndex]);
    }
  
    return selectedColors;
  }