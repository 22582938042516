import { FC } from 'react';
import styled from 'styled-components';
import PeriodSelector from './PeriodSelector';
import { useDispatch, useSelector } from 'react-redux';
import { selectOptions } from '../../../utils/const';
import { clientSelect, setSelectValue } from '../../../entities/client';
import { AppDispatch } from '../../../config/store';

const HeaderInfo: FC = () => {
	const { selectValue } = useSelector(clientSelect);
	const { topStats } = useSelector(clientSelect);
	const dispatch = useDispatch<AppDispatch>();

	return (
		<HeaderInfoWrap>
			<CardInfo>
				<CardTextArea>
					<PeriodSelector
						options={selectOptions}
						setSelectValue={(val) => dispatch(setSelectValue(val))}
						value={selectValue}
					/>
					<CardText>период статистики</CardText>
				</CardTextArea>
				<CardDesc></CardDesc>
			</CardInfo>
			<CardInfo>
				<CardTextArea>
					<CardTitle>{topStats?.registrations}</CardTitle>
					<CardText>регистраций</CardText>
				</CardTextArea>
			</CardInfo>
			<CardInfo>
				<CardTextArea>
					<CardTitle>
						{new Intl.NumberFormat('ru-RU', {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						}).format(Number(topStats?.balance))}
					</CardTitle>
					<CardText>сумма депозитов</CardText>
				</CardTextArea>
			</CardInfo>
			<CardInfo>
				<CardTextArea>
					<CardTitle>
						{new Intl.NumberFormat('ru-RU', {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						}).format(Number(topStats?.bonus))}
					</CardTitle>
					<CardText>сумма бонусов</CardText>
				</CardTextArea>
			</CardInfo>
			<CardInfo>
				<CardTextArea>
					<CardTitle>
						{new Intl.NumberFormat('ru-RU', {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						}).format(Number(topStats?.discount))}
					</CardTitle>
					<CardText>сумма скидок</CardText>
				</CardTextArea>
			</CardInfo>
		</HeaderInfoWrap>
	);
};

export default HeaderInfo;

const HeaderInfoWrap = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 10px;
`;

const CardInfo = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 19%;
	min-width: 190px;
`;

const CardTextArea = styled.div`
	display: flex;
	flex-direction: column;
	padding-top: 12px;
	width: 100%;
	height: 92px;
	background: #ffffff;
	box-shadow: 0px 2px 15.2px rgba(0, 0, 0, 0.11);
	border-radius: 5px;
	flex: none;
	order: 0;
	flex-grow: 0;
`;

const CardTitle = styled.div`
	font-family: 'Inter';
	font-style: normal;
	font-weight: 600;
	font-size: 30px;
	line-height: 34px;
	align-items: center;
	padding: 5px 16px 0px 26px;
	color: #333333;
	@media (max-width: 1500px) {
		font-size: 24px;
	}
`;

const CardText = styled.div`
	height: 28px;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	padding-left: 26px;
	line-height: 28px;
	align-items: center;
	color: #8f8f8f;
	@media (max-width: 1500px) {
		padding-left: 16px;
	}
`;

const CardDesc = styled.div`
	top: 16px;
	right: 10px;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 28px;
	align-items: center;
	text-align: right;
	color: #8f8f8f;
`;
