import {useState, useEffect, useMemo} from "react";
import {Chart, Filler} from "chart.js";
// import ChartDataLabels from 'chartjs-plugin-datalabels';
import styled from "styled-components";
import annotationPlugin from "chartjs-plugin-annotation";
import BarChart from "./BarChart";
import {useApplication} from "../../../context/ApplicationContext";

import {IBarCharCard} from "../../../types";
import CardSelector from "./components/CardSelector";
import {transformCardsData} from "../../../utils/functions/transformCardsData";
import {useSelector} from "react-redux";
import {authInfoSelect} from "../../../entities/auth";

Chart.register(Filler, annotationPlugin);

const selectOptionsQ = [
    {key: "quontity", value: "quontity", label: "Количество визитов"},
    {key: "amount", value: "amount", label: "Суммы бонусов"},
];

//const urlForRemains = `${process.env.REACT_APP_API_URL}/shops/1/reports/revenue/station/days?from=2024-08-01&to=2024-09-15`;
const initialValue: IBarCharCard[] = new Array(2).fill({
    labels: [],
    datasets: [
        {
            label: "",
            data: [],
            borderRadius: 10,
            borderSkipped: false,
            barThickness: 14,
        },
    ],
});

function Plag3() {
    const {dateRange} = useApplication();
    const {shop} = useSelector(authInfoSelect);
    // const [viruchkaData, setViruchkaData] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [chartData, setChartData] = useState<IBarCharCard[]>(initialValue);
    const [selectQ, setSelectQ] = useState<number>(0);

    const urlForCardsPlagin = useMemo(() => {
        const from =
            dateRange && dateRange.from ? dateRange.from : "2024-01-01";
        const to = dateRange && dateRange.to ? dateRange.to : "2024-11-01";
        const url = `${process.env.REACT_APP_API_URL}/shops/${shop?.id}/loyalty/card-templates/count-usage?from=${from}&to=${to}`;

        return url;
    }, [dateRange, shop]);

    // const {
    //   data: viruchkaData,
    //   loading,
    //   setParams: setHeaderParams,
    // } = useFetchData(urlForRemains,
    //   [urlForRemains]);
    useEffect(() => {
        fetch(urlForCardsPlagin, {
            // credentials: 'include',
            headers: {
                accept: "application/json",
                Authorization: `${localStorage.getItem("accessToken")}`,
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setChartData(initialValue);
                if (data && data?.length > 0) {
                    setChartData(transformCardsData(data));
                }
                setLoading(false);
            })
            .catch((err) => {
                console.log("Error fetching card data:", err);
                setLoading(false);
            });
    }, [urlForCardsPlagin]);

    // useEffect(() => {
    //     if (viruchkaData && Array.isArray(viruchkaData)) {
    //         setChartData(calcSortedDataChart(viruchkaData));
    //     } else {
    //         setChartData(undefined);
    //     }
    // }, [viruchkaData]);
    return (
        <Palg3Wrap>
            <HeaderWrapper>
                <Header>Карты и лояльность</Header>
                <WrapSelect>
                    <CardSelector
                        select={selectQ}
                        setSelect={setSelectQ}
                        selectOptions={selectOptionsQ}
                    />
                </WrapSelect>
            </HeaderWrapper>
            <BarChart
                labels={chartData[selectQ].labels}
                datasets={chartData[selectQ].datasets}
            />
            <style>
                {`
          .chartjs-render-monitor {
            position: relative;
          }
          .chartjs-render-monitor .tick {
            background-color: blue;
            color: white;
            padding: 2px 4px;
            border-radius: 3px;
          }
        `}
            </style>
        </Palg3Wrap>
    );
}

export default Plag3;

const Palg3Wrap = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
`;

const Header = styled.div`
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 28px;
    /* identical to box height, or 112% */
    display: flex;
    align-items: center;

    /* Текст/#333333 текст|кнопки */
    color: #333333;
`;
const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const WrapSelect = styled.div`
    display: flex;
    align-self: flex-end;
`;
