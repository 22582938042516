import axios from "axios";
import axiosInstance, {AxiosError} from "axios";
import {StoreType} from "../../config/store";
import {handleRemoveToken} from "../../entities/auth";

export let api = axios.create();
export const API_URL = process.env.REACT_APP_API_URL;
export const setupAxiosInstance = (store: StoreType) => {
    api = axios.create({
        baseURL: API_URL,
        timeout: 30 * 1000,
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });

    api.interceptors.request.use((config) => {
        const token = localStorage.getItem("accessToken");
        if (token) {
            const authToken: string = token;
            config.headers["Authorization"] = `${authToken}`;
        } else {
            config.headers["Authorization"] = "";
        }

        return config;
    });

    api.interceptors.response.use(
        (config) => {
            return config;
        },
        async (error) => {
            const originalRequest = error.config;
            if (
                error.response?.status === 401 &&
                error.config &&
                !error.config._isRetry
            ) {
                originalRequest._isRetry = true;

                const token = localStorage.getItem("refreshToken");

                if (token) {
                    const {data} = await axios.get(
                        `${API_URL}/auth/refreshToken`,
                        {
                            params: {
                                t: token,
                            },
                        }
                    );
                    localStorage.setItem("refreshToken", data.refreshToken);
                    localStorage.setItem("accessToken", data.accessToken);
                    return api.request(originalRequest);
                } else {
                    logout(store);
                }
            } else if (error.response?.status === 406) {
                logout(store);
            } else {
                throw error;
            }

            return error;
        }
    );
};

const logout = (store: StoreType) => {
    store.dispatch(handleRemoveToken());
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("accessToken");
    window.location.href = "/login";
};

export const axiosErrorHandler = (e: unknown) => {
    interface BackendError {
        message: string;
        error: boolean;
        ok: boolean;
        details: string;
    }

    if (axiosInstance.isAxiosError(e)) {
        const error = e as AxiosError<BackendError>;
        const errArrayMessage = error?.response?.data;
        if (errArrayMessage?.error) {
            return errArrayMessage?.details;
        } else {
            return errArrayMessage?.message;
        }
    } else {
        return e;
    }
};
