export const linesColorArray = [
  { borderColor: 'rgb(221, 26, 106)', backgroundColor: 'rgba(221, 26, 106, 0.5)' },
  { borderColor: 'rgb(43, 30, 202)', backgroundColor: 'rgba(43, 30, 202, 0.5)' },
  { borderColor: 'rgb(251, 181, 0)', backgroundColor: 'rgba(251, 181, 0, 0.5)' },
  { borderColor: 'rgb(46, 194, 16)', backgroundColor: 'rgba(46, 194, 16, 0.5)' },
  { borderColor: 'rgb(53, 162, 235)', backgroundColor: 'rgba(53, 162, 235, 0.5)' },
  { borderColor: 'rgb(121, 26, 106)', backgroundColor: 'rgba(121, 26, 106, 0.5)' },
  { borderColor: 'rgb(83, 40, 202)', backgroundColor: 'rgba(48, 34, 202, 0.5)' },
  { borderColor: 'rgb(151, 101, 0)', backgroundColor: 'rgba(151, 101, 0, 0.5)' },
  { borderColor: 'rgb(246, 124, 16)', backgroundColor: 'rgba(246, 124, 16, 0.5)' },
  { borderColor: 'rgb(23, 222, 235)', backgroundColor: 'rgba(23, 222, 235, 0.5)' },
  { borderColor: 'rgb(241, 26, 156)', backgroundColor: 'rgba(241, 26, 156, 0.5)' },
  { borderColor: 'rgb(43, 30, 22)', backgroundColor: 'rgba(43, 30, 20, 0.5)' },
  { borderColor: 'rgb(251, 101, 120)', backgroundColor: 'rgba(251, 101, 120, 0.5)' },
  { borderColor: 'rgb(146, 124, 160)', backgroundColor: 'rgba(461, 1942 16, 00.5)' },
  { borderColor: 'rgb(53, 162, 235)', backgroundColor: 'rgba(53, 162, 235, 0.5)' },
  { borderColor: 'rgb(221, 26, 106)', backgroundColor: 'rgba(221, 26, 106, 0.5)' },
  { borderColor: 'rgb(43, 30, 202)', backgroundColor: 'rgba(43, 30, 202, 0.5)' },
  { borderColor: 'rgb(251, 181, 0)', backgroundColor: 'rgba(251, 181, 0, 0.5)' },
  { borderColor: 'rgb(46, 194, 16)', backgroundColor: 'rgba(46, 194, 16, 0.5)' },
  { borderColor: 'rgb(53, 162, 235)', backgroundColor: 'rgba(53, 162, 235, 0.5)' },
  { borderColor: 'rgb(221, 26, 106)', backgroundColor: 'rgba(221, 26, 106, 0.5)' },
  { borderColor: 'rgb(43, 30, 202)', backgroundColor: 'rgba(43, 30, 202, 0.5)' },
  { borderColor: 'rgb(251, 181, 0)', backgroundColor: 'rgba(251, 181, 0, 0.5)' },
  { borderColor: 'rgb(46, 194, 16)', backgroundColor: 'rgba(46, 194, 16, 0.5)' },
  { borderColor: 'rgb(53, 162, 235)', backgroundColor: 'rgba(53, 162, 235, 0.5)' },
  { borderColor: 'rgb(221, 26, 106)', backgroundColor: 'rgba(221, 26, 106, 0.5)' },
  { borderColor: 'rgb(43, 30, 202)', backgroundColor: 'rgba(43, 30, 202, 0.5)' },
  { borderColor: 'rgb(251, 181, 0)', backgroundColor: 'rgba(251, 181, 0, 0.5)' },
  { borderColor: 'rgb(46, 194, 16)', backgroundColor: 'rgba(46, 194, 16, 0.5)' },
  { borderColor: 'rgb(53, 162, 235)', backgroundColor: 'rgba(53, 162, 235, 0.5)' },
]

export const barsColorArray = [
  "#1f77b4", 
  "#ff7f0e", 
  "#2ca02c",
  "#d62728", 
  "#9467bd", 
  "#8c564b", 
  "#e377c2", 
  "#bcbd22",
  "#17becf", 
  "#FF1493", 
  "#00BFFF", 
  "#FFD700", 
  "#ADFF2F", 
  "#FF4500", 
  "#9400D3", 
  "#00FF7F", 
  "#FF6347", 
  "#7B68EE",
  "#40E0D0", 
  "#7f7f7f", 
];