import {toast, ToastOptions, ToastPosition} from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import {ENotificationStatus} from "../enums";

export const sendNotification = (
    message: string,
    type: ENotificationStatus,
    position?: ToastPosition
) => {
    const toastOptions: ToastOptions = {
        position,
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        rtl: false,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true,
        type,
        theme: "colored",
        style: {
            width: "100%",
        },
    };

    toast.dismiss();

    toast(message, toastOptions);
};
