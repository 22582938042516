import {  createAsyncThunk } from '@reduxjs/toolkit';
import { axiosErrorHandler } from '../../config';
import {  getNotifyRequest, getTemplatesReuest, updateNotifyRequest } from '../../services/notifyApi';
import { ITemaplate } from '../../../entities/card/types';
import { INotifyRequest } from '../../../entities/notify/types';
import { IBaseQuery, PaginatedType } from '../../../types';
import React from 'react';
import { INotifyUpdateRequest } from '../../services/notifyApi/types';


export const getTemplates = createAsyncThunk<ITemaplate[], number>(
    '@@card/getTemplates',
    async (shop_id: number, {rejectWithValue}) => {
      try {
        const {data} = await getTemplatesReuest(shop_id);
        return data;
      } catch (err) {
        return rejectWithValue(axiosErrorHandler(err));
      }
    },
  );


  export const getNotifys = createAsyncThunk<PaginatedType<INotifyRequest>, {shop_id: number, params: IBaseQuery}>(
    '@@notify/getNotifys',
    async ({shop_id, params}: {shop_id: number, params: IBaseQuery}, {rejectWithValue}) => {
      try {
        const {data} = await getNotifyRequest({shop_id, params});
        return data;
      } catch (err) {
        return rejectWithValue(axiosErrorHandler(err));
      }
    },
  );

  export const updateNotify = createAsyncThunk<INotifyUpdateRequest, {shop_id: number; notify_id: React.Key[]; body: INotifyUpdateRequest}>(
    '@@notify/updateNotify',
    async ({shop_id, notify_id, body}: {shop_id: number; notify_id: React.Key[]; body: INotifyUpdateRequest}, {rejectWithValue}) => {
      try {
        const {data} = await updateNotifyRequest({shop_id, notify_id, data: body})
        return data;
      } catch (err) {
        return rejectWithValue(axiosErrorHandler(err));
      }
    },
  );