import { IBookingReportResponse } from '../../types';


export const transformBookingData = (
	res: IBookingReportResponse,
): { labels: string[]; data: number[], diff: number, total: number } => {
	const {rows, diff, total} = res
	let labels: string[] = [];
	let data: number[] = [];

	if (!res || !rows?.length) {
		return { labels, data, diff: 0, total:0};
	}

	res.rows.sort((a, b) => {
		return a.date > b.date ? 1 : a.date < b.date ? -1 : 0;
	  });

	res.rows.forEach((row) => {
		labels.push(convertDate(row.date));
		data.push(row.count);
	});
	return { labels, data, diff,total  };
};

export const convertDate = (date: string): string => {
	const data = date.split('-');
	return `${data[2]}.${data[1]}`;
};
