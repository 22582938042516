import {Icons} from "../assets";
import {IShop} from "../types";
import {isAllow} from "../utils/functions/allowMenu";

const {HeaderIcons} = Icons;

const navAllow = (self: string, val: string = "r"): boolean => {
    const data = localStorage.getItem("shop");

    if (data) {
        const rules: IShop = JSON.parse(data);

        return isAllow(rules.role.acl, self, val);
    }
    return false;
};

export const navigationItems = {
    navbar: [
        {
            name: "Дашборд ",
            to: "/dashboard",
            icon: <HeaderIcons.IconHeaderRkeeper />,
            text: "Дашборд",
            height: 195,
            isAllow: navAllow("dashboard"),
        },
        {
            name: "RKeeper ",
            to: "/rkeeper",
            icon: <HeaderIcons.IconHeaderRkeeper />,
            text: "rkeeper",
            height: 195,
            isAllow: navAllow("rkeeper"),
        },
        {
            name: "Бронирование",
            to: "/booking",
            icon: <HeaderIcons.IconHeaderBooking />,
            text: "booking",
            height: 195,
            isAllow: navAllow("booking"),
        },
        {
            name: "CRM ",
            to: "/crm",
            icon: <HeaderIcons.IconHeaderCrm />,
            text: "crm",
            height: 120,
            isAllow: navAllow("crm"),
        },
        // {
        //   name: 'Видеоаналитика',
        //   to: '/video',
        //   icon: <HeaderIcons.IconHeaderVideo />,
        //   text: 'video',
        //   height: 90,
        // },
        {
            name: "Настройки",
            to: "/settings",
            icon: <HeaderIcons.IconHeaderSetting />,
            text: "settings",
            height: 120,
            isAllow: navAllow("settings"),
        },
    ],
    rkeeper: [
        {
            name: "Продажи ",
            desc: "Продажи",
            icon: <HeaderIcons.IconHeaderRkeeper />,
            to: "/rkeeper/viruchka",
            text: "rkeeper_viruchka",
            isAllow: navAllow("rkeeper.viruchka"),
            subs: [
                {
                    name: "Выручка ",
                    to: "/rkeeper/viruchka",
                    text: "rkeeper_viruchka",
                    isAllow: navAllow("rkeeper.viruchka"),
                },
                {
                    name: "Динамика ",
                    to: "/rkeeper/dynamic",
                    text: "rkeeper_dynamic",
                    isAllow: navAllow("rkeeper.dynamic"),
                },
            ],
        },
        {
            name: "Товары ",
            desc: "Товары ",
            icon: <HeaderIcons.IconHeaderRkeeper />,
            to: "/rkeeper/menu",
            text: "rkeeper_menu",
            isAllow: navAllow("rkeeper.menu"),
            subs: [
                {
                    name: "Меню ",
                    to: "/rkeeper/menu",
                    text: "rkeeper_menu",
                    isAllow: navAllow("rkeeper.menu"),
                },
                {
                    name: "ТОП-позиции ",
                    to: "/rkeeper/toppositions",
                    text: "rkeeper_toppositions",
                    isAllow: navAllow("rkeeper.toppositions"),
                },
                {
                    name: "СТОП-позиции ",
                    to: "/rkeeper/stoppositions",
                    text: "rkeeper_stoppositions",
                    isAllow: navAllow("rkeeper.stoppositions"),
                },
                //   {
                //   name: 'Анализ продаж ',
                //   to: '/rkeeper/dynamic',
                //   text: 'rkeeper_dynamic',
                // }
            ],
        },
        {
            name: "Склад ",
            desc: "Склад ",
            icon: <HeaderIcons.IconHeaderRkeeper />,
            to: "/rkeeper/menu",
            text: "rkeeper_menu",
            isAllow: navAllow("rkeeper.menu"),
            subs: [
                {
                    name: "Остатки ",
                    to: "/rkeeper/ostatki",
                    text: "rkeeper_ostatki",
                    isAllow: navAllow("rkeeper.ostatki"),
                },
                //   {
                //   name: 'Инвентаризации ',
                //   to: '/rkeeper/ostatki',
                //   text: 'rkeeper_ostatki',
                // }, {
                //   name: 'Анализ себестоимости ',
                //   to: '/rkeeper/ostatki',
                //   text: 'rkeeper_ostatki',
                // }
            ],
        },
    ],
    booking: [
        {
            name: "Бронирование ",
            desc: "Бронирование ",
            icon: <HeaderIcons.IconHeaderBooking />,
            to: "/booking/reservation",
            text: "booking_reservation",
            isAllow: navAllow("booking.reservation"),
            subs: [
                {
                    name: "Бронирование ",
                    to: "/booking/reservation",
                    text: "booking_reservation",
                    isAllow: navAllow("booking.reservation"),
                },
            ],
        },
        {
            name: "Статистика ",
            desc: "Статистика ",
            icon: <HeaderIcons.IconHeaderBooking />,
            to: "/booking/bronirovanie",
            text: "booking_bronirovanie",
            isAllow: navAllow("booking.bronirovanie"),
            subs: [
                {
                    name: "Отчет по бронированию ",
                    to: "/booking/bronirovanie",
                    text: "booking_bronirovanie_report",
                    isAllow: navAllow("booking.bronirovanie"),
                },
                {
                    name: "Отчет по звонкам ",
                    to: "/booking/callsreport",
                    text: "booking_callsreport",
                    isAllow: navAllow("booking.callsreport"),
                },
                // {
                // name: 'Отчет по промоутерам ',
                // to: '/booking/promoreport',
                // text: 'booking_promoreport',
                // }
            ],
        },
        {
            name: "Promo ",
            desc: "Promo ",
            icon: <HeaderIcons.IconHeaderBooking />,
            to: "/booking/bronirovanie",
            text: "booking_promo",
            isAllow: navAllow("booking.promo"),
            subs: [
                {
                    name: "Афиши ",
                    to: "/booking/afishi",
                    text: "booking_afishi",
                    isAllow: navAllow("booking.promo.afishi"),
                },
                // {
                // name: 'Схема зала ',
                // to: '/booking/bronirovanie',
                // text: 'booking_hall',
                // },
                {
                    name: "Список промоутеров ",
                    to: "/booking/promouters",
                    text: "booking_promouters",
                    isAllow: navAllow("booking.promo.promouters"),
                },
                // {
                // name: 'Реферальная программа ',
                // to: '/booking/bronirovanie',
                // text: 'booking_ref',
                // }
            ],
        },
    ],
    crm: [
        {
            name: "Клиенты ",
            desc: "Клиенты ",
            icon: <HeaderIcons.IconHeaderCrm />,
            to: "/crm/clients",
            text: "crm_clients",
            isAllow: navAllow("crm.clients", "w"),
            subs: [
                {
                    name: "Клиенты",
                    to: "/crm/clients",
                    text: "crm_clients",
                    isAllow: navAllow("crm.clients", "w"),
                },
            ],
        },
        {
            name: "Push-уведомления",
            desc: "Push-уведомления",
            icon: <HeaderIcons.IconHeaderCrm />,
            to: "/crm/notify",
            text: "crm_notify",
            isAllow: navAllow("crm.notify"),
            subs: [
                {
                    name: "Push-уведомления",
                    to: "/crm/notify",
                    text: "crm_notify",
                    isAllow: navAllow("crm.notify"),
                },
            ],
        },

        // {
        //   name: 'Маркетинг ',
        //   desc: 'Маркетинг ',
        //   icon: <Icons.IconCalendarClockFill />,
        //   to: '/crm/marketing',
        //   text: 'crm_marketing',
        //   subs: [
        //       // {
        //       // name: 'Дашборд ',
        //       // to: '/crm/marketing',
        //       // text: 'crm_marketing',
        //       // },
        //       {
        //       name: 'Источники ',
        //       to: '/crm/clients_origins',
        //       text: 'crm_clients_origins',
        //     },{
        //       name: 'Карты и макеты ',
        //       to: '/crm/clients_cards',
        //       text: 'crm_clients_cards',
        //     },{
        //       name: 'Рассылки ',
        //       to: '/crm/clients_push',
        //       text: 'crm_clients_push',
        //     },{
        //       name: 'Лояльность ',
        //       to: '/crm/clients_loyality',
        //       text: 'crm_clients_loyality',
        //     },{
        //       name: 'Формы регистрации ',
        //       to: '/crm/reg_forms',
        //       text: 'crm_reg_forms',
        //     },

        //   ]
        // },
        // {
        //   name: 'Статистика и отчеты ',
        //   desc: 'Статистика ',
        //   icon: <Icons.IconCalendarClockFill />,
        //   to: '/crm/statistics',
        //   text: 'crm_statistics',
        //   subs: [{
        //     name: 'Посещения ',
        //     to: '/crm/clients',
        //     text: 'crm_clients',
        //   },{
        //     name: 'Анализ продаж ',
        //     to: '/crm/clients_origins',
        //     text: 'crm_clients_origins',
        //   },{
        //     name: 'Регистрации ',
        //     to: '/crm/clients_cards',
        //     text: 'crm_clients_cards',
        //   },{
        //     name: 'Сегменты ',
        //     to: '/crm/clients_push',
        //     text: 'crm_clients_push',
        //   },
        //   //   {
        //   //   name: 'Заказанные отчеты ',
        //   //   to: '/crm/reports',
        //   //   text: 'crm_reports',
        //     // }
        //   ]
        // },
        // {
        //   name: 'Справочники ',
        //   desc: 'Справлчники ',
        //   icon: <Icons.HeaderIcons.IconHeaderCrm />,
        //   to: '/crm/helpers',
        //   text: 'crm_helpers',
        //   subs: [{
        //     name: 'Теги для карт',
        //     to: '/crm/clients',
        //     text: 'crm_clients',
        //   },{
        //     name: 'Пункт 2 ',
        //     to: '/crm/clients_origins',
        //     text: 'crm_clients_origins',
        //   },{
        //     name: 'Пункт 3 ',
        //     to: '/crm/clients_cards',
        //     text: 'crm_clients_cards',
        //   }]
        // },
    ],
    // video: [
    //   {
    //     name: 'Посещаемость ',
    //     desc: 'Посещаемость ',
    //     icon: <Icons.IconCalendarClockFill />,
    //     to: '/video/attendance',
    //     text:'video_attendance'
    //   },
    //   // {
    //   //   name: 'Сегменты ',
    //   //   desc: 'Сегменты ',
    //   //   icon: <Icons.IconCalendarClockFill />,
    //   //   to: '/video/segments',
    //   //   text:'video_segments'
    //   // },
    //   {
    //     name: 'Временной анализ',
    //     desc: 'Настройки ',
    //     icon: <Icons.IconCalendarClockFill />,
    //     to: '/video/zoneanalitics',
    //     text:'video_zoneanalitics'
    //   },
    //   {
    //     name: 'LTV ',
    //     desc: 'LTV ',
    //     icon: <Icons.IconCalendarClockFill />,
    //     to: '/video/ltv',
    //     text:'video_ltv'
    //   },
    // ],
    settings: [
        // {
        //   name: 'Аккаунт ',
        //   desc: 'Аккаунт ',
        //   icon: <Icons.IconCalendarClockFill />,
        //   to: '/settings/account',
        //   text:'settings_account'
        // },
        // {
        //   name: 'Интеграция ',
        //   desc: 'Интеграция ',
        //   icon: <Icons.IconCalendarClockFill />,
        //   to: '/settings/integration',
        //   text:'settings_integration'
        // },
        {
            name: "Аккаунт",
            to: "/settings",
            icon: <HeaderIcons.IconHeaderSetting />,
            text: "settings",
            isAllow: navAllow("settings"),
        },
        {
            name: "Точки продаж ",
            desc: "Точки продаж ",
            icon: <HeaderIcons.IconHeaderSetting />,
            to: "/settings/salepoints",
            text: "settings_salepoints",
            isAllow: navAllow("settings.salepoints"),
        },

        // {
        //   name: 'Помощь ',
        //   desc: 'Помощь ',
        //   icon: <HeaderIcons.IconHeaderSetting />,
        //   to: '/settings/help',
        //   text:'settings_help'
        // },
    ],
    footer: [],
};
export default navigationItems;
