import React from "react";
import {Routes, Route, Navigate} from "react-router-dom";

import InnerContent from "./components/InnerContent";
import ProtectedRoutes from "./components/ProtectedRoutes";

import LoginPage from "./pages/LoginPage";

import Dash from "./pages/dashboard/Dash";
import DashboardPage from "./pages/dashboard/Dashboard";

import Rkeeper from "./pages/rkeeper/Rkeeper";
import Viruchka from "./pages/rkeeper/ViruchkaPage";
import DynamicPage from "./pages/rkeeper/DynamicPage";
import Ostatki from "./pages/rkeeper/OstatkiPage";
import Toppositions from "./pages/rkeeper/ToppositionsPage";
import Stoppositions from "./pages/rkeeper/StoppositionsPage";
import MenuPage from "./pages/rkeeper/MenuPage";

import Booking from "./pages/booking/Booking";
import ReservationPage from "./pages/booking/ReservationPage";
import BronirovaniePage from "./pages/booking/BronirovaniePage";
import CallsReportPage from "./pages/booking/CallsReportPage";
import PromoReportPage from "./pages/booking/PromoReportPage";
import AfishiPage from "./pages/booking/AfishiPage";
import PromoutersPage from "./pages/booking/PromoutersPage";
import CrmPage from "./pages/crm/CrmPage";
import ClientsPage from "./pages/crm/ClientsPage";
import OriginsPage from "./pages/crm/OriginsPage";
import MarketingPage from "./pages/crm/MarketingPage";
import StatisticsPage from "./pages/crm/StatisticsPage";
import ReportsPage from "./pages/crm/ReportsPage";
import RegFormsPage from "./pages/crm/RegFormsPage";
import RegFormModal from "./pages/crm/RegFormModal";

// import VideoPage from "./pages/video/VideoPage"
// import AttendancePage from "./pages/video/AttendancePage"
// import ZoneAnaliticsPage from "./pages/video/ZoneAnaliticsPage"
// import LTVPage from "./pages/video/LTVPage"
import SettingsPage from "./pages/settings/SettingsPage";
import AccountPage from "./pages/settings/AccountPage";
import ShopsPage from "./pages/settings/ShopsPage";

import Missing from "./pages/Missing";

import {navigationItems} from "./config";
import PushNotifyPage from "./pages/crm/PushNotifyPage";

const MainRoutes = () => (
    <Routes key={"main-route"}>
        {/** Protected Routes */}
        {/** Wrap all Route under ProtectedRoutes element */}
        <Route key={"ProtectedRoutes"} path="/" element={<ProtectedRoutes />}>
            <Route key={"InnerContent"} path="/" element={<InnerContent />}>
                <Route
                    key={"todashboard"}
                    path="/"
                    element={<Navigate replace to="dashboard" />}
                />
                <Route
                    key={"Dash"}
                    path="dashboard"
                    element={<Dash sidemenu={navigationItems.rkeeper} />}
                >
                    <Route
                        key={"to-main"}
                        path="/dashboard"
                        element={<Navigate replace to="main" />}
                    />
                    <Route
                        key={"DashboardPage"}
                        path="main"
                        element={<DashboardPage />}
                    />
                </Route>
                <Route
                    key={"toRkeeper"}
                    path="rkeeper"
                    element={<Rkeeper sidemenu={navigationItems.rkeeper} />}
                >
                    <Route
                        key={"toviruchka"}
                        path="/rkeeper"
                        element={<Navigate replace to="viruchka" />}
                    />
                    <Route
                        key={"Viruchka"}
                        path="viruchka"
                        element={<Viruchka />}
                    />
                    <Route
                        key={"DynamicPage"}
                        path="dynamic"
                        element={<DynamicPage />}
                    />
                    <Route
                        key={"MenuPage"}
                        path="menu"
                        element={<MenuPage />}
                    />
                    <Route
                        key={"Ostatki"}
                        path="ostatki"
                        element={<Ostatki />}
                    />
                    <Route
                        key={"Toppositions"}
                        path="toppositions"
                        element={<Toppositions />}
                    />
                    <Route
                        key={"Stoppositions"}
                        path="stoppositions"
                        element={<Stoppositions />}
                    />
                </Route>

                <Route
                    key={"toBooking"}
                    path="booking"
                    element={<Booking sidemenu={navigationItems.booking} />}
                >
                    <Route
                        key={"toreservation"}
                        path="/booking"
                        element={<Navigate replace to="reservation" />}
                    />
                    <Route
                        key={"ReservationPage"}
                        path="reservation"
                        element={<ReservationPage />}
                    />
                    <Route
                        key={"BronirovaniePage"}
                        path="bronirovanie"
                        element={<BronirovaniePage />}
                    />
                    <Route
                        key={"CallsReportPage"}
                        path="callsreport"
                        element={<CallsReportPage />}
                    />
                    <Route
                        key={"PromoReportPage"}
                        path="promoreport"
                        element={<PromoReportPage />}
                    />
                    <Route
                        key={"AfishiPage"}
                        path="afishi"
                        element={<AfishiPage />}
                    />
                    <Route
                        key={"PromoutersPage"}
                        path="promouters"
                        element={<PromoutersPage />}
                    />
                </Route>

                <Route
                    key={"toCrmPage"}
                    path="crm"
                    element={<CrmPage sidemenu={navigationItems.crm} />}
                >
                    <Route
                        key={"tomarketing"}
                        path="/crm"
                        element={<Navigate replace to="clients" />}
                    />
                    <Route
                        key={"ClientsPage"}
                        path="clients"
                        element={<ClientsPage />}
                    />
                    <Route
                        key={"PushNotifyPage"}
                        path="notify"
                        element={<PushNotifyPage />}
                    />
                    <Route
                        key={"OriginsPage"}
                        path="clients_origins"
                        element={<OriginsPage />}
                    />
                    <Route
                        key={"MarketingPage"}
                        path="marketing"
                        element={<MarketingPage />}
                    />
                    <Route
                        key={"StatisticsPage"}
                        path="statistics"
                        element={<StatisticsPage />}
                    />
                    <Route
                        key={"ReportsPage"}
                        path="reports"
                        element={<ReportsPage />}
                    />
                    <Route
                        key={"RegFormModal"}
                        path="reg_form"
                        element={<RegFormModal />}
                    />
                    <Route
                        key={"RegFormsPage"}
                        path="reg_forms"
                        element={<RegFormsPage />}
                    />
                </Route>

                {/* <Route key={'toVideoPage'} path="video" element={<VideoPage sidemenu={navigationItems.video} />} >
					<Route key={'toattendance'} path="/video" element={<Navigate replace to="attendance" />} />
					<Route key={'AttendancePage'} path="attendance" element={<AttendancePage />} />
					<Route key={'ZoneAnaliticsPage'} path="zoneanalitics" element={<ZoneAnaliticsPage />} />
					<Route key={'LTVPage'} path="ltv" element={<LTVPage />} />
				</Route> */}

                <Route
                    key={"toSettingsPage"}
                    path="settings"
                    element={
                        <SettingsPage sidemenu={navigationItems.settings} />
                    }
                >
                    <Route
                        key={"toaccount"}
                        path="/settings"
                        element={<Navigate replace to="account" />}
                    />
                    <Route
                        key={"AccountPage"}
                        path="account"
                        element={<AccountPage />}
                    />
                    <Route
                        key={"ShopsPage"}
                        path="salepoints"
                        element={<ShopsPage />}
                    />
                </Route>
            </Route>
        </Route>

        {/** Public Routes */}
        {/** Wrap all Route under PublicRoutes element */}
        <Route key={"tpLoginPage"} path="login" element={<LoginPage />}>
            <Route key={"LoginPage"} path="/login" element={<LoginPage />} />
        </Route>

        {/** Permission denied route */}
        <Route key={"Missing"} path="/denied" element={<Missing />} />
    </Routes>
);

export default MainRoutes;
