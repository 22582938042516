import {useRef, useState, useEffect} from "react";
import styled from "styled-components";
import {Icons} from "../../../../assets";

interface IProps {
    setSelect?: any;
    select?: any;
    selectOptions: {
        key: string;
        value: string;
        label: string;
    }[];
}

const CardSelector = (props: IProps) => {
    const [switchIsOpen, setSwitchIsOpen] = useState(false);
    const {select, setSelect, selectOptions} = props;

    const dropdownRef = useRef<HTMLDivElement>(null);

    const swithHandler = (ind: string | number) => {
        if (ind !== select) {
            setSelect(ind);
        }
        setSwitchIsOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                setSwitchIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    return (
        <NoiseNameContainer $isOpen={switchIsOpen}>
            {switchIsOpen ? (
                <Label
                    onClick={() => {
                        setSwitchIsOpen(!switchIsOpen);
                    }}
                >
                    {selectOptions[select].label}
                </Label>
            ) : (
                <TitleLabel
                    onClick={() => {
                        setSwitchIsOpen(!switchIsOpen);
                    }}
                >
                    {selectOptions[select].label}
                </TitleLabel>
            )}
            <NoiseSelectorBtn
                $isOpen={switchIsOpen}
                onClick={() => {
                    setSwitchIsOpen(!switchIsOpen);
                }}
            >
                {switchIsOpen ? <Icons.IconArrowUp /> : <Icons.IconArrowDown />}
            </NoiseSelectorBtn>
            {switchIsOpen && (
                <SelectorDropdown ref={dropdownRef}>
                    {selectOptions?.map((item, ind) => (
                        <>
                            <Br />
                            <Label
                                decl={false}
                                key={item.key}
                                onClick={() => swithHandler(ind)}
                            >
                                <span>{item.label}</span>
                            </Label>
                        </>
                    ))}
                </SelectorDropdown>
            )}
        </NoiseNameContainer>
    );
};

export default CardSelector;

const TitleLabel = styled.div`
    /* Frame 427322058 */
    /* box-sizing: border-box; */
    /*min-width: 195px;*/
    height: 30px;
    width: 170px;
    /* #8F8F8F 30% */
    /* Подтвержден */
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    /* identical to box height, or 200% */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    /* Текст/#333333 текст|кнопки */
    color: #a7388e;

    & span {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 28px;
        /* identical to box height, or 200% */
        color: #a7388e;
    }
`;

const Br = styled.div`
    width: 136px;
    height: 1px;
    border: 1px solid #eeefef;
`;
const Label = styled.div<{
    decl?: boolean;
}>`
    /* Frame 427322058 */

    /* box-sizing: border-box; */

    width: 190px;
    height: 30px;
    /* #8F8F8F 30% */
    /* Подтвержден */

    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 13px;
    /* identical to box height, or 200% */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    /* Текст/#333333 текст|кнопки */
    color: #333333;

    & span {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 28px;
        /* identical to box height, or 200% */
        color: ${({decl}) => (decl ? "#DD1A6A" : "#333333")};
    }
`;

const SelectorDropdown = styled.div`
    width: 190px;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    justify-content: center;
    z-index: 8;
    padding-top: 10px;
    align-items: center;
    top: 22px;
    left: -1px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: 1px solid rgba(143, 143, 143, 0.3);
    border-top: none;
    border-radius: 0 0 5px 5px;

    /* border-radius: 8px;
  box-shadow: 0px 0px 8px 0px rgb(158 158 158 / 40%); */

    ::-webkit-scrollbar {
        width: 14px;
        /* border-radius: 8px; */
        background-color: inherit;
    }

    ::-webkit-scrollbar-thumb {
        height: 140px;
        background-color: #e7e7e7;
        /* border-radius: 8px;
    border: 5px solid #fff; */
    }
`;

const NoiseSelectorBtn = styled.div<{
    $isOpen: boolean;
}>`
    position: absolute;
    right: 8px;
    top: 8px;
    & svg path {
        fill: ${({$isOpen}) => ($isOpen ? "#1F1E24" : "#A7388E")};
    }
`;

// const SelectorNoiseNameContainer = styled.div`
//   display: flex;
//   justify-content: flex-start;
//   align-items: left;
//   text-align: left;
//   width: 257px;
//   padding: 0px;
//   border-radius: 8px;
//   color: #1F1E24;
//   font-weight: 600;
//   font-size: 18px;
//   line-height: 26px;
//   letter-spacing: 0.02em;
//   cursor: pointer;
//   margin-top: 0px;
//   padding-left: 10px;

//   :hover {
//     background-color: #f5f5f5;
//   }
// `

const NoiseNameContainer = styled.div<{
    $isOpen: boolean;
}>`
    /* Frame 195 */
    width: 190px;
    height: 30px;
    justify-content: space-between;
    /* Текст/#FFFFFF текст|иконки|кнопки */
    background: #ffffff;
    box-shadow: ${({$isOpen}) =>
        $isOpen ? "0px 2px 15.2px rgba(0, 0, 0, 0.11)" : "none"};
    border: ${({$isOpen}) => ($isOpen ? "1px solid #D6DCE9" : "none")};
    border-radius: 8px;
    position: relative;
    display: flex;
    font-weight: 600;
    font-size: 11px;
    line-height: 11px;
    /* letter-spacing: 0.02em; */
    color: ${({$isOpen}) => ($isOpen ? "#1F1E24" : "red")};
    /* text-transform: capitalize; */
    cursor: pointer;
`;
