/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState, useCallback} from "react";
import _ from "lodash";

interface QueryParams {
    [key: string]: string | number | undefined;
}

const useFetchData = <T,>(
    url?: string,
    initialParams: any[] = [],
    queryParams?: QueryParams,
    initialLoading = false
) => {
    const [data, setData] = useState<T>();
    const [loading, setLoading] = useState(initialLoading);
    const [error, setError] = useState<Error>();
    const [params, setParams] = useState(initialParams);

    const buildUrl = useCallback(() => {
        if (!url) return "";
        const queryString =
            queryParams && Object.keys(queryParams).length
                ? `?${new URLSearchParams(
                      queryParams as Record<string, string>
                  ).toString()}`
                : "";
        return `${url}${queryString}`;
    }, [url, queryParams]);

    const fetchData = useCallback(async () => {
        const fullUrl = buildUrl();
        if (!fullUrl) return;

        setLoading(true);
        try {
            const response = await fetch(fullUrl, {
                method: "GET",
                headers: {
                    accept: "application/json",
                    Authorization: `${
                        localStorage.getItem("accessToken") || ""
                    }`,
                    "Content-Type": "application/json",
                },
            });
            const result: T = await response.json();
            setData(result);
            setError(undefined);
        } catch (err) {
            setError(err as Error);
            setData(undefined);
        } finally {
            setLoading(false);
        }
    }, [buildUrl]);

    useEffect(() => {
        if (!_.isEmpty(url)) {
            fetchData();
        }
    }, [fetchData]);

    return {data, loading, error, params, setParams};
};

export default useFetchData;
