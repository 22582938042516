import { NotificationInstance } from 'antd/lib/notification/interface';
import { NotificationType } from '../../types';
import { Alert } from 'antd';

export const openNotificationWithIcon = (
	api: NotificationInstance,
	type: NotificationType,
	title: string,
	subtitle: string,
) => {
	if (type === 'error') {
		api.error({
			message: title,
			description: <Alert message={subtitle} type={type} />,
		});
	}
	if (type === 'success') {
		api.success({
			message: title,
			description: <Alert message={subtitle} type={type} />,
		});
	}
	if (type === 'info') {
		api.info({
			message: title,
			description: <Alert message={subtitle} type={type} />,
		});
	}
};
