import React, {useState, useEffect} from "react";
import {CookiesProvider, useCookies} from "react-cookie";
import MainRoutes from "./Routes";
import GlobalStyles from "./styles/GlobalStyles";
import {ConfigProvider} from "antd";
import ApplicationContext, {Theme} from "./context/ApplicationContext";
import SidebarsContext, {useSidebarsContext} from "./context/SidebarsContext";
import {IUser, IDateRangeType} from "./types";
import moment from "./config/momentConfig";
import ruRU from "antd/lib/locale/ru_RU";
import dayjs from "dayjs";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, store} from "./config/store";
import {setupAxiosInstance} from "./api/config";
import {ToastContainer} from "react-toastify";
import {authInfoSelect} from "./entities/auth";
import {getMe} from "./api/thunks/auth";
import {checkIsResponseStatus} from "./utils/functions";
import {useLocation, useNavigate} from "react-router-dom";
import {customTheme} from "./utils/const";
import {ThemeProvider} from "styled-components";
import {colors, fonts} from "./styles";

const DATE_FORMAT = "YYYY-MM-DD";

export const getTodayDateRange = () => {
    return {
        from: moment().utc().add(-1, "month").format(DATE_FORMAT).valueOf(),
        to: moment().utc().format(DATE_FORMAT).valueOf(),
    };
};

const App: React.FC = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setupAxiosInstance(store);
    const {search} = window.location;
    const searchParams = new URLSearchParams(search);
    const {token, shop} = useSelector(authInfoSelect);
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const [theme, setTheme] = useState<Theme>("light");
    const location = useLocation();
    const [rangeStart, setRangeStart] = useState<string | undefined>(undefined);
    const [rangeEnd, setRangeEnd] = useState<string | undefined>(undefined);
    const [periodDate, setSetPeriodDate] = useState<string | undefined>(
        undefined
    );
    const [inventGroups, setInventGroups] = useState<any>(undefined);
    const [lastShift, setLastShift] = useState<string | undefined>(undefined);
    const [lastShiftLoad, setLastShiftLoad] = useState<boolean>(true);
    const [dateFilterMain, setDateFilterMain] = useState<string | undefined>(
        () => {
            if (searchParams.get("date")) {
                return moment(searchParams.get("date"))
                    .format(DATE_FORMAT)
                    .valueOf();
            }
            return moment().format(DATE_FORMAT).valueOf();
        }
    );

    const [dateRange, setDateRange] = useState<IDateRangeType>(() => {
        return getTodayDateRange();
    });
    const from = location.state?.from?.pathname || "/";

    //! непрвильно - поменять - поэтому и не работало. пропустить через useState()
    const {isActiveSubmenu, setActiveSubmenu, numActiveSubmenu} =
        useSidebarsContext();

    useEffect(() => {
        const authTokens = localStorage.getItem("accessToken");
        if (!authTokens) {
            navigate("/login", {replace: true});
        }
        if (!token && authTokens) {
            dispatch(
                getMe({
                    checkResponse: (status: number) => {
                        if (checkIsResponseStatus(status)) {
                            navigate(from, {replace: true});
                        }
                    },
                })
            );
        }
    }, [token, shop, dispatch, from, navigate]);

    useEffect(() => {
        if (!lastShift && lastShiftLoad && shop)
            fetch(
                `${process.env.REACT_APP_API_URL}/shops/${shop.id}/last-closed-shift`,
                {
                    // credentials: 'include',
                    headers: {
                        accept: "application/json",
                        Authorization: `${localStorage.getItem("accessToken")}`,
                        "Content-Type": "application/json",
                    },
                }
            )
                .then((resp) => resp.json())
                .then((json) => {
                    const last =
                        json && json.SHIFTDATE
                            ? dayjs(json.SHIFTDATE).format("YYYY-MM-DD")
                            : "2024-10-05";
                    setLastShiftLoad(false);
                    setLastShift(last);
                });
    }, [lastShift, lastShiftLoad, shop]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [cookies, setCookies, removeCookie] = useCookies<any[""]>(["id"]);

    return (
        <React.Fragment key="app">
            <CookiesProvider>
                <ConfigProvider theme={customTheme} locale={ruRU}>
                    <ThemeProvider theme={{colors, fonts}}>
                        <ApplicationContext.Provider
                            value={{
                                theme,
                                setTheme: (updateTheme) =>
                                    setTheme(updateTheme),

                                onLogin: (updatedUser: IUser) => {
                                    window.location.href = "/";
                                },
                                onLogout: () => {
                                    removeCookie("id");
                                    window.location.href = "/login";
                                },
                                rangeStart,
                                setRangeStart: (v: string | undefined) =>
                                    setRangeStart(v),
                                rangeEnd,
                                setRangeEnd: (v: string | undefined) =>
                                    setRangeEnd(v),
                                periodDate,
                                setSetPeriodDate: (v: string | undefined) =>
                                    setSetPeriodDate(v),
                                dateFilterMain,
                                setDateFilterMain: (v: string | undefined) =>
                                    setDateFilterMain(v),
                                dateRange,
                                setDateRange: (v: any) => setDateRange(v),
                                inventGroups,
                                setInventGroups: (v: any) => setInventGroups(v),
                                lastShift,
                                setLastShift: (v: any) => setLastShift(v),
                            }}
                        >
                            <SidebarsContext.Provider
                                value={{
                                    isActiveSubmenu,
                                    setActiveSubmenu: (v: boolean) =>
                                        setActiveSubmenu(v),
                                    numActiveSubmenu,
                                    setNumActiveSubmenu: (v: any) =>
                                        setActiveSubmenu(v),
                                }}
                            >
                                <GlobalStyles />

                                <ToastContainer style={{width: "40%"}} />
                                <MainRoutes />
                            </SidebarsContext.Provider>
                        </ApplicationContext.Provider>
                    </ThemeProvider>
                </ConfigProvider>
            </CookiesProvider>
        </React.Fragment>
    );
};

export default App;
