import React, {useState, FC} from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import {Modal} from "antd/lib";
import ContentModalCheck from "./ContentModalCheck";
import {useSelector} from "react-redux";
import {cardSelect} from "../../../../entities/card";
import {clientSelect} from "../../../../entities/client";
import {IClientCardHistory} from "../../../../entities/card/types";
import {formaterSum} from "../../../../utils/functions/formaterSumm";

interface PropsClientModal {
    setOpeningSettingsPopup: (val: boolean) => void;
    loadMore: () => void;
}

const ContentModalBayes: FC<PropsClientModal> = ({
    setOpeningSettingsPopup,
    loadMore,
}) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedCheck, setSelectedCheck] =
        useState<IClientCardHistory | null>(null);
    const {cardTransactions} = useSelector(cardSelect);
    const {selectedClient} = useSelector(clientSelect);

    const handlerCheck = (item: IClientCardHistory) => {
        setModalOpen(true);
        setSelectedCheck(item);
    };

    return (
        <Wrapper>
            <StyledTableWrapper>
                <StyledTableHeader>
                    <HeaderTitle>Карточка</HeaderTitle>
                    <HeaderTitle>Дата</HeaderTitle>
                    <HeaderTitle>Чек</HeaderTitle>
                    <HeaderTitle>
                        Сумма <span>со скидкой</span>
                    </HeaderTitle>
                    <HeaderTitle>Бонусы</HeaderTitle>
                    <HeaderTitle>Баланс</HeaderTitle>
                </StyledTableHeader>
                <StyledTableBody>
                    <Br />
                    {cardTransactions?.map((item, ind) => (
                        <React.Fragment key={`fragment-${ind}`}>
                            <InfoWrap key={`infowrap-${ind}`}>
                                <InfoOrg key={`infocard-${ind}`}>
                                    {item.card_code}
                                </InfoOrg>
                                <InfoDate key={`infodate-${ind}`}>
                                    <span>
                                        {dayjs(item.date).format("DD.MM.YYYY")}
                                    </span>
                                    <span>
                                        {dayjs(item.date).format("HH:mm:ss")}
                                    </span>
                                </InfoDate>
                                <InfoCheck
                                    key={`infocheck-${ind}`}
                                    onClick={() => handlerCheck(item)}
                                >
                                    {item?.checknum ? item.checknum : "-"}
                                </InfoCheck>
                                <InfoSumm key={`infosum-${ind}`}>
                                    {item.discount
                                        ? formaterSum(item.discount)
                                        : "0,00"}
                                </InfoSumm>

                                {item.bonus ? (
                                    item.bonus > 0 ? (
                                        <InfoSummP key={`infobonusp-${ind}`}>
                                            {formaterSum(item.bonus)}
                                        </InfoSummP>
                                    ) : item.bonus < 0 ? (
                                        <InfoSummM key={`infobonusm-${ind}`}>
                                            {formaterSum(item.bonus)}
                                        </InfoSummM>
                                    ) : (
                                        <InfoSumm key={`infobonus-${ind}`}>
                                            {"0,00"}
                                        </InfoSumm>
                                    )
                                ) : (
                                    <InfoSumm key={`infobonuse-${ind}`}>
                                        {"0,00"}
                                    </InfoSumm>
                                )}
                                {item.balance ? (
                                    item.balance > 0 ? (
                                        <InfoSummP key={`infobalancep-${ind}`}>
                                            {formaterSum(item.balance)}
                                        </InfoSummP>
                                    ) : item.balance < 0 ? (
                                        <InfoSummM key={`infobalancesm-${ind}`}>
                                            {formaterSum(item.balance)}
                                        </InfoSummM>
                                    ) : (
                                        <InfoSumm key={`infobalance-${ind}`}>
                                            {"0,00"}
                                        </InfoSumm>
                                    )
                                ) : (
                                    <InfoSumm key={`infobalance-${ind}`}>
                                        {"0,00"}
                                    </InfoSumm>
                                )}
                            </InfoWrap>
                            <Br />
                        </React.Fragment>
                    ))}
                    <StyledButtonMore onClick={() => loadMore()}>
                        <span>Показать еще</span>
                    </StyledButtonMore>
                    <StyledModal
                        centered
                        open={modalOpen}
                        onOk={() => setModalOpen(false)}
                        onCancel={() => setModalOpen(false)}
                        zIndex={3300}
                        width={1200}
                    >
                        <ContentModalCheck
                            selectedCheck={selectedCheck}
                            selectedClient={selectedClient}
                        />
                    </StyledModal>
                </StyledTableBody>
            </StyledTableWrapper>
            <StyledFooter>
                <StyledButtonClose
                    onClick={() => setOpeningSettingsPopup(false)}
                >
                    Закрыть
                </StyledButtonClose>
                <StyledButtonSave
                    onClick={() => setOpeningSettingsPopup(false)}
                >
                    <span>Сохранить</span>
                </StyledButtonSave>
            </StyledFooter>
        </Wrapper>
    );
};

export default ContentModalBayes;

const StyledModal = styled(Modal)`
    z-index: 1500;
`;

const StyledTableWrapper = styled.div`
    height: 460px;
`;

const StyledTableHeader = styled.div`
    display: flex;
    flex-direction: row nowrap;
    width: 100%;
    height: 56px;
    gap: 4px;
    padding: 10px;
`;

const HeaderTitle = styled.div`
    width: 105px;
    height: 23px;
    text-align: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 23px;
    display: flex;
    align-items: center;
    color: #333333;

    & span {
        position: absolute;
        top: 28px;
        font-weight: 400;
        font-size: 14px;
        line-height: 15px;
        color: #8f8f8f;
    }
`;

const StyledTableBody = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 10px;
    height: 400px;
    overflow-y: auto;
    overflow-x: hidden;

    ::-webkit-scrollbar {
        width: 6px;
    }

    /* Стилизация ползунка скроллбара */
    ::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        border-radius: 50%;
    }

    /* Стилизация ползунка при наведении */
    ::-webkit-scrollbar-thumb:hover {
        background: #b30000;
    }

    /* Стили для Firefox */
    scrollbar-color: darkgrey #ffffff;
    scrollbar-width: thin;
`;

const TextStyles = styled.div`
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
`;

const InfoOrg = styled(TextStyles)`
    width: 105px;
    height: 30px;
    left: 13.25px;
    top: 7px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #8f8f8f;
`;

const InfoCheck = styled(TextStyles)`
    width: 105px;
    height: 45px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #a7388e;
    cursor: pointer;
`;

const InfoSumm = styled(TextStyles)`
    width: 105px;
    height: 15px;
    left: 238.25px;
    top: 14px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #8f8f8f;
`;

const InfoSummP = styled(TextStyles)`
    width: 105px;
    height: 15px;
    left: 238.25px;
    top: 14px;
    line-height: 15px;
    display: flex;
    align-items: start;
    color: #3bca1e;
`;

const InfoSummM = styled(TextStyles)`
    width: 105px;
    height: 15px;
    left: 238.25px;
    top: 14px;
    line-height: 15px;
    display: flex;
    align-items: start;
    color: #ca281e;
`;

const InfoDate = styled(TextStyles)`
    display: flex;
    flex-direction: column;
    width: 105px;
    height: 30px;
    left: 332.25px;
    top: 6px;
    line-height: 15px;
    display: flex;
    align-items: start;
    color: #333333;
`;

const InfoWrap = styled.div`
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
`;

const StyledButtonClose = styled(TextStyles)`
    width: 59px;
    height: 28px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: #8f8f8f;
    cursor: pointer;
`;

const StyledButtonSave = styled(TextStyles)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 58px;
    gap: 10px;
    cursor: pointer;
    width: 195px;
    height: 40px;
    background: #a7388e;
    border-radius: 5px;
    flex: none;
    order: 1;
    flex-grow: 0;

    & span {
        line-height: 28px;
        text-align: center;
        color: #ffffff;
    }
`;
const StyledButtonMore = styled(TextStyles)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
    align-items: center;
    cursor: pointer;
    width: 150px;
    height: 40px;
    background: #f7f7f7;
    border-radius: 5px;
    & span {
        line-height: 28px;
        color: #a7388e;
    }
`;

const StyledFooter = styled.div`
    display: flex;
    gap: 34px;
    flex-direction: row;
    justify-content: flex-end;
    height: 40px;
    margin-top: 16px;
    align-items: center;
    position: absolute;
    bottom: 10px;
    right: 10px;
`;

const Br = styled.hr`
    margin: 5px 0px;
    width: 650px;
    height: 0px;
    border: 1px solid rgba(143, 143, 143, 0.3);
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 680px;
    height: 520px;
    z-index: 1200;
    flex: none;
    flex-grow: 0;
`;
