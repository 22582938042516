import axios, {AxiosResponse} from "axios";
import {api, API_URL} from "../../config";
import {ILoginRequest, IRespLogin} from "../../../entities/auth";

const BASE_PATH = "auth";

export const loginRequest = (
    data: ILoginRequest
): Promise<AxiosResponse<IRespLogin>> => {
    return axios.post<IRespLogin>(`${API_URL}/${BASE_PATH}/login`, data);
};

export const getMeRequest = (): Promise<AxiosResponse<IRespLogin>> => {
    return api.get<IRespLogin>(`${API_URL}/${BASE_PATH}/info`);
};
