import { stat } from 'fs/promises'
import _ from 'lodash'

const excludeSumForClean = 'Платежные карты'

export const cleanTest = (data) => _.filter(data, (o) => o.SHIFTDATE !== '2024-06-20')

export const calcViruchka = (viruchkaData) => {
    
  return _(viruchkaData)
      // .remove(i => i.SHIFTDATE === '2024-06-20')
      .groupBy('CLOSESTATION')
      .map((objs: any, key: any) => ({
        key: key,
        'closeStation': key,
        'totalAmount': _.sumBy(objs, 'ORIGINALSUM'),
        'nested': _(objs).filter((o) => o.CLOSESTATION === key)
          .groupBy('SHIFTDATE')
          .map((item, ind) => ({
              key: `${key}-${ind}`,
              shiftDate: ind,
              shiftDateAmount: _.sumBy(item, 'ORIGINALSUM'),
              subNested: _(item).filter(k => k.SHIFTDATE === ind)
                .groupBy('CURRENCYTYPE')
                .map((subitem, kInd) => ({
                  key: `${key}-${ind}-${kInd}`,
                  currencyType: kInd,
                  currencyTypeAmount: _.sumBy(subitem, 'ORIGINALSUM'),
                  details: _(subitem).filter(r => r.CURRENCYTYPE === kInd)
                    .map((detailItem, detailInd) => ({
                      key: `${key}-${ind}-${kInd}-${detailInd}`,
                      currency: detailItem.CURRENCY,
                      originalSum: detailItem.ORIGINALSUM,
                    }))
                    .value()
                  
                }))
                  .value()
          })
        )
        .value()
      }))
      .value()
}

export const calcSortedDataChart = (viruchkaData) => {
  const sortedDataChart = _.orderBy(viruchkaData, 'SHIFTDATE', 'asc')
  const groupedDataChartDate = _(sortedDataChart)
    .groupBy('SHIFTDATE')
    .map((o, k) => k)
    .value()
  const groupedDataChart = _(sortedDataChart)
    .groupBy('CLOSESTATION')
    .map((objs, key) => ({
      key: key,
      range: _.chain(objs)
        .groupBy('SHIFTDATE')
        .map((o, k) => k)
        .value(),
      values: _.chain(objs)
        .groupBy('SHIFTDATE')
        .map((o, k) => _.sumBy(o, 'ORIGINALSUM'))
        .value()
      }))
  .value()
  let newGroupedDataChart = groupedDataChart.map(station => {
    const reference = groupedDataChartDate
    let result = new Array(reference.length).fill(0);
    let keys = station.range
    let values = station.values
    for (let i = 0; i < keys.length; i++) {
      let index = reference.indexOf(keys[i]);
      if (index !== -1) {
        result[index] = values[i];
      }

    }
    return { ...station, values: result, range: reference }
  })
  return {
    range: groupedDataChartDate,
    values: newGroupedDataChart
  }
}

export const calcViruchkaPivot = (viruchkaData) => {
  let resultedData: any = []
  const groupedByCloseStation = _(viruchkaData)
    .groupBy('CLOSESTATION')
    .map((stationItem, key) => {

      const amountArray1 = _(stationItem)
        .filter((o) => o.CLOSESTATION === key && o.CURRENCYTYPE === 'Кредитные карты')
        .groupBy('CURRENCYTYPE')
        .map(k => _.sumBy(k, 'ORIGINALSUM'))
        .value()
      const amount1 = (amountArray1.length > 0) ? amountArray1[0] : 0
      
      const amountArray2 = _(stationItem)
        .filter((o) => o.CLOSESTATION === key && o.CURRENCYTYPE === 'Наличность')
        .groupBy('CURRENCYTYPE')
        .map(k => _.sumBy(k, 'ORIGINALSUM'))
        .value()
      const amount2 = (amountArray2.length > 0) ? amountArray2[0] : 0;

      const amountArray3 = _(stationItem)
        .filter((o) => o.CLOSESTATION === key && o.CURRENCYTYPE === 'Платежные карты')
        .groupBy('CURRENCYTYPE')
        .map(k => _.sumBy(k, 'ORIGINALSUM'))
        .value()
      const amount3 = (amountArray3.length > 0)? amountArray3[0]: 0

      return {
        key: key,
        station: key,
        amount: _.sumBy(stationItem, 'ORIGINALSUM'),
        amount1: amount1,
        amount2: amount2,
        amount3: amount3,
        amount5: amount1 + amount2,
        nested: _(stationItem).filter((o) => o.CLOSESTATION === key)
          .groupBy('CURRENCYTYPE')
          .map((currencyTypeItem, keyCurrencyType) => {
            return {
              currencyType: keyCurrencyType,
              amountCurrencyType: _.sumBy(currencyTypeItem, 'ORIGINALSUM'),
            }
          })
        
          .value()
      }})
  
    .value();
  const groupedCurrencyType = Object.values(_(viruchkaData)
    .groupBy('CURRENCYTYPE')
    .map((i, g) => g)
    .mapValues((o, k) => o)
    .value()
  )
   
  groupedByCloseStation.map((item, ind) => {
    let tempResult = {}
    const nestedData = _(item.nested).map((i, k) => {
      return i.amountCurrencyType
    })
      .value()
    
    resultedData.push({...item, ...nestedData, tempResult})
  })
  return { resultedData, groupedCurrencyType }
}

export const calcViruchkaByWaiters = (waitersData) => {
  let resultedData: any = []
  const groupedByWaiter = _(waitersData)
    .groupBy('WAITER')
    .map((waiterItem, key) => {

      const amountArray1 = _(waiterItem)
        .filter((o) => o.WAITER === key && o.CURRENCYTYPE === 'Кредитные карты')
        .groupBy('CURRENCYTYPE')
        .map(k => _.sumBy(k, 'ORIGINALSUM'))
        .value()
      const amount1 = (amountArray1.length > 0) ? amountArray1[0] : 0
      
      const amountArray2 = _(waiterItem)
        .filter((o) => o.WAITER === key && o.CURRENCYTYPE === 'Наличность')
        .groupBy('CURRENCYTYPE')
        .map(k => _.sumBy(k, 'ORIGINALSUM'))
        .value()
      const amount2 = (amountArray2.length > 0) ? amountArray2[0] : 0;

      const amountArray3 = _(waiterItem)
        .filter((o) => o.WAITER === key && o.CURRENCYTYPE === 'Платежные карты')
        .groupBy('CURRENCYTYPE')
        .map(k => _.sumBy(k, 'ORIGINALSUM'))
        .value()
      const amount3 = (amountArray3.length > 0)? amountArray3[0]: 0

      return {
        waiter: key,
        amount: _.sumBy(waiterItem, 'ORIGINALSUM'),
        amount1: amount1,
        amount2: amount2,
        amount3: amount3,
        amount5: amount1 + amount2,
        nested: _(waiterItem).filter((o) => o.WAITER === key)
          .groupBy('CURRENCYTYPE')
          .map((currencyTypeItem, keyCurrencyType) => {
            return {
              currencyType: keyCurrencyType,
              amountCurrencyType: _.sumBy(currencyTypeItem, 'ORIGINALSUM'),
            }
          })
        
          .value()
      }})
  
    .value();
  const groupedCurrencyType = Object.values(_(waitersData)
    .groupBy('CURRENCYTYPE')
    .map((i, g) => g)
    .mapValues((o, k) => o)
    .value()
  )
   
  groupedByWaiter.map((item, ind) => {
    let tempResult = {}
    const nestedData = _(item.nested).map((i, k) => {
      return i.amountCurrencyType
    })
      .value()
    
    resultedData.push({...item, ...nestedData, tempResult})
  })
  
  return { resultedData, groupedCurrencyType }
}

