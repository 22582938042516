import {FC} from "react";
import styled from "styled-components";
import {Icons} from "../../../../assets";

interface IDiffInfo {
    diff: number;
    total: number;
}

const DiffInfo: FC<IDiffInfo> = ({diff, total}) => {
    return (
        <Container>
            <Header>Количество за период</Header>
            <Wrapper>
                <AmountBox>{total}</AmountBox>
                <DiffBox $isdiff={diff > 0}>
                    <TextWrap>
                        {diff > 0 ? (
                            <Icons.ArrowUpWhite />
                        ) : (
                            <Icons.ArrowDownWhite />
                        )}
                        {`${diff} %`}
                    </TextWrap>
                </DiffBox>
            </Wrapper>
        </Container>
    );
};
export default DiffInfo;

const Container = styled.div`
    flex-direction: column;
`;

const Wrapper = styled.div`
    gap: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;
const Header = styled.div`
    font-weight: 700;
    font-size: 14px;
    line-height: 28px;
    color: #8f8f8f;
`;
const AmountBox = styled.div`
    font-weight: 600;
    font-size: 25px;
    line-height: 28px;
`;

const DiffBox = styled.div<{
    $isdiff: boolean;
}>`
    padding: 3px 7px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 700;
    color: #ffffff;
    background-color: ${({$isdiff}) => ($isdiff ? "#3BCA1E" : "#FF3B30")};
`;
const TextWrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
`;
