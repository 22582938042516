import {FC} from "react";
import styled from "styled-components";
import moment from "moment";
import {useSelector} from "react-redux";
import {cardSelect} from "../../../../entities/card";
import {clientSelect} from "../../../../entities/client";

moment().format();

const ContentModalMain: FC = () => {
    const {cardSpendStats} = useSelector(cardSelect);
    const {selectedClient} = useSelector(clientSelect);

    return (
        <Wrapper>
            <NavbarTitleID>#{selectedClient?.id}</NavbarTitleID>
            <ClientNameInfo>{selectedClient?.fio}</ClientNameInfo>
            <StatisticWrap>
                <StyledTitle>СТАТИСТИКА:</StyledTitle>
                <StyledStatistic>
                    <StyledStatisticItem>
                        <StyledStatisticItemKey>
                            посещений
                        </StyledStatisticItemKey>
                        <StyledStatisticItemValue>
                            {cardSpendStats?.visits}
                        </StyledStatisticItemValue>
                    </StyledStatisticItem>
                    <StyledStatisticItem>
                        <StyledStatisticItemKey>
                            потраченные депозиты
                        </StyledStatisticItemKey>
                        <StyledStatisticItemValue>
                            {`${
                                cardSpendStats?.balance !== 0
                                    ? cardSpendStats?.balance
                                    : 0
                            }`}
                        </StyledStatisticItemValue>
                    </StyledStatisticItem>
                    <StyledStatisticItem>
                        <StyledStatisticItemKey>
                            потраченные бонусы
                        </StyledStatisticItemKey>
                        <StyledStatisticItemValue>
                            {`${
                                cardSpendStats?.bonus !== 0
                                    ? cardSpendStats?.bonus
                                    : 0
                            }`}
                        </StyledStatisticItemValue>
                    </StyledStatisticItem>
                    <StyledStatisticItem>
                        <StyledStatisticItemKey>
                            последняя покупка
                        </StyledStatisticItemKey>
                        <StyledStatisticItemValue>
                            {cardSpendStats?.visitLast
                                ? moment
                                      .utc(cardSpendStats.visitLast)
                                      .format("DD.MM.YYYY")
                                : "-"}
                        </StyledStatisticItemValue>
                    </StyledStatisticItem>
                </StyledStatistic>
            </StatisticWrap>

            <ClientInfoWrap>
                <StyledTitle>ОСНОВНОЕ</StyledTitle>
                <ClientInfoItem>
                    <ClientInfoValue>
                        {selectedClient?.sex === 1 ? "Мужской" : "Женский"}
                    </ClientInfoValue>
                </ClientInfoItem>
                <ClientInfoItem>
                    <ClientInfoValue>
                        {selectedClient?.birthdate}
                    </ClientInfoValue>
                </ClientInfoItem>
                <ClientInfoItem>
                    <ClientInfoValue>{selectedClient?.email}</ClientInfoValue>
                </ClientInfoItem>
                <ClientInfoItem>
                    <ClientInfoValue>{selectedClient?.phone}</ClientInfoValue>
                </ClientInfoItem>
            </ClientInfoWrap>
        </Wrapper>
    );
};

export default ContentModalMain;

const NavbarTitleID = styled.div`
    margin-left: 18px;
    margin-bottom: 18px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    display: flex;
    align-items: center;

    color: #f7f7f7;
`;

const ClientNameInfo = styled.div`
    margin-left: 18px;
    width: 124px;
    height: 56px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 28px;
    display: flex;
    align-items: center;

    color: #f7f7f7;
`;
const ClientInfoValue = styled.div`
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: #f7f7f7;
`;

const ClientInfoItem = styled.div`
    display: flex;
    flex-direction: row;
`;

const ClientInfoWrap = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 14px;
    gap: 12px;
    overflow: hidden;
`;

const StyledStatistic = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    gap: 16px;
    padding-left: 10px;
`;

const StyledStatisticItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 0px;
`;

const StyledStatisticItemValue = styled.div`
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 22px;
    display: flex;
    align-items: center;
    margin-top: 5px;
    color: #f7f7f7;
`;

const StyledStatisticItemKey = styled.div`
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: rgba(255, 255, 255, 0.5);
`;

const StatisticWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-top: 24px;
    margin-bottom: 34px;
    padding: 10px 10px 18px 18px;
    background-color: #3d277e;
    width: 204px;
    gap: 16px;
    border-radius: 0px 5px 5px 0px;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 216px;
    padding: 12px 12px 0px 0px;
    margin-top: 10px;
    height: 595px;
    z-index: 1205;
    background-color: #2e1c65;
    border-radius: 0px 10px 0px 0px;
    overflow-y: auto;
`;

const StyledTitle = styled.div`
    width: 116px;
    height: 19px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: -0.01em;
    color: #f7f7f7;
`;
