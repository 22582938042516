import {AxiosResponse} from "axios";
import {
    ICard,
    ICardSpendStats,
    IClientCardHistory,
} from "../../../entities/card/types";
import {api, API_URL} from "../../config";
import {IBaseQuery, ICartTrnsaction, PaginatedType} from "../../../types";

const BASE_PATH = "shops";

export const getClientCardTransactReq = (
    id: number,
    shop_id: number,
    params: IBaseQuery
): Promise<AxiosResponse<PaginatedType<IClientCardHistory>>> => {
    return api.get<PaginatedType<IClientCardHistory>>(
        `${API_URL}/${BASE_PATH}/${shop_id}/loyalty/clients/${id}/cards/history/transactions`,
        {
            params: {
                ...params,
            },
        }
    );
};

export const getClientCardChangesReq = (
    id: number,
    shop_id: number,
    params: IBaseQuery
): Promise<AxiosResponse<PaginatedType<IClientCardHistory>>> => {
    return api.get<PaginatedType<IClientCardHistory>>(
        `${API_URL}/${BASE_PATH}/${shop_id}/loyalty/clients/${id}/cards/history/changes`,
        {
            params: {
                ...params,
            },
        }
    );
};

export const getClientCardReq = (
    id: number,
    shop_id: number
): Promise<AxiosResponse<ICard[]>> => {
    return api.get<ICard[]>(
        `${API_URL}/${BASE_PATH}/${shop_id}/loyalty/clients/${id}/cards`
    );
};

export const depositInRequest = (
    id: number,
    shop_id: number,
    amount: number
): Promise<AxiosResponse<ICartTrnsaction>> => {
    return api.post<ICartTrnsaction>(
        `${API_URL}/${BASE_PATH}/${shop_id}/loyalty/cards/${id}/deposit-in`,
        {amount}
    );
};
export const depositOutRequest = (
    id: number,
    shop_id: number,
    amount: number
): Promise<AxiosResponse<ICartTrnsaction>> => {
    return api.post<ICartTrnsaction>(
        `${API_URL}/${BASE_PATH}/${shop_id}/loyalty/cards/${id}/deposit-out`,
        {amount}
    );
};

export const bonusInRequest = (
    id: number,
    shop_id: number,
    amount: number
): Promise<AxiosResponse<ICartTrnsaction>> => {
    return api.post<ICartTrnsaction>(
        `${API_URL}/${BASE_PATH}/${shop_id}/loyalty/cards/${id}/bonus-in`,
        {amount}
    );
};

export const bonusOutRequest = (
    id: number,
    shop_id: number,
    amount: number
): Promise<AxiosResponse<ICartTrnsaction>> => {
    return api.post<ICartTrnsaction>(
        `${API_URL}/${BASE_PATH}/${shop_id}/loyalty/cards/${id}/bonus-out`,
        {amount}
    );
};
export const getCardSpendStatsRequest = (
    card_id: number,
    shop_id: number,
    client_id: number
): Promise<AxiosResponse<ICardSpendStats>> => {
    return api.get<ICardSpendStats>(
        `${API_URL}/${BASE_PATH}/${shop_id}/loyalty/clients/${client_id}/cards/${card_id}/spendStats`
    );
};

export const getAllCardsSpendStatsRequest = (
    shop_id: number,
    client_id: number
): Promise<AxiosResponse<ICardSpendStats>> => {
    return api.get<ICardSpendStats>(
        `${API_URL}/${BASE_PATH}/${shop_id}/loyalty/clients/${client_id}/cards/spendStats`
    );
};
