import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {AuthProvider} from "./context/AuthProvider";
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import {store} from "./config/store";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    <React.Fragment key={"main"}>
        <React.StrictMode>
            <Provider store={store}>
                <BrowserRouter>
                    <AuthProvider>
                        <App
                            key={"means"}
                            // context={}
                        />
                    </AuthProvider>
                </BrowserRouter>
            </Provider>
        </React.StrictMode>
    </React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
