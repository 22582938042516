import React, { useRef, useState, useEffect, FC } from 'react';
import styled from 'styled-components';
import { Icons } from '../../../assets';
import { ISelector } from '../../../types';

interface IProps {
	options: ISelector[];
	setSelectValue: (value: ISelector) => void;
	value: ISelector;
}

const PeriodSelector: FC<IProps> = ({ options, setSelectValue, value }) => {
	const [switchIsOpen, setSwitchIsOpen] = useState(false);

	const dropdownRef = useRef<HTMLDivElement>(null);

	const swithHandler = (key: ISelector) => {
		if (key.value !== value.value) {
			setSelectValue(key);
		}
		setSwitchIsOpen(false);
	};

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
				setSwitchIsOpen(false);
			}
		};
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [dropdownRef]);

	return (
		<SelectorWrap $isOpen={switchIsOpen}>
			<Title>{value.label}</Title>
			<NoiseSelectorBtn
				onClick={() => {
					setSwitchIsOpen(!switchIsOpen);
				}}
			>
				{switchIsOpen ? <Icons.IconArrowUp /> : <Icons.IconArrowDown />}
			</NoiseSelectorBtn>
			{switchIsOpen && (
				<SelectorDropdown $isOpen={switchIsOpen} ref={dropdownRef}>
					{options?.map((item) => {
						if (item.value === value.value) return null;

						return (
							<React.Fragment key={item.key}>
								<TitleLabel onClick={() => swithHandler(item)}>
									<span>{item.label}</span>
								</TitleLabel>
							</React.Fragment>
						);
					})}
				</SelectorDropdown>
			)}
		</SelectorWrap>
	);
};

export default PeriodSelector;

const SelectorWrap = styled.div<{
	$isOpen: boolean;
}>`
	width: 100%;
	justify-content: flex-start;
	background: #ffffff;
	border-radius: 8px 8px 0px 0px;
	position: relative;
	display: flex;
	font-weight: 600;
	font-size: 13px;
	line-height: 26px;
	color: #1f1e24;
	cursor: pointer;
`;
const Title = styled.div`
	width: 100%;
	padding: 6px 0px 0px 26px;
	font-style: normal;
	font-weight: 600;
	height: 100%;
	font-size: 30px;
	line-height: 34px;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	text-align: center;
	color: #333333;
	@media (max-width: 1500px) {
		font-size: 24px;
		padding: 6px 0px 0px 16px;
	}
`;

const TitleLabel = styled.div`
	font-style: normal;
	font-weight: 600;
	font-size: 30px;
	line-height: 34px;
	display: flex;
	align-items: center;
	color: #333333;
	width: 100%;
	height: 38px;
	justify-content: flex-start;
	text-align: center;
	color: #333333;

	& span {
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 28px;
		color: #333333;
	}
`;

const SelectorDropdown = styled.div<{ $isOpen: boolean }>`
	width: 100%;
	position: absolute;
	justify-content: center;
	z-index: 8;
	padding: 0px 26px 6px 26px;
	align-items: center;
	top: 80px;
	display: flex;
	flex-direction: column;
	background-color: #fff;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
	border-top-left-radius: ${({ $isOpen }) => ($isOpen ? '0px' : '8px')};
	border-top-right-radius: ${({ $isOpen }) => ($isOpen ? '0px' : '8px')};
	box-shadow: ${({ $isOpen }) =>
		$isOpen ? '0px 8px 8px 0px  rgb(158 158 158 / 40%)' : '0px 0px 8px 0px rgb(158 158 158 / 40%)'};
	::-webkit-scrollbar {
		width: 14px;
		background-color: inherit;
	}
	::-webkit-scrollbar-thumb {
		height: 140px;
		background-color: #e7e7e7;
	}
`;

const NoiseSelectorBtn = styled.div`
	position: absolute;
	right: 6px;
	width: 30px;
	padding-top: 16px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	& svg path {
		fill: #a7a7a7;
	}
`;
