import React, {useState} from "react";
import styled from "styled-components";
import moment from "moment";
import {Input} from "antd";
import {useSelector} from "react-redux";
import {authInfoSelect} from "../../../../entities/auth";
import {ICard} from "../../../../entities/card/types";
import {
    bonusInRequest,
    bonusOutRequest,
} from "../../../../api/services/cardApi";

interface PropsClientModal {
    card: ICard;
    setShowFormBonus: (val: boolean) => void;
}

moment().format();

const ChangeBonusForm: React.FC<PropsClientModal> = ({
    card,
    setShowFormBonus,
}) => {
    const [valueAdd, setValueAdd] = useState<number>(0);
    const [valueRemove, setValueRemove] = useState<number>(0);
    const {shop} = useSelector(authInfoSelect);

    const handleFormSubmitBonus = async () => {
        if (valueAdd > 0 && shop) {
            await bonusInRequest(card.id, shop.id, Number(valueAdd));
        }
        if (valueRemove > 0 && shop) {
            await bonusOutRequest(card.id, shop.id, Number(valueAdd));
        }
        setShowFormBonus(false);
        setValueAdd(0);
        setValueRemove(0);
    };

    const handlerAdd = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const {value: inputValue} = e.target;
        const reg = /^-?\d*(\.\d*)?$/;
        if (reg.test(inputValue) || inputValue === "" || inputValue === "-") {
            setValueAdd(Number(inputValue));
        }
    };
    const handlerRemove = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const {value: inputValue} = e.target;
        const reg = /^-?\d*(\.\d*)?$/;
        if (reg.test(inputValue) || inputValue === "" || inputValue === "-") {
            setValueRemove(Number(inputValue));
        }
    };
    return (
        <FormWrap>
            <HeaderTitle>Изменить бонусный баланс</HeaderTitle>
            <Br />
            <Title>{`Бонусный баланс карты ${
                card.bonus
                    ? new Intl.NumberFormat("ru-RU", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                      }).format(Number(card.bonus))
                    : "0,00"
            } вы можете списать или начислить бонусы`}</Title>
            <CountWrap>
                <AddInput
                    onChange={handlerAdd}
                    value={valueAdd}
                    placeholder="Начислить бонусы"
                    onKeyDown={(e) =>
                        e.key === "Enter" ? handleFormSubmitBonus() : null
                    }
                />
                <AddInput
                    onChange={handlerRemove}
                    value={valueRemove}
                    placeholder="Списать бонусы"
                    onKeyDown={(e) =>
                        e.key === "Enter" ? handleFormSubmitBonus() : null
                    }
                />
            </CountWrap>

            <Description placeholder="Комментарии к действию" />
            <ButtonWrap>
                <CloseButton onClick={() => setShowFormBonus(false)}>
                    Закрыть
                </CloseButton>
                <SaveButton onClick={handleFormSubmitBonus}>
                    Сохранить
                </SaveButton>
            </ButtonWrap>
        </FormWrap>
    );
};

export default ChangeBonusForm;

const HeaderTitle = styled.div`
    width: 187px;
    height: 40px;
    left: 26px;
    top: 29px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #333333;
`;
const Br = styled.div`
    width: 298px;
    height: 0px;
    margin: 20px 0px 23px;
    border: 1px solid #eeefef;
`;
const Title = styled.div`
    width: 299px;
    height: 30px;
    left: 26px;
    top: 112px;

    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #333333;
`;
const CountWrap = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 18px;
`;
const AddInput = styled(Input)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    width: 148px;
    height: 35px;
    background: #eeefef;
    border-radius: 5px;
    flex: none;
    order: 0;
    flex-grow: 0;
`;
const Description = styled(Input)`
    margin-top: 13px;
    width: 298px;
    height: 92px;
    background: #eeefef;
    border-radius: 5px;
`;
const ButtonWrap = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 18px;
`;
const CloseButton = styled.div`
    width: 59px;
    height: 28px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #8f8f8f;
    cursor: pointer;
`;
const SaveButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: none;
    width: 190px;
    height: 40px;
    cursor: pointer;
    background: #a7388e;
    border-radius: 5px;
    color: #ffffff;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
`;
const FormWrap = styled.div`
    overflow: hidden;
    display: flex;
    position: fixed;
    flex-direction: column;
    justify-content: flex-start;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1105;
    background-color: #fff;
    padding: 29px 26px 22px;
    border-radius: 5px;
    width: 350px;
    height: 380px;
    background: #ffffff;
    border-radius: 5px;
`;
