import React, {useState, useEffect, useRef} from "react";
import {Link} from "react-router-dom";
import styled, {css} from "styled-components";
import {Container} from "../../styles/GlobalStyles";
import {navigationItems} from "../../config";
import {Logos, Icons} from "../../assets";
import DropdownMenu from "./DropdownMenu";
import Sidebar from "./Sidebar";
import {useSelector} from "react-redux";
import {authInfoSelect} from "../../entities/auth";

const Navbar = (sidemenu) => {
    const {pathname} = window.location;
    const {user} = useSelector(authInfoSelect);
    const [button, setButton] = useState(true);
    const [activeMenuItemPath, setActiveMenuItemPath] = useState(pathname);
    const [isActiveSubmenu, setActiveSubmenu] = useState(true);
    const [numActiveSubmenu, setNumActiveSubmenu] = useState(0);
    const [heigthActiveItem, setHeightActiveItem] = useState<number>(230);
    const activeItemRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setActiveMenuItemPath(pathname);
    }, [pathname]);

    const showButton = () => {
        if (window.innerWidth <= 1000) {
            setButton(false);
        } else {
            setButton(true);
        }
    };

    useEffect(() => {
        showButton();
    }, []);
    window.addEventListener("resize", showButton);

    //  const navigation = useNavigate();

    // const logout = () => {
    // 	localStorage.removeItem('user');
    // 	localStorage.removeItem('accessToken');
    // 	localStorage.removeItem('refreshToken');
    // 	localStorage.removeItem('shop');
    // 	navigation('/login');
    // };

    const navbarHendler = (path) => {
        const {text} = path;
        const item: any = navigationItems.navbar.find((i) => i.text === text);
        setHeightActiveItem(item.height);
        setActiveSubmenu(true);
        setNumActiveSubmenu(navigationItems[text]);
    };

    return (
        <>
            <Nav>
                {isActiveSubmenu && !!numActiveSubmenu && (
                    <SubmenuBackground
                        $hover={isActiveSubmenu}
                        height={heigthActiveItem}
                        // onMouseOut={() => setActiveSubmenu(false)}
                        // onMouseLeave={()=>setActiveSubmenu(false)}
                    />
                )}
                <NavbarContainer>
                    {isActiveSubmenu && !!numActiveSubmenu && (
                        <Submenu
                            $hover={isActiveSubmenu}
                            onMouseLeave={() => setActiveSubmenu(false)}
                            height={heigthActiveItem}
                        >
                            <Sidebar
                                onSetActiveSubmenu={setActiveSubmenu}
                                sidemenu={numActiveSubmenu}
                                height={heigthActiveItem}
                            />
                        </Submenu>
                    )}
                    <NavHeaderLineWrap>
                        <NavLogo to="/">
                            <Logos.LogoWhite />
                            {/* <SVGLogo /> */}
                        </NavLogo>
                        <AccountInfoWrap>
                            <StyledInfo>
                                <img src={Icons.Wallet} alt="" />
                                {`Баланс ${user?.state} ₽`}
                            </StyledInfo>
                            {/* <StyledInfo>
                  <img src={Icons.AccountIcon} alt="" />
                  mixafterparty.com
                </StyledInfo> */}
                            <DropdownMenu />
                            <StyledInfo>{`${user?.firstname} ${user?.lastname}`}</StyledInfo>
                            {/* <StyledInfo>
                  <img src={Icons.MenuDotFill} alt="" />
                </StyledInfo> */}
                        </AccountInfoWrap>
                    </NavHeaderLineWrap>
                    <Menu>
                        {navigationItems?.navbar?.map((item: any) =>
                            item.isAllow ? (
                                <MenuLink
                                    onMouseEnter={() => navbarHendler(item)}
                                    to={item.to}
                                    key={`link-${item.text}`}
                                >
                                    <StyledButton
                                        ref={
                                            activeMenuItemPath.includes(item.to)
                                                ? activeItemRef
                                                : null
                                        }
                                        $activeitem={
                                            activeMenuItemPath.includes(item.to)
                                                ? "true"
                                                : ""
                                        }
                                        onClick={() =>
                                            setActiveMenuItemPath(item.to)
                                        }
                                        key={item.text}
                                        $pathname={pathname}
                                    >
                                        {item.icon}
                                        <span>{item.name}</span>
                                    </StyledButton>
                                </MenuLink>
                            ) : null
                        )}
                        {/* <div>
                  <StyledButton
                    $activeitem={''}
                    key="exit"
                    $pathname="/login"
                  >
                    <MenuLink onClick={logout} to="/login">Выход</MenuLink>
                  </StyledButton>

              </div> */}
                    </Menu>
                </NavbarContainer>
            </Nav>
        </>
    );
};

export default Navbar;

// Стилизованный компонент для всплывающего подменю
const Nav = styled.nav`
    font-size: 18px;
    position: sticky;
    top: 0;
    z-index: 999;
    height: 110px;
    background-color: #2e1c65;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const NavbarContainer = styled(Container)`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    /* align-items: center; */
    max-width: 80%;
    padding-top: 20px;
    height: 110px;

    ${Container};
`;

const NavHeaderLineWrap = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
`;

const AccountInfoWrap = styled.div`
    display: flex;
    gap: 10px;
    color: #fff;
`;

const StyledInfo = styled.div`
    display: flex;
    align-items: start;
    align-items: center;
    font-family: "Inter" sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    color: #ffffff;
    //margin-right: 45px;
    & img {
        margin-right: 10px;
        font-size: 30px;
        fill: #ffffff80;
    }
`;

const NavLogo = styled(Link)`
    color: #fff;
    cursor: pointer;
    height: 28px;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 2.5rem;
    font-weight: 800;
    transition: all 0.5s ease;

    & svg path {
        height: 40px;
    }
`;

const Menu = styled.ul`
    display: flex;
    height: 38px;
    align-items: center;
    justify-content: "flex-start";
    text-align: center;
`;

const MenuLink = styled(Link)`
    text-decoration: none;
    font-weight: bold;
    font-size: 2rem;
    vertical-align: middle;
    align-items: center;
    margin-right: 45px;
    height: 28px;
    padding-bottom: 10px;
    transition: all 0.2s ease;

    &:hover {
        color: #cd6ee5;
        /* transform: traslateY(-3rem); */
        /* & svg path {
      fill: #CD6EE5;
      
    } */
    }
    &:active {
        transform: traslateY(3rem);
        color: #e38b06;
        /* & svg path {
      fill: #CD6EE5;
      
    } */
    }

    & span {
        margin-left: 8px;
    }
`;

const StyledButton = styled.div<{
    $activeitem: string | boolean;
    $pathname: string;
}>`
    display: flex;
    flex-direction: row;
    font-weight: 600;
    line-height: 28px;
    font-size: 14px;
    color: #fff;
    /* display: block; */
    height: 28px;
    transition: all 0.2s ease;
    background-color: #2e1c65;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-sizing: border-box;

    & span {
        /* display: inline-block; */
        width: ${({$pathname}) =>
            $pathname.includes("admin") ? "100%" : "90%"};
        word-wrap: break-word;
        text-align: center;
    }

    & svg path {
        height: 22px;
        width: 22px;
        fill: #ffffff80;
    }

    &:active {
        color: #e38b06;
        & svg path {
            fill: #cd6ee5;
        }
    }

    &:hover {
        color: #cd6ee5;
        border-bottom: 5px solid #cd6ee5;
        /* transform: traslateY(-3rem); */
        & svg path {
            fill: #cd6ee5;
        }
    }

    ${({$activeitem}) => {
        return (
            $activeitem &&
            css`
                color: #cd6ee5;
                & svg path {
                    fill: #cd6ee5;
                }
            `
        );
    }}
`;

const Submenu = styled.div<{
    $hover: boolean | string;
    height?: number;
}>`
    display: ${({$hover}) => ($hover ? "block" : "none")};
    position: absolute;
    max-width: 1366px;
    height: ${({height}) => (height ? `${height}px` : "100px")};
    top: 101%;
    background: #2e1c65;
    ${MenuLink}:hover & {
        display: block;
    }
`;

const SubmenuBackground = styled.div<{
    $hover: boolean;
    height?: number;
}>`
    display: ${({$hover}) => ($hover ? "block" : "none")};
    position: absolute;
    width: 100%;
    height: ${({height}) => (height ? `${height}px` : "100px")};
    top: 101%;
    background: #2e1c65;

    ${MenuLink}:hover & {
        display: block;
    }
`;
