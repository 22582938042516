import {useEffect, useState, useMemo} from "react";
import {Line} from "react-chartjs-2";
import {Chart, Filler} from "chart.js";
// import ChartDataLabels from 'chartjs-plugin-datalabels';
import styled from "styled-components";
import annotationPlugin from "chartjs-plugin-annotation";
import BookingSelector from "./components/BookingSelector";
import {useApplication} from "../../../context/ApplicationContext";
import {IBookingReportResponse} from "../../../types";
import DiffInfo from "./components/DiffInfo";
import {transformBookingData} from "../../../utils/functions/transformBookingData";
import {useSelector} from "react-redux";
import {authInfoSelect} from "../../../entities/auth";

Chart.register(Filler, annotationPlugin);

const selectOptions = [
    {key: "all", value: "all", label: "Всего"},
    {key: "accepted", value: "accepted", label: "Подтвержденных"},
    {key: "declined", value: "declined", label: "Отклоненных"},
];

type BookingDataType = {
    labels: string[];
    datasets: BookingDataSets[];
    diff: number;
    total: number;
};

type BookingDataSets = {
    label: string;
    data: number[];
    fill: {
        target: string;
        above: string;
        below: string;
    };
    borderColor: string;
    tension: number;
};

const fill = {
    target: "origin",
    above: "rgba(148, 129, 205, 0.1)",
    below: "rgba(148, 129, 205, 0.1)",
};
const borderColor = "rgba(148, 129, 205, 0.25)";
const initialValue: BookingDataType = {
    labels: [],
    datasets: [
        {
            label: "",
            data: [],
            fill,
            borderColor,
            tension: 0.4,
        },
    ],
    diff: 0,
    total: 0,
};

const options = {
    maintainAspectRatio: false,
    responsive: true,
    layout: {
        padding: 0,
    },
    scales: {
        x: {
            grid: {
                display: false, // Убираем линии сетки по оси X
            },
            ticks: {
                display: true,
                // color: "gray", // Цвет текста меток
                // backdropPadding: 2, // Отступы внутри фона меток
                beginAtZero: true,
            },
            offset: false,
            border: {
                display: false,
            },
        },
        y: {
            display: false,
            grid: {
                display: false, // Убираем линии сетки по оси X
            },
            ticks: {
                display: false, // Убираем подписи по оси Y
                beginAtZero: true,
            },
        },
    },
    plugins: {
        legend: {
            display: false, // Убираем легенду
        },
        hover: {
            mode: "nearest",
            intersect: true,
            onHover: (event, chartElement) => {
                const chart = chartElement[0]?.element?.$context?.chart;
                if (chart) {
                    const index = chartElement[0].index;
                    const xAxis = chart.scales.x;
                    xAxis.ticks[index].label = {
                        ...xAxis.ticks[index].label,
                        backgroundColor: "blue",
                        color: "white",
                        padding: 2,
                        borderRadius: 3,
                    };
                    chart.update();
                }
            },
        },
    },
};

function Plag1() {
    const {dateRange} = useApplication();
    const {shop} = useSelector(authInfoSelect);
    const [datas, setDatas] = useState<BookingDataType>(initialValue);
    // const [loading, setLoading] = useState<boolean>(true);
    const [select, setSelect] = useState<number>(0);

    const urlForBookingPlagin = useMemo(() => {
        const from =
            dateRange && dateRange.from ? dateRange.from : "2024-01-01";
        const to = dateRange && dateRange.to ? dateRange.to : "2024-11-01";
        const state = selectOptions[select].key;
        const url = `${process.env.REACT_APP_API_URL}/shops/${shop?.id}/reports/booking?from=${from}&to=${to}&state=${state}`;

        return url;
    }, [dateRange, select, shop]);

    useEffect(() => {
        fetch(urlForBookingPlagin, {
            // credentials: 'include',
            headers: {
                accept: "application/json",
                Authorization: `${localStorage.getItem("accessToken")}`,
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setDatas(initialValue);
                if (data && data.rows) {
                    const info = transformBookingData(
                        data as IBookingReportResponse
                    );
                    setDatas({
                        labels: info?.labels,
                        datasets: [
                            {
                                label: "",
                                data: info?.data,
                                fill,
                                borderColor,
                                tension: 0.4,
                            },
                        ],
                        diff: info?.diff,
                        total: info?.total,
                    });
                }
                // setLoading(false);
            })
            .catch((err) => {
                console.log("Error fetching booking report data:", err);
                // setLoading(false);
            });
    }, [urlForBookingPlagin]);

    return (
        <Palg1Wrap>
            <StyledHeader>
                <Header>Бронирование</Header>
                <WrapSelect>
                    <BookingSelector
                        select={select}
                        setSelect={setSelect}
                        selectOptions={selectOptions}
                    />
                </WrapSelect>
            </StyledHeader>
            <CharWrap>
                <Line data={datas} options={options} />
            </CharWrap>
            {/* <style>
                {`
          .chartjs-render-monitor {
            position: relative;
          }
          .chartjs-render-monitor .tick {
            background-color: blue;
            color: white;
            padding: 2px 4px;
            border-radius: 3px;
          }
        `}
            </style> */}
            <DiffWrap>
                <DiffInfo diff={datas?.diff} total={datas?.total} />
            </DiffWrap>
        </Palg1Wrap>
    );
}

export default Plag1;

const StyledHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const Palg1Wrap = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
`;

const Header = styled.div`
    margin-bottom: 10px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 28px;
    /* identical to box height, or 112% */
    display: flex;
    align-items: center;

    /* Текст/#333333 текст|кнопки */
    color: #333333;
`;
const WrapSelect = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0px;
`;

const DiffWrap = styled.div`
    position: absolute;
    top: 14%;
    left: 0px;
    z-index: 10;
`;
const CharWrap = styled.div`
    height: 70%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
`;
