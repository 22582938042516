import React, {
    CSSProperties,
    useEffect,
    useState,
    useMemo,
    useRef,
} from "react";
import styled from "styled-components";
import moment from "moment";
import {PuffLoader} from "react-spinners";
import useFetchData from "../../hooks/useFetchData";
import {TableWrapper} from "../../components/TableWrapper";
import {ColumnsType} from "antd/lib/table";
import {getNoun} from "../../utils/functions/getNoun";

import type {DatePickerProps} from "antd";
import {DatePicker} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import type {InputRef, TableColumnType} from "antd";
import {Button, Input, Space} from "antd";
import type {FilterDropdownProps} from "antd/es/table/interface";
import Highlighter from "react-highlight-words";

import history from "../../hystory";
import {useApplication} from "../../context/ApplicationContext";
import dayjs from "dayjs";

moment().format();

interface DataType {
    goodName: string;
    groupId: number;
    mUnitsName: string;
    mUnitsRid: number;
    maxReserve: number;
    minReserve: number;
    quantity: number;
    rid: number;
    sumNds: number;
    sumNsp: number;
    sumWithoutTax: number;
}

type DataIndex = keyof DataType;

const OstatkiPage = () => {
    const {dateFilterMain, setDateFilterMain} = useApplication();
    const {pathname, search} = window.location;
    const searchParams = new URLSearchParams(search);

    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef<InputRef>(null);

    const handleSearch = (
        selectedKeys: string[],
        confirm: FilterDropdownProps["confirm"],
        dataIndex: DataIndex
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText("");
    };

    const [datePickerValue, setDatePickerValue] = useState<any>(null);

    const urlForRemains = useMemo(() => {
        const newURI = datePickerValue
            ? `${process.env.REACT_APP_API_URL}/shops/1/remains?date=${datePickerValue}`
            : `${process.env.REACT_APP_API_URL}/shops/1/remains`;

        return newURI;
    }, [datePickerValue]);

    const {
        data: goodsData,
        loading,
        setParams: setHeaderParams,
    } = useFetchData(urlForRemains, [urlForRemains]);

    const disabledDate: DatePickerProps["disabledDate"] = (current) => {
        return current && current > dayjs().endOf("day");
    };

    useEffect(() => {
        setDatePickerValue(dateFilterMain);
    }, [dateFilterMain]);

    const onDateChange: DatePickerProps["onChange"] = (
        date: any,
        dateString: any
    ) => {
        if (dateString) {
            searchParams.set("date", dateString.toString());
            setDatePickerValue(dateString.toString());
            setHeaderParams([{url: urlForRemains}]);
            setDateFilterMain(dateString.toString());
            history?.replace({
                pathname,
                search: searchParams.toString(),
            });
        } else {
        }
    };

    const cssOverride: CSSProperties = {
        position: "absolute",
        top: "50%",
        left: "50%",
    };

    const getColumnSearchProps = (
        dataIndex: DataIndex
    ): TableColumnType<DataType> => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div style={{padding: 8}} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(
                            selectedKeys as string[],
                            confirm,
                            dataIndex
                        )
                    }
                    style={{marginBottom: 8, display: "block"}}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() =>
                            handleSearch(
                                selectedKeys as string[],
                                confirm,
                                dataIndex
                            )
                        }
                        icon={<SearchOutlined />}
                        size="small"
                        style={{width: 90}}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() =>
                            clearFilters && handleReset(clearFilters)
                        }
                        size="small"
                        style={{width: 90}}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({closeDropdown: false});
                            setSearchText((selectedKeys as string[])[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{color: filtered ? "#1677ff" : undefined}} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{backgroundColor: "#ffc069", padding: 0}}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });

    const columns: ColumnsType = [
        // {
        //   title: 'RID',
        //   dataIndex: 'rid',
        //   render: ((shops_counter: number) => {
        //     return <span>{shops_counter}</span>
        //   }),
        //   width: 30,
        //   sorter: (a, b) => a.rid && b.rid ? a.rid.localeCompare(b.rid) : 0,
        // },
        {
            title: "Наименование",
            dataIndex: "goodName",
            render: (item_name, record) => {
                return <span>{item_name}</span>;
            },
            width: 100,
            // sorter: (a: DataType, b: DataType) => a.goodName && b.goodName ? a.goodName.localeCompare(b.goodName) : 0,
            ...getColumnSearchProps("goodName"),
        },
        {
            title: "Ед.изм",
            dataIndex: "mUnitsName",
            render: (shops_counter: string) => {
                return <span>{shops_counter}</span>;
            },
            width: 100,
            // sorter: (a: DataType, b: DataType) => a.mUnitsName && b.mUnitsName ? a.mUnitsName.localeCompare(b.mUnitsName) : 0,
        },
        {
            title: "Остаток",
            dataIndex: "quantity",
            render: (shops_counter: number) => {
                return <StyledSum>{shops_counter}</StyledSum>;
            },
            width: 100,
            // sorter: (a: DataType, b: DataType) => a.quantity - b.quantity,
        },
        {
            title: "Сумма без налогов",
            dataIndex: "sumWithoutTax",
            render: (shops_counter: number) => {
                return (
                    <StyledSum>
                        {new Intl.NumberFormat("ru-RU").format(
                            Number(shops_counter)
                        )}{" "}
                        р.
                    </StyledSum>
                );
            },
            width: 100,
            // sorter: (a, b) => a.sumWithoutTax - b.sumWithoutTax,
        },
    ];

    return (
        <Wrapper>
            <Header>
                <Title>
                    Товары
                    <SubTitle>
                        {goodsData && Array.isArray(goodsData)
                            ? getNoun(
                                  goodsData.length,
                                  "товар",
                                  "товара",
                                  "товаров"
                              )
                            : "нет товаров"}
                    </SubTitle>
                </Title>
                <StyledCaledarWrapper>
                    <DatePicker
                        onChange={onDateChange}
                        value={dayjs(datePickerValue)}
                        format={"YYYY-MM-DD"}
                        // placeholder={datePickerValue}
                        disabledDate={disabledDate}
                    />
                </StyledCaledarWrapper>
                {/* <Button
          onClick={() => { }}
        >
          <span>+</span>
          Добавить точку продаж
        </Button> */}
            </Header>
            <div>
                {loading ? (
                    <PuffLoader color="#E65E20" cssOverride={cssOverride} />
                ) : (
                    <TableWrapper
                        data={goodsData}
                        loading={loading}
                        columns={columns}
                    />
                )}
            </div>
        </Wrapper>
    );
};

export default OstatkiPage;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* min-width: 100%; */
    width: 100%;
    min-height: 80%;
    top: 0px;
    background-color: #fff;
    padding: 16px 0px 16px 128px;

    .ant-table-thead > tr > th {
        background-color: #9481cd40;
        font-family: "Inter";
        font-size: 14px;
        font-weight: 600;
        line-height: 16.8px;
        text-align: left;
    }

    .ant-table-footer {
        background-color: #9481cd40;
        font-family: "Inter";
        font-size: 14px;
        font-weight: 600;
        line-height: 16.8px;
        text-align: left;
    }

    table > tbody > tr > td {
        background-color: #ffffff;
        font-family: Inter;
        border: 1px solid #efefef;
        color: #333333;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.8px;
        text-align: left;
        height: 30px;
    }
`;

const Header = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    top: 0px;
    margin-bottom: 20px;
    /* gap: 30px; */
`;

const Title = styled.div`
    display: flex;
    flex-direction: column;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #000000;
`;

const SubTitle = styled.span`
    font-weight: 500;
    font-size: 13px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #999999;
`;

const StyledSum = styled.div`
    text-align: right;
    padding-right: 20%;
`;

const StyledCaledarWrapper = styled.div`
    width: 250px;
    color: #ffffff;
    display: flex;
    gap: 10px;
    color: #ffffff;

    & svg {
        width: 20px;
        height: 20px;
    }
    .ant-picker {
        background-color: #a7388e;
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 28px;
        text-align: center;
        color: #ffffff;
    }

    .ant-picker .ant-picker-input,
    .ant-picker .ant-picker-range,
    .ant-picker .ant-picker-input-active .ant-picker-input-placeholder {
        display: flex;
        flex-direction: row-reverse;
        font-family: "Inter";
        font-size: 14px;
        font-weight: 400;
        line-height: 28px;
        color: #ffffff;
    }

    .ant-picker .ant-picker-input .ant-picker-input-active {
        color: #ffffff !important;
    }

    .ant-picker .ant-picker-input .ant-picker-input-placeholder {
        color: #ffffff !important;
    }

    .ant-picker .ant-picker-input span.ant-picker-suffix,
    .ant-picker.ant-picker-range span.ant-picker-suffix {
        order: -1;
        margin-left: 0;
        margin-right: 10px; /* Для отступа иконки от текста */
        color: #ffffff;
    }

    .ant-picker.ant-picker-range.ant-picker-range-separator {
        color: #ffffff !important;
        /* display: none; */
    }

    .ant-picker-range-separator > span {
        color: #ffffff !important; /* Замените #ваш_цвет на нужный код цвета */
    }

    .ant-picker-clear {
        color: #ffffff !important; /* Замените #ваш_цвет на нужный код цвета */
    }

    .ant-picker .ant-picker-clear {
        position: absolute;
        right: 9px;
        /* color: #ffffff !important; */
    }
`;
