import { IBarCharCard, IBarCharCardItem, ICardInfoResponse } from '../../types';
import { convertDate } from './transformBookingData';




export const transformCardsData = (data: ICardInfoResponse[]): IBarCharCard[] => {
	data.sort((a, b) => {
		return a.date > b.date ? 1 : a.date < b.date ? -1 : 0;
	});
	

	const dates = Array.from(new Set(data.map((item) => convertDate(item.date))));
	const templates = Array.from(new Set(data.map((item) => item.template)));

	const datasetsSum: IBarCharCardItem[] = templates.map((template) => ({
		label:template,
		data: new Array(dates.length).fill(0),
		borderRadius: 10,
		borderSkipped: false,
		barThickness: 14,
	}));
	const datasetsAmount: IBarCharCardItem[] = templates.map((template) => ({
		label: template,
		data: new Array(dates.length).fill(0),
		borderRadius: 10,
		borderSkipped: false,
		barThickness: 14,
	}));

	data.forEach((item) => {
		const dateIndex = dates.indexOf(convertDate(item.date));
		const templateIndex = templates.indexOf(item.template);
		if (dateIndex !== -1 && templateIndex !== -1) {
			datasetsSum[templateIndex].data[dateIndex] += item.sum;
			datasetsAmount[templateIndex].data[dateIndex] += item.count;
		}
	});

	return [
	{ labels: dates, datasets: datasetsAmount },
	{ labels: dates, datasets: datasetsSum },
	];
};
