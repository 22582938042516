import React from "react";
import Layout from "../../components/Layout";
interface PropsDash {
    sidemenu: any;
}

const Dash = ({sidemenu}: PropsDash) => {
    return (
        <Layout sidemenu={sidemenu}>
            <div></div>
        </Layout>
    );
};

export default Dash;
