import { styled } from 'styled-components'
import { Table, TableColumnsType } from 'antd';
import { Icons } from '../../../assets';
import { columns1Main, columns1Nested, columns1SubNested, columns1Details } from './columns';

interface TableData {
  key: React.Key;
  SUM: number;
  CURRENCYTYPE?: string;
  CURRENCY?: string;
  ORIGINALSUN?: number;
  SHIFTDATE?: string;
  nested?: TableData[];
}

const columns: TableColumnsType<TableData> = columns1Main;
const nestedColumns: TableColumnsType<TableData> = columns1Nested;
const subNestedColumns: TableColumnsType<TableData> = columns1SubNested;
const detailsNestedColumns: TableColumnsType<TableData> = columns1Details;

const TableViruchkaMain = ({dataValues, loading}) => {
  return (
    <StyledTable>
    <Table
      pagination={false}
      dataSource={dataValues}
      loading={loading}
      columns={columns}
      size="small"
      footer={() => 'footer'}
      expandIconColumnIndex={0}
      expandable={{
        rowExpandable: (record) => true,
        indentSize: 20,
        expandIcon: ({ expanded, onExpand, record }) => {
          return expanded
            ? <div onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => onExpand(record, e)}>
                <Icons.IconMinusCircleFill  />
              </div>
            : <div onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => onExpand(record, e)} >
                <Icons.IconPlusCircleFill />
              </div>
        },
        expandedRowRender: (rec) => {
          const nested = rec['nested']
          return <Table
            pagination={false}
            dataSource={nested}
            columns={nestedColumns}
            size="small"
            footer={() => 'footer'}
            expandIconColumnIndex={0}
            expandable={{
              rowExpandable: (record) => true,
              indentSize: 20,
              expandIcon: ({ expanded, onExpand, record }) => {
                return expanded
                  ? <div onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => onExpand(record, e)}>
                      <Icons.IconMinusCircleFill  />
                    </div>
                  : <div onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => onExpand(record, e)} >
                      <Icons.IconPlusCircleFill />
                    </div>
              },
              expandedRowRender: (rec) => {
                const subNested = rec['subNested']
                return <Table
                  pagination={false}
                  dataSource={subNested}
                  columns={subNestedColumns}
                  size="small"
                  footer={() => 'footer2'}
                  expandIconColumnIndex={0}
                  expandable={{
                    rowExpandable: (record) => true,
                    indentSize: 20,
                    expandIcon: ({ expanded, onExpand, record }) => {
                      return expanded
                        ? <div onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => onExpand(record, e)}>
                            <Icons.IconMinusCircleFill  />
                          </div>
                        : <div onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => onExpand(record, e)} >
                            <Icons.IconPlusCircleFill />
                          </div>
                    },
                    expandedRowRender: (rec) => {
                      const details = rec['details']
                      return <Table
                        pagination={false}
                        dataSource={details}
                        columns={detailsNestedColumns}
                        size="small"
                        footer={() => 'footer3'}
                      />
                    }
                  }}
                />
              }
            }}
          />
        }
      }}
      />
    </StyledTable>
	)
}

export default TableViruchkaMain;


const StyledTable = styled.div`
  .ant-table-thead > tr > th {
    background-color: #9481CD40;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    text-align: left;
    border-radius: 0px !important;
  }

  .ant-table-footer {
    background-color: #9481CD40;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    text-align: left;
  }

  
  table>tbody>tr>td{
    background-color: #ffffff;
    font-family: Inter;
    border: 1px solid #EFEFEF;
    color: #333333;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
    height: 30px;

  }

  
`
