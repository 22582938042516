import React, {useState} from "react";
import styled, {css} from "styled-components";
import ContentModalMain from "./content/ContentModalMain";
import ContentModalBayes from "./content/ContentModalBayes";
import ContentModalHistory from "./content/ContentModalHistory";
import ContentModalCards from "./content/ContentModalCards";
import {PuffLoader} from "react-spinners";
import {cardSelect} from "../../../entities/card";
import {useSelector} from "react-redux";

interface PropsClientModal {
    setOpeningSettingsPopup: (val: boolean) => void;
    loadMore: () => void;
    loadMoreCardChanges: () => void;
}

const ClientsModal: React.FC<PropsClientModal> = ({
    setOpeningSettingsPopup,
    loadMore,
    loadMoreCardChanges,
}) => {
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const {loading} = useSelector(cardSelect);

    const handleButtonClick = (index: number) => {
        setActiveIndex(index);
    };

    const getOffset = (index: number) => {
        return index - activeIndex === 0 ? 0 : index - activeIndex > 0 ? 1 : -1;
    };

    return (
        <Wrapper>
            <BlurredContent $isLoading={loading === "loading"}>
                <SideWrap>
                    <ContentModalMain />
                </SideWrap>
                <OutsideWrap>
                    <NavbarModalWrapper>
                        <NavbarTitleModal>
                            <NavbarTabsModal>
                                <RadioButton
                                    $isActive={activeIndex === 0}
                                    onClick={() => handleButtonClick(0)}
                                >
                                    <span>Покупки</span>
                                </RadioButton>
                                <RadioButton
                                    $isActive={activeIndex === 1}
                                    onClick={() => handleButtonClick(1)}
                                >
                                    <span>История</span>
                                </RadioButton>
                                <RadioButton
                                    $isActive={activeIndex === 2}
                                    onClick={() => handleButtonClick(2)}
                                >
                                    <span>Карты</span>
                                </RadioButton>
                                <Highlight
                                    style={{
                                        transform: `translateX(${
                                            activeIndex * 272
                                        }px)`,
                                    }}
                                />
                            </NavbarTabsModal>
                        </NavbarTitleModal>
                    </NavbarModalWrapper>
                    <ContentWrap>
                        <FormWindow key={0} offset={getOffset(0)}>
                            <ContentModalBayes
                                setOpeningSettingsPopup={
                                    setOpeningSettingsPopup
                                }
                                loadMore={loadMore}
                            />
                        </FormWindow>
                        <FormWindow key={1} offset={getOffset(1)}>
                            <ContentModalHistory
                                loadMore={loadMoreCardChanges}
                                setOpeningSettingsPopup={
                                    setOpeningSettingsPopup
                                }
                            />
                        </FormWindow>
                        <FormWindow key={2} offset={getOffset(2)}>
                            <ContentModalCards
                                setOpeningSettingsPopup={
                                    setOpeningSettingsPopup
                                }
                            />
                        </FormWindow>
                    </ContentWrap>
                </OutsideWrap>
            </BlurredContent>
            {loading === "loading" && (
                <LoaderContainer>
                    <PuffLoader color="#E65E20" size={60} />
                </LoaderContainer>
            )}
        </Wrapper>
    );
};

export default ClientsModal;

const SideWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 216px;
`;
const OutsideWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
`;

const FormWindow = styled.div<{
    offset: any;
}>`
    position: absolute;
    transition: transform 0.5s ease-in-out;
    transform: translateX(${({offset}) => offset * 120 - 0}%);
`;

const ContentWrap = styled.div`
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    z-index: 1105;
    overflow-y: auto;
`;

const Highlight = styled.div`
    position: absolute;
    background-color: #fa0;
    transform: translateX(0);
    transition: transform 0.3s ease;
    z-index: 1107;
    width: 116px;
    height: 34px;
    background: #a7388e;
    box-shadow: 0px 3px 3.4px rgba(0, 0, 0, 0.21);
    border-radius: 5px;
    flex: none;
    order: 0;
    flex-grow: 0;
`;

const Wrapper = styled.div`
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 917px;
    height: 605px;
    background: #f7f7f7;
    border-radius: 5px;
    z-index: 1105;
    overflow: hidden;
    display: flex;
`;

const NavbarModalWrapper = styled.div`
    width: 680px;
    height: 46px;
    margin-bottom: 18px;
`;

const NavbarTitleModal = styled.div`
    display: flex;
    width: 100%;
    height: 28px;
`;

const NavbarTabsModal = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    width: 680px;
    height: 46px;
    left: 226px;
    top: 10px;
    padding: 0px 10px;
    background: #f3f3f3;
    box-shadow: inset 0px -1px 10px rgba(0, 0, 0, 0.1),
        inset 0px 4px 4px rgba(0, 0, 0, 0.03);
    border-radius: 5px;
`;

const RadioButton = styled.div<{
    $isActive: boolean;
}>`
    display: flex;
    gap: 10px;
    z-index: 1110;
    width: 120px;
    height: 34px;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    flex: none;
    order: 1;

    flex-grow: 0;
    color: ${(props) => (props.$isActive ? "white" : "black")};
    transition: color 0.5s ease;

    cursor: pointer;
    & span {
        text-align: center;
        vertical-align: middle;
    }

    &:active {
        color: "white";
    }
`;
const BlurredContent = styled.div<{$isLoading: boolean}>`
    flex: 1;
    display: flex;
    position: relative;
    ${({$isLoading}) =>
        $isLoading &&
        css`
            filter: blur(2px);
            opacity: 0.7;
            pointer-events: none;
        `}
`;

const LoaderContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1110;
    background-color: rgba(247, 247, 247, 0.5);
`;
