import React from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import {cardSelect} from "../../../../entities/card";
import {useSelector} from "react-redux";

interface PropsClientModal {
    loadMore: () => void;
    setOpeningSettingsPopup: (val: boolean) => void;
}

const ContentModalHistory: React.FC<PropsClientModal> = ({
    loadMore,
    setOpeningSettingsPopup,
}) => {
    const {cardChanges} = useSelector(cardSelect);
    return (
        <Wrapper>
            <Hr />
            <WrapHistory>
                {cardChanges?.map((item) => (
                    <Card key={item?.id}>
                        <StyledDate>
                            {item?.date
                                ? dayjs(item.date).format("DD.MM.YYYY")
                                : ""}
                        </StyledDate>
                        <StyledLine></StyledLine>
                        <InfoWrap>
                            <StyledImage></StyledImage>
                            <StyledFrameInfo>
                                <StyledTextArea>
                                    <span>{item?.desc}</span>
                                </StyledTextArea>
                                <StyledTextArea>
                                    Карта: <span>{item?.card_code}</span>
                                </StyledTextArea>
                                <StyledTextArea>
                                    Изменение баланса:{" "}
                                    <span>{item?.balance}</span>
                                </StyledTextArea>
                                <StyledTextArea style={{marginTop: "15px"}}>
                                    Изменение бонус:{" "}
                                    <span>
                                        {`${item?.bonusBefore + item?.bonus}` ||
                                            "0"}
                                    </span>
                                </StyledTextArea>
                            </StyledFrameInfo>
                        </InfoWrap>
                    </Card>
                ))}
                <StyledButtonMore onClick={() => loadMore()}>
                    <span>Показать еще</span>
                </StyledButtonMore>
            </WrapHistory>
            <Hr />

            <StyledFooter>
                <StyledButtonClose
                    onClick={() => setOpeningSettingsPopup(false)}
                >
                    Закрыть
                </StyledButtonClose>
                <StyledButtonSave
                    onClick={() => setOpeningSettingsPopup(false)}
                >
                    <span>Сохранить</span>
                </StyledButtonSave>
            </StyledFooter>
        </Wrapper>
    );
};

export default ContentModalHistory;

const WrapHistory = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 680px;
    height: 420px;
    gap: 10px;
    overflow-y: auto;

    ::-webkit-scrollbar {
        width: 6px;
    }

    /* Стилизация ползунка скроллбара */
    ::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        border-radius: 50%;
    }

    /* Стилизация ползунка при наведении */
    ::-webkit-scrollbar-thumb:hover {
        background: #b30000;
    }

    /* Стили для Firefox */
    scrollbar-color: darkgrey #ffffff;
    scrollbar-width: thin;
`;

const TextStyles = styled.div`
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
`;

const Card = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 176px;
`;

const StyledDate = styled.div`
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 23px;
    width: 80px;
    display: flex;
    align-items: center;
    color: #333333;
    flex: none;
    order: 0;
    flex-grow: 0;
`;

const StyledLine = styled.div`
    width: 2px;
    height: 176px;
    margin-left: 10px;
    border: 2px solid #333333;
`;

const InfoWrap = styled.div`
    display: flex;
    flex-wrap: nowrap;
    box-sizing: border-box;
    flex-direction: row;
    align-items: center;
    gap: 32px;
    margin-top: 10px;
    margin-left: 10px;
    width: 560px;
    height: 159px;
    border: 1px solid #d6d6d6;
    border-radius: 5px;
    flex: none;
    order: 2;
    flex-grow: 0;
`;

const StyledImage = styled.div``;

const StyledFrameInfo = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyledTextArea = styled(TextStyles)`
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #8f8f8f;

    span {
        margin-left: 3px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 23px;
        color: #333333;
    }
`;

const StyledButtonClose = styled(TextStyles)`
    width: 59px;
    height: 28px;
    line-height: 28px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #8f8f8f;
    cursor: pointer;
`;

const StyledButtonSave = styled(TextStyles)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 58px;
    gap: 10px;
    cursor: pointer;
    width: 195px;
    height: 40px;
    background: #a7388e;
    border-radius: 5px;
    flex: none;
    order: 1;
    flex-grow: 0;

    & span {
        line-height: 28px;
        text-align: center;
        color: #ffffff;
    }
`;

const StyledFooter = styled.div`
    display: flex;
    gap: 34px;
    flex-direction: row;
    justify-content: flex-end;
    height: 40px;
    margin-top: 11px;
    align-items: center;
    position: absolute;
    bottom: 10px;
    right: 10px;
`;

const Hr = styled.hr`
    width: 680px;
    height: 0px;
    border: 3px solid rgba(143, 143, 143, 0.3);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
    margin-bottom: 10px;
    margin-top: 10px;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 680px;
    height: 524px;
    z-index: 1205;
`;
const StyledButtonMore = styled(TextStyles)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
    align-items: center;
    cursor: pointer;
    width: 150px;
    height: 40px;
    background: #f7f7f7;
    border-radius: 5px;
    margin-bottom: 10px;
    & span {
        line-height: 28px;
        color: #a7388e;
    }
`;
