export const fonts = {
    main: '"Inter", sans-serif',
    secondary: '"Roboto", sans-serif',
    sizes: {
        small: "0.75rem", // 12px
        regular: "0.875rem", //14px
        medium: "1rem", //16px
        large: "1.25rem", //20px
        xlarge: "1.5rem", //24px
    },
    weights: {
        light: 300,
        regular: 400,
        medium: 500,
        bold: 700,
    },
    lineHeight: {
        s: "0.938rem", // 15px
    },
};
