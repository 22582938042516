import {CSSProperties, useEffect, useState, useRef} from "react";
import styled from "styled-components";
import _ from "lodash";
import moment from "moment";
import {PuffLoader} from "react-spinners";
import {TableWrapper} from "../../components/TableWrapper";
import {ColumnsType} from "antd/lib/table";
import {getNoun} from "../../utils/functions/getNoun";
import {menuPageColumns} from "./components/columns";

moment().format();

const uniqueByKeys = (array, keys) => {
    const seen = new Set();
    return array.filter((item) => {
        const key = keys.map((k) => item[k]).join("|");
        return seen.has(key) ? false : seen.add(key);
    });
};

const defaultInventCategory = {
    parent_id: 0,
    shop_id: 1,
    color: "#000000",
    borderColor: "#000000",
    name_invented: "Прочее",
};

const MenuPage = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [menuData, setMenuData] = useState<any[]>([]);
    const [nameInventedArray, setNameInventedArray] = useState<any[]>([]);
    const [nameCategoriesArray, setNameCategoriesArray] = useState<any[]>([]);
    // const [hasFetched, setHasFetched] = useState(false);
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const hasFetchedRef = useRef(false);

    useEffect(() => {
        setFilteredData(menuData);
    }, [menuData]);

    useEffect(() => {
        if (loading && !hasFetchedRef.current) {
            hasFetchedRef.current = true;
            setLoading(false);
            const menuUrl = `${process.env.REACT_APP_API_URL}/shops/1/menu`;
            const url = `${process.env.REACT_APP_API_URL}/shops/1/inventedCategory/items`;
            Promise.all([
                fetch(url, {
                    // credentials: 'include',
                    headers: {
                        accept: "application/json",
                        Authorization: `${localStorage.getItem("accessToken")}`,
                        "Content-Type": "application/json",
                    },
                })
                    .then((res) => res.json())
                    .catch(() => []),
                fetch(menuUrl, {
                    // credentials: 'include',
                    headers: {
                        accept: "application/json",
                        Authorization: `${localStorage.getItem("accessToken")}`,
                        "Content-Type": "application/json",
                    },
                })
                    .then((res) => res.json())
                    .catch(() => []),
            ]).then(([inventedGroupsData, data]) => {
                if (
                    data &&
                    Array.isArray(data) &&
                    inventedGroupsData &&
                    data.length > 0
                ) {
                    const updatedData = data.map((item) => {
                        const {CATCODE: catcode} = item;
                        let indexInvent = _.findIndex(inventedGroupsData, {
                            category_id: catcode,
                        });

                        if (indexInvent !== -1) {
                            return {
                                ...item,
                                ...inventedGroupsData[indexInvent].invent,
                            };
                        } else {
                            return {...item, ...defaultInventCategory};
                        }
                    });
                    const inventedCategoriesNameArray = _(updatedData)
                        .map((i) => i.name_invented)
                        .uniq()
                        .map((o) => ({
                            text: o,
                            value: o,
                        }))
                        .value();

                    const categoriesNameArray = _(updatedData)
                        .map((i) => i.CATEGORY)
                        .uniq()
                        .map((o) => ({
                            text: o,
                            value: o,
                        }))
                        .value();

                    setNameInventedArray(inventedCategoriesNameArray);
                    setNameCategoriesArray(categoriesNameArray);
                    setMenuData(uniqueByKeys(updatedData, ["CATID", "ID"]));

                    // setFromDate(from)
                    // setToDate(to)

                    // setHasFetched(true);
                }
            });
        }
    }, [loading]);

    const cssOverride: CSSProperties = {
        position: "absolute",
        top: "50%",
        left: "50%",
    };

    const columns: ColumnsType = menuPageColumns(
        nameInventedArray,
        nameCategoriesArray
    );

    return loading ? (
        <PuffLoader color="#E65E20" cssOverride={cssOverride} />
    ) : (
        <Wrapper>
            <Header>
                <Title>
                    Позиций в меню
                    <SubTitle>
                        {menuData
                            ? getNoun(menuData.length, "ед.", "ед.", "ед.")
                            : "нет товаров"}
                    </SubTitle>
                </Title>
                {/* <Button
          onClick={() => { }}
        >
          <span>+</span>
          Добавить точку продаж
        </Button> */}
            </Header>
            <TableWrapper
                data={filteredData}
                loading={loading}
                columns={columns}
                setFilteredData={setFilteredData}
            />
        </Wrapper>
    );
};

export default MenuPage;

const Wrapper = styled.div`
    min-width: 100%;
    height: 80%h;
    background-color: #fff;
    padding: 16px 29px 16px 128px;
`;

const Header = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
`;

const Title = styled.div`
    display: flex;
    flex-direction: column;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #000000;
`;

const SubTitle = styled.span`
    font-weight: 500;
    font-size: 13px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #999999;
`;
