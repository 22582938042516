import { useState } from 'react';
import styled from 'styled-components'
import moment from 'moment';

const buttons = ['but 0', 'but 2', 'but 3']
moment().format();

const StatisticsPage = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleButtonClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <Wrapper>
      <Header>
        <Title>
          Статистика
        </Title>
      </Header>
      <ButtonsContainer>
        {buttons.map((button, index) => (
          <Button
            key={button}
            active={index === activeIndex}
            onClick={() => handleButtonClick(index)}
          >
            {button}
          </Button>
        ))}
        <Highlight style={{ transform: `translateX(${activeIndex * 270+100}px)` }} />
      </ButtonsContainer>
    </Wrapper>
   
  )
}

export default StatisticsPage;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* min-width: 100%; */
  width: 100%;
  min-height: 80%;
  top: 0px;
  background-color: #fff;
  padding: 16px 0px 16px 0px;
`

const Header = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  top: 0px;
  margin-bottom: 20px;
  /* gap: 30px; */
`

const Title = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #000000;
`

const ButtonsContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
`;

const Button = styled.button<{
  active: boolean
}>`
  margin: 0 100px;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  background-color: #ccc;
  transition: background-color 0.3s ease;
  z-index: 1;
  ${({ active }) => active &&`background-color: #f90;`}
`;

const Highlight = styled.div`
  position: absolute;
  bottom: 0;
  border-radius: 5px;
  left: 0;
  width: 70px;
  height: 100%;
  background-color: #fa0;
  transform: translateX(0);
  transition: transform 0.3s ease;
  z-index: 0;
`;