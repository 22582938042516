import {
    createSlice,
    PayloadAction,
    ActionReducerMapBuilder,
  } from '@reduxjs/toolkit';
import { RootState } from '../../../config/store';
import { getNotifys} from '../../../api/thunks/notify';
import { INotifyRequest, INotifyState } from '../types';
import React from 'react';
import { PaginatedType } from '../../../types';


  
  const initialState: INotifyState = {
    notifys: [],
    total: 29,
    editNotify: null,
    loading: 'idle',
    error: null,
  };
  
  const notifySlice = createSlice({
    name: '@@notify',
    initialState,
    reducers: {

       setEditNotify:(state: INotifyState, { payload }: PayloadAction<React.Key>) => {
         const notify = state.notifys.find((item) => item.id === payload);
         state.editNotify = notify!
       }, 
     
      clearCardState: () => {
        return initialState;
      },
    },
    extraReducers: (builder: ActionReducerMapBuilder<INotifyState>) => {
      builder
      .addCase(
        getNotifys.fulfilled,
        (state: INotifyState, {payload}: PayloadAction<PaginatedType<INotifyRequest>>) => {
          state.notifys = payload.rows;
          state.total = payload.count
        },
      )
          
        .addMatcher(
          action => action.type.endsWith('/pending'),
          state => {
            state.loading = 'loading';
            state.error = null;
          },
        )
        .addMatcher(
          action => action.type.endsWith('/rejected'),
          (state, action: PayloadAction<string>) => {
            state.loading = 'idle';
            state.error = action.payload
            
          },
        )
        .addMatcher(
          action => action.type.endsWith('/fulfilled'),
          state => {
            state.loading = 'idle';
          },
        );
    },
  });
  
  export const {
   
    setEditNotify,
    clearCardState,
    
  } = notifySlice.actions;
  export const notifyReducer = notifySlice.reducer;
  
  export const notifySelect = (state: RootState) => state.notify;