import React from "react";
import styled from "styled-components";
import moment from "moment";
import dayjs from "dayjs";
import {Table} from "antd";
import type {TableColumnsType} from "antd";
import {IClientCardHistory} from "../../../../entities/card/types";
import {IClient} from "../../../../types";
import {formaterSum} from "../../../../utils/functions/formaterSumm";

interface PropsClientModal {
    selectedCheck: IClientCardHistory | null;
    selectedClient: IClient | null;
}

moment().format();

const ContentModalCheck: React.FC<PropsClientModal> = ({
    selectedCheck,
    selectedClient,
}) => {
    const columns: TableColumnsType = [
        {
            title: "Наименование",
            dataIndex: "name",
            sorter: (a, b) => (a.fio && b.fio ? a.fio.localeCompare(b.fio) : 0),
        },
        {
            title: "Категория",
            dataIndex: "category",
            sorter: (a, b) =>
                a.category && b.category
                    ? a.category.localeCompare(b.category)
                    : 0,
        },
        {
            title: "Цена",
            dataIndex: "price",
            render: (value) => (
                <span>{value ? formaterSum(value) : formaterSum(0)}</span>
            ),
        },
        {
            title: "Количество",
            dataIndex: "quantity",
        },
        // {
        //   title: 'Скидка',
        //   dataIndex: 'discount',
        // },
        // {
        //   title: 'Бонусы',
        //   dataIndex: 'sum',
        // },
        // {
        //   title: 'Депозит',
        //   dataIndex: 'sum',
        // },
        {
            title: "Сумма к оплате",
            dataIndex: "sum",
            render: (value) => (
                <span>{value ? formaterSum(value) : formaterSum(0)}</span>
            ),
        },
    ];

    return (
        <Wrapper>
            <HeaderTitle>Чек №{selectedCheck?.checknum}</HeaderTitle>
            <Hr />
            <StyledTableWrapper>
                <StyledTableHeader>
                    <StyledHeaderBlock>
                        <HeaderTitle
                            style={{fontSize: "20px", marginBottom: "5px"}}
                        >
                            Основное
                        </HeaderTitle>
                        <HeaderElement>
                            <HeaderTitle>Клиент</HeaderTitle>
                            <HeaderValue>{selectedClient?.fio}</HeaderValue>
                        </HeaderElement>
                        <HeaderElement>
                            <HeaderTitle>Дата</HeaderTitle>
                            <HeaderValue>
                                {dayjs(selectedCheck?.date).format(
                                    "DD.MM.YYYY HH:mm:ss"
                                )}
                            </HeaderValue>
                        </HeaderElement>
                        <HeaderElement>
                            <HeaderTitle>Сумма чека</HeaderTitle>
                            <HeaderValue>!!!!!!!!!!!!</HeaderValue>
                        </HeaderElement>
                        <HeaderElement>
                            <HeaderTitle>Сумма со скидкой</HeaderTitle>
                            <HeaderValue>!!!!!!!!!!!!!</HeaderValue>
                        </HeaderElement>
                        <HeaderElement>
                            <HeaderTitle>Организация</HeaderTitle>
                            <HeaderValue>
                                {selectedCheck?.cashserver}
                            </HeaderValue>
                        </HeaderElement>
                    </StyledHeaderBlock>
                    <StyledHeaderBlock>
                        <HeaderTitle
                            style={{fontSize: "20px", marginBottom: "5px"}}
                        >
                            Бонусы
                        </HeaderTitle>
                        <HeaderElement>
                            <HeaderTitle>До визита</HeaderTitle>
                            <HeaderValue>
                                {selectedCheck?.bonusBefore
                                    ? formaterSum(selectedCheck.bonusBefore)
                                    : "0"}
                            </HeaderValue>
                        </HeaderElement>
                        <HeaderElement>
                            <HeaderTitle>Начислено</HeaderTitle>
                            <HeaderValue>
                                {selectedCheck?.bonus
                                    ? formaterSum(selectedCheck?.bonus)
                                    : "0"}
                            </HeaderValue>
                        </HeaderElement>
                        <HeaderElement>
                            <HeaderTitle>Списано</HeaderTitle>
                            <HeaderValue>
                                {formaterSum(
                                    selectedCheck?.bonus &&
                                        selectedCheck.bonus < 0
                                        ? -selectedCheck.bonus
                                        : 0
                                )}
                            </HeaderValue>
                        </HeaderElement>
                        <HeaderElement>
                            <HeaderTitle>После визита</HeaderTitle>
                            <HeaderValue>
                                {selectedCheck?.bonus &&
                                selectedCheck?.bonusBefore
                                    ? formaterSum(
                                          selectedCheck?.bonus +
                                              selectedCheck?.bonusBefore
                                      )
                                    : 0}
                            </HeaderValue>
                        </HeaderElement>
                    </StyledHeaderBlock>
                    <StyledHeaderBlock>
                        <HeaderTitle
                            style={{fontSize: "20px", marginBottom: "5px"}}
                        >
                            Депозит
                        </HeaderTitle>
                        <HeaderElement>
                            <HeaderTitle>До визита</HeaderTitle>
                            <HeaderValue>
                                {selectedCheck?.balanceBefore
                                    ? formaterSum(selectedCheck.balanceBefore)
                                    : "0"}
                            </HeaderValue>
                        </HeaderElement>
                        <HeaderElement>
                            <HeaderTitle>Начислено</HeaderTitle>
                            <HeaderValue>
                                {selectedCheck?.balance
                                    ? formaterSum(
                                          selectedCheck?.balance < 0
                                              ? 0
                                              : selectedCheck.balance
                                      )
                                    : "0"}
                            </HeaderValue>
                        </HeaderElement>
                        <HeaderElement>
                            <HeaderTitle>Списано</HeaderTitle>
                            <HeaderValue>
                                {selectedCheck?.balance
                                    ? formaterSum(
                                          selectedCheck.balance < 0
                                              ? -selectedCheck.balance
                                              : 0
                                      )
                                    : "0"}
                            </HeaderValue>
                        </HeaderElement>
                        <HeaderElement>
                            <HeaderTitle>После визита</HeaderTitle>
                            <HeaderValue>
                                {selectedCheck?.balance &&
                                selectedCheck.balanceBefore
                                    ? formaterSum(
                                          selectedCheck.balance +
                                              selectedCheck.balanceBefore
                                      )
                                    : "0"}
                            </HeaderValue>
                        </HeaderElement>
                    </StyledHeaderBlock>
                </StyledTableHeader>
                <Br />
                <StyledTableBody>
                    <Table
                        size="small"
                        // rowSelection={rowSelection}
                        columns={columns}
                        rowKey={(rec) => rec.id}
                        dataSource={selectedCheck?.lines}
                        // onChange={handleTableChange}
                        // pagination={{ pageSize: 20 }}
                        // footer={renderTableFooter}
                    />
                </StyledTableBody>
            </StyledTableWrapper>
            <Br />
        </Wrapper>
    );
};

export default ContentModalCheck;

const StyledTableWrapper = styled.div`
    height: 643px;
`;

const StyledTableHeader = styled.div`
    display: flex;
    flex-direction: row nowrap;
    width: 100%;
    height: 150px;
    gap: 5px;
    padding: 10px;
    justify-content: space-between;
`;

const StyledHeaderBlock = styled.div`
    display: flex;
    flex-direction: column;
    height: 400px;
`;

const HeaderElement = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
`;

const HeaderTitle = styled.div`
    width: 150px;
    height: 23px;
    text-align: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 23px;
    display: flex;
    align-items: center;
    color: #333333;
    flex: none;
    order: 0;
    flex-grow: 0;
`;

const HeaderValue = styled.div``;

const StyledTableBody = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 10px;
    max-height: 470px;
    overflow-y: auto;
    overflow-x: hidden;
`;

const Hr = styled.hr`
    width: 1140px;
    height: 0px;
    margin-bottom: 15px;
    border: 3px solid rgba(143, 143, 143, 0.3);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
`;

const Br = styled.hr`
    margin: 5px 0px;
    width: 1140px;
    height: 0px;
    border: 1px solid rgba(143, 143, 143, 0.3);
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 544px;
    z-index: 1205;
    flex: none;
    flex-grow: 0;
`;
