import {useState} from "react";
import {Bar} from "react-chartjs-2";
import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import {barsColorArray} from "../../../config/colors";
import {LegendContainer} from "./LegendContainer";
import {getRandomColors} from "../../../utils/functions/ getRundomNumber";

ChartJS.register(
    BarElement,
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend
);

const data = {
    labels: ["27.04", "28.04", "29.04", "30.04", "01.05", "02.05"],
    datasets: [
        {
            label: "Woman card",
            data: [600, 580, 420, 420, 350, 480],
            backgroundColor: "#DD1A6A",
            borderRadius: 5,
            borderSkipped: false,
            barThickness: 45,
        },
        {
            label: "Friend card",
            data: [500, 380, 380, 300, 300, 400],
            backgroundColor: "#FBB500",
            borderRadius: 5,
            borderSkipped: false,
            barThickness: 45,
        },
        {
            label: "Deposit",
            data: [950, 600, 480, 500, 400, 500],
            backgroundColor: "#2B1ECA",
            borderRadius: 5,
            barThickness: 45,
        },
    ],
};

const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false, // Отключаем стандартную легенду
        },
        // legend: {
        //   position: 'top' as const,
        //   labels: {
        //     usePointStyle: true,
        //     pointStyle: 'rect',
        //   },
        // },
    },
    scales: {
        // x: {
        //   type: 'category',
        //   barPercentage: 0.9, // Настройка ширины баров
        //   categoryPercentage: 0.1, // Настройка расстояния между барами
        // },
        y: {
            ticks: {
                maxTicksLimit: 5, // Установите максимальное количество меток на оси Y
            },
        },
    },
};

const BarChart = () => {
    const [hiddenDatasets, setHiddenDatasets] = useState<number[]>([]);

    if (data && data.datasets) {
        const datas = {
            labels: data.labels,
            datasets: data.datasets.map((item, ind) => {
                const color = getRandomColors(barsColorArray, 20);
                return {
                    label: item.label,
                    data: item.data,
                    borderColor: color,
                    backgroundColor: color,
                    fill: false,
                    spanGaps: false, // Убедитесь, что пропуски не заполняются
                    hidden: hiddenDatasets.includes(ind),
                };
            }),
        };

        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "100%",
                    marginBottom: "38px",
                }}
            >
                {" "}
                {/* Используем Flexbox */}
                <LegendContainer
                    datas={datas}
                    setHiddenDatasets={setHiddenDatasets}
                    hiddenDatasets={hiddenDatasets}
                />
                <div style={{width: "1366px", height: "330px"}}>
                    {" "}
                    {/* Установите размеры контейнера */}
                    <Bar data={datas} options={options} />
                </div>
            </div>
        );
    }
    return <></>;
};

export default BarChart;
