import {
    createSlice,
    PayloadAction,
    ActionReducerMapBuilder,
} from "@reduxjs/toolkit";
import {RootState} from "../../../config/store";
import {
    ICard,
    ICardSpendStats,
    ICardState,
    IClientCardHistory,
    ITemaplate,
} from "../types";
import {getTemplates} from "../../../api/thunks/notify";
import {
    getAllCardsSpendStats,
    getClientCardChanges,
    getClientCards,
    getClientCardTransactions,
} from "../../../api/thunks/card";
import {PaginatedType} from "../../../types";

const initialState: ICardState = {
    templates: [],
    cardTransactions: [],
    cardChanges: [],
    clientCards: [],
    transactionCount: 0,
    changesCount: 0,
    cardSpendStats: null,
    histCardCount: 0,
    loading: "idle",
    error: null,
};

const cardSlice = createSlice({
    name: "@@card",
    initialState,
    reducers: {
        clearCardState: () => {
            return initialState;
        },
    },
    extraReducers: (builder: ActionReducerMapBuilder<ICardState>) => {
        builder
            .addCase(
                getTemplates.fulfilled,
                (state: ICardState, {payload}: PayloadAction<ITemaplate[]>) => {
                    state.templates = payload;
                }
            )
            .addCase(
                getClientCardTransactions.fulfilled,
                (
                    state: ICardState,
                    {payload}: PayloadAction<PaginatedType<IClientCardHistory>>
                ) => {
                    if (state.transactionCount === 0) {
                        state.cardTransactions = payload.rows;
                    } else {
                        const combinedHistorys = [
                            ...state.cardTransactions,
                            ...payload.rows,
                        ];
                        const uniqueClients = Array.from(
                            new Map(
                                combinedHistorys.map((item) => [item.id, item])
                            ).values()
                        );
                        state.cardTransactions = uniqueClients;
                    }
                    state.transactionCount = payload.count;
                }
            )
            .addCase(
                getClientCardChanges.fulfilled,
                (
                    state: ICardState,
                    {payload}: PayloadAction<PaginatedType<IClientCardHistory>>
                ) => {
                    if (state.changesCount === 0) {
                        state.cardChanges = payload.rows;
                    } else {
                        const combinedHistorys = [
                            ...state.cardChanges,
                            ...payload.rows,
                        ];
                        const uniqueClients = Array.from(
                            new Map(
                                combinedHistorys.map((item) => [item.id, item])
                            ).values()
                        );
                        state.cardChanges = uniqueClients;
                    }
                    state.changesCount = payload.count;
                }
            )

            .addCase(
                getClientCards.fulfilled,
                (state: ICardState, {payload}: PayloadAction<ICard[]>) => {
                    state.clientCards = payload;
                }
            )

            .addCase(
                getAllCardsSpendStats.fulfilled,
                (
                    state: ICardState,
                    {payload}: PayloadAction<ICardSpendStats>
                ) => {
                    state.cardSpendStats = payload;
                }
            )

            .addMatcher(
                (action) => action.type.endsWith("/pending"),
                (state) => {
                    state.loading = "loading";
                    state.error = null;
                }
            )
            .addMatcher(
                (action) => action.type.endsWith("/rejected"),
                (state, action: PayloadAction<string>) => {
                    state.loading = "idle";
                    state.error = action.payload;
                }
            )
            .addMatcher(
                (action) => action.type.endsWith("/fulfilled"),
                (state) => {
                    state.loading = "idle";
                }
            );
    },
});

export const {clearCardState} = cardSlice.actions;
export const cardReducer = cardSlice.reducer;

export const cardSelect = (state: RootState) => state.card;
