import moment from "moment";
import {renderNotifyStatus} from "../../../../utils/functions";
import {TableColumnsType} from "antd";
import {Tag} from "antd";
import {styled} from "styled-components";

export const notifyColumns: TableColumnsType = [
    {
        title: "№",
        dataIndex: "id",
        width: 30,
        render: (id: number) => (
            <StyledLink tabIndex={0}>{id.toString()}</StyledLink>
        ),
    },
    {
        title: "ДАТА ОТПРАВКИ",
        dataIndex: "date",
        width: 140,
        render: (record) => {
            return (
                <span>
                    {record
                        ? moment.utc(record).local().format("DD.MM.YYYY HH:mm")
                        : "-"}
                </span>
            );
        },
    },
    {
        title: "ТЕКСТ",
        dataIndex: "text",
        width: 370,
    },

    {
        title: "АУДИТОРИЯ",
        width: 220,
        dataIndex: "templates",
        render: (templates: any[]) => (
            <>
                {templates.map((template, ind) => {
                    const displayName =
                        typeof template.name === "string" ? template.name : "";
                    let color =
                        displayName && displayName.length > 10
                            ? displayName && displayName.length > 13
                                ? "volcano"
                                : "cyan"
                            : displayName && displayName.length === 10
                            ? "red"
                            : displayName && displayName.length < 7
                            ? "blue"
                            : "green";
                    return (
                        <Tag color={color} key={ind}>
                            {displayName && displayName.toUpperCase()}
                        </Tag>
                    );
                })}
            </>
        ),
    },
    {
        title: "КОЛИЧЕСТВО",
        dataIndex: "lastIndex",
        width: 140,
        render: (record) => {
            return <span>{record ? record : "-"}</span>;
        },
    },

    {
        title: "СТАТУС",
        dataIndex: "state",
        width: 80,
        render: (record) => {
            return <span>{record ? renderNotifyStatus(record) : "-"}</span>;
        },
    },
];

const StyledLink = styled.a`
    cursor: default;
`;
