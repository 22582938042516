import { AxiosResponse } from 'axios';
import { api, API_URL } from '../../config';
import { IBaseQuery, IOkResponse, PaginatedType } from '../../../types';
import { ICancelNotify, INotifyUpdateRequest } from './types';
import { ITemaplate } from '../../../entities/card/types';
import { INotifyRequest } from '../../../entities/notify/types';

const BASE_PATH = 'shops';

export const cancelNotifyReuest = async ({
	shop_id,
	notify_id,
}: ICancelNotify): Promise<AxiosResponse<IOkResponse>> => {
	return await api.post<IOkResponse>(
		`${API_URL}/${BASE_PATH}/${shop_id}/loyalty/notify/${notify_id}/cancel`,
	);
};

export const deleteNotifyReuest = async ({
	shop_id,
	notify_id,
}: ICancelNotify): Promise<AxiosResponse<IOkResponse>> => {
	return await api.delete<IOkResponse>(
		`${API_URL}/${BASE_PATH}/${shop_id}/loyalty/notify/${notify_id}`,
	);
};

export const getTemplatesReuest = (shop_id: number): Promise<AxiosResponse<ITemaplate[]>> => {
	return api.get<ITemaplate[]>(
		`${API_URL}/${BASE_PATH}/${shop_id}/loyalty/card-templates/short-list`,
	);
};

export const getNotifyRequest = ({
	shop_id,
	params,
}: {
	shop_id: number;
	params: IBaseQuery;
}): Promise<AxiosResponse<PaginatedType<INotifyRequest>>> => {
	return api.get<PaginatedType<INotifyRequest>>(
		`${API_URL}/${BASE_PATH}/${shop_id}/loyalty/notify`,
		{
			params: {
				...params,
			},
		},
	);
};

export const updateNotifyRequest = ({
	shop_id,
	notify_id,
	data,
}: {
	shop_id: number;
	notify_id: React.Key[];
	data: INotifyUpdateRequest;
}): Promise<AxiosResponse<INotifyUpdateRequest>> => {
	return api.put<INotifyUpdateRequest>(
		`${API_URL}/${BASE_PATH}/${shop_id}/loyalty/notify/${notify_id}`,
		data,
	);
};

export const sendNotifyRequest = async (
	data: INotifyRequest,
	shop_id: number,
): Promise<AxiosResponse<INotifyRequest>> => {
	return await api.post<INotifyRequest>(`${API_URL}/${BASE_PATH}/${shop_id}/loyalty/notify `, {
		...data,
	});
};
