import styled from "styled-components"
import { Logos } from "../assets"

const CustomEmpty = () => {
	return (
    <Wrap>
      <DataWrap>
        <ImageWrap>
          <Logos.NoDBImage />
        </ImageWrap>
        <InfoWrap>
          <TextWrap>
            <Title>Нет соединения с сервером!</Title>
            <Description>Технические работы уже ведутся.</Description>
          </TextWrap>
          <ButtonsWrap>
            <RefreshButton><span>Обновить</span></RefreshButton>
            <ConnectButton><span>Связаться</span></ConnectButton>
          </ButtonsWrap>
        </InfoWrap>
      </DataWrap>
    </Wrap>
	)
}

export default CustomEmpty

const Wrap = styled.div`
  /* Frame 427321998 */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 329px;

  background: #FFFFFF;
  border-radius: 5px;
`

const DataWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 62px;

  width: 471px;
  height: 127px;
`

const InfoWrap = styled.div`
    /* Frame 427321959 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;

  width: 273px;
  height: 121px;


  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;

`

const TextWrap = styled.div`
    /* Frame 427322016 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 14px;

  width: 273px;
  height: 49px;


  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

`
const Title = styled.div`
    /* Нет соединения с сервером! */

  width: 273px;
  height: 20px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  /* identical to box height, or 111% */

  /* Текст/#333333 текст|кнопки */
  color: #333333;


  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

`
const Description = styled.div`
    /* Технические работы уже ведутся. */

  width: 273px;
  height: 15px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  /* identical to box height, or 107% */
  display: flex;
  align-items: center;

  /* Текст/#8F8F8F текст */
  color: #8F8F8F;


  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

`
const ButtonsWrap = styled.div`
    /* Frame 11626 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 26px;

  width: 273px;
  height: 40px;


  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;

`
const RefreshButton = styled.div`
  /* Frame 37 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 54px;
  gap: 10px;
  cursor: pointer;
  width: 175px;
  height: 40px;

  /* Текст/#A7388E текст|кнопки */
  background: #A7388E;
  border-radius: 5px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;

  & span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    align-items: center;
    color: #FFFFFF;
  }
`
const ConnectButton = styled.div`
  /* Связаться */

  width: 72px;
  height: 28px;

  /* Текст */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height, or 200% */
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  /* Текст/#8F8F8F текст */
  color: #8F8F8F;


  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
`

const ImageWrap = styled.div``