import {
    createSlice,
    PayloadAction,
    ActionReducerMapBuilder,
} from "@reduxjs/toolkit";
import {IAuthState, IRespLogin, ITokens} from "../types";
import {RootState} from "../../../config/store";
import {authLogin, getMe} from "../../../api/thunks/auth";
import {ENotificationStatus} from "../../../utils/enums";
import {sendNotification} from "../../../utils/functions";

const initialState: IAuthState = {
    token: null,
    user: null,
    shop: null,
    message: "",
    isShowErrMess: false,
    loading: "idle",
    error: null,
};

const authSlice = createSlice({
    name: "@@auth",
    initialState,
    reducers: {
        updateToken: (state: IAuthState, action: PayloadAction<ITokens>) => {
            state.token = action.payload.accessToken;
        },
        handleRemoveToken: (state: IAuthState) => {
            state.token = null;
            state.user = null;
            state.shop = null;
        },

        setError: (state, {payload}: PayloadAction<string>) => {
            state.error = payload;
            sendNotification(payload, ENotificationStatus.ERROR, "top-center");
        },
        handleErrMessage: (
            state: IAuthState,
            {payload}: PayloadAction<boolean>
        ) => {
            state.isShowErrMess = payload;
        },

        clearToken: (state) => {
            state.token = null;
        },

        clearAuthState: () => {
            return initialState;
        },
    },
    extraReducers: (builder: ActionReducerMapBuilder<IAuthState>) => {
        builder

            .addCase(
                authLogin.fulfilled,
                (state: IAuthState, {payload}: PayloadAction<IRespLogin>) => {
                    state.token = payload.token.accessToken;
                    state.user = payload.user;
                    state.shop = payload.shops[0];
                }
            )
            .addCase(
                getMe.fulfilled,
                (state: IAuthState, {payload}: PayloadAction<IRespLogin>) => {
                    state.token = payload.token.accessToken;
                    state.user = payload.user;
                    state.shop = payload.shops[0];
                }
            )

            .addMatcher(
                (action) => action.type.endsWith("/pending"),
                (state) => {
                    state.loading = "loading";
                    state.error = null;
                }
            )
            .addMatcher(
                (action) => action.type.endsWith("/rejected"),
                (state) => {
                    state.loading = "idle";
                    state.isShowErrMess = true;
                }
            )
            .addMatcher(
                (action) => action.type.endsWith("/fulfilled"),
                (state) => {
                    state.loading = "idle";
                }
            );
    },
});

export const {
    updateToken,
    handleRemoveToken,
    clearAuthState,
    setError,
    clearToken,
} = authSlice.actions;
export const authReducer = authSlice.reducer;

export const authInfoSelect = (state: RootState) => state.auth;
