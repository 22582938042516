import { configureStore, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { rootReducer } from './root.reducer';

export type RejectValue = {
  data: Error | AxiosError<unknown>;
  globalError: boolean;
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: { client: axios },
      },
    }),
  devTools: (process.env.NODE_ENV || '').trim() !== 'production',
});

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: null | RejectValue;
}>();

export type StoreType = typeof store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
