import React, {useState, useEffect, FC, useCallback} from "react";
import {Table, Select, Checkbox, notification, Grid} from "antd";
import type {TableProps} from "antd";
import ClientModal from "./components/ClientModal";
import {Icons} from "../../assets";
import PushNotifyModal from "./components/content/PushNotifyModal";
import {IPaginatedProps, TableParams} from "../../types";
import {
    limitCardChanges,
    limitCardTrans,
    limitClients,
    optionsSelect,
} from "../../utils/const";
import {useDispatch, useSelector} from "react-redux";
import {authInfoSelect} from "../../entities/auth";
import {AppDispatch} from "../../config/store";
import {IClientsQuery} from "../../entities/client/model/types";
import {getClients, getTopStats} from "../../api/thunks/clients";
import {AnyObject} from "antd/es/_util/type";
import {
    clientSelect,
    setBloket,
    setRemove,
    setSelectedClient,
} from "../../entities/client";
import {ITemaplate} from "../../entities/card/types";
import {useDebounce} from "../../hooks/useDebounce";
import {EClientsAction} from "../../utils/enums";
import HeaderInfo from "./components/HeaderInfo";
import {cssOverride} from "../../utils/const";
import {PuffLoader} from "react-spinners";
import {getClientColumns} from "./components/content/clientsColumns";
import {
    blockClientRequest,
    deleteClientRequest,
    unBlockClientRequest,
} from "../../api/services/clientsApi";
import {
    checkIsResponseStatus,
    openNotificationWithIcon,
} from "../../utils/functions";
import {styled} from "styled-components";
import {getTemplates} from "../../api/thunks/notify";
import {
    getAllCardsSpendStats,
    getClientCardChanges,
    getClientCards,
    getClientCardTransactions,
} from "../../api/thunks/card";
import {cardSelect} from "../../entities/card";

const ClientsPage: FC = () => {
    const {shop} = useSelector(authInfoSelect);
    const {
        selectValue: selectTopStats,
        total,
        clients,
        loading,
        selectedClient,
    } = useSelector(clientSelect);
    const {transactionCount, cardSpendStats, changesCount} =
        useSelector(cardSelect);
    const [openedSettingsPopup, setOpenedSettingsPopup] =
        useState<boolean>(false);
    const [selectedClientData, setSelectedClientData] = useState<{
        client_id: React.Key[];
        template_id: number[];
    }>({client_id: [], template_id: []});
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [searchText, setSearchText] = useState<string>("");
    const [selectValue, setSelectValue] = useState<number>(1);
    const [api, contextHolder] = notification.useNotification();

    const [transfPagination, setTransfPagination] = useState<IPaginatedProps>({
        page: 1,
        limit: limitCardTrans,
    });
    const [changesPagination, setChangesPagination] = useState<IPaginatedProps>(
        {
            page: 1,
            limit: limitCardChanges,
        }
    );

    const [selectAction, setSelectAction] = useState<EClientsAction>(
        EClientsAction.ACTIVE
    );
    const [show, setShow] = useState<boolean>(false);
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {current: 1, pageSize: limitClients},
        field: null,
        order: null,
        filters: {isActive: null},
    });

    const dispatch = useDispatch<AppDispatch>();
    const isPushNotify = selectAction === EClientsAction.SEND_NOTIFICATION;
    const debouncedSearch = useDebounce(searchText);

    useEffect(() => {
        if (shop) {
            const {current, pageSize} = tableParams.pagination!;
            const request: IClientsQuery = {
                limit: pageSize!,
                offset: (current! - 1) * pageSize!,
            };

            if (debouncedSearch) {
                request.anyOf = debouncedSearch;
            }

            if (tableParams?.field && tableParams?.order) {
                request.order = `${
                    tableParams.field === "fio" ? "lastname" : tableParams.field
                },${tableParams.order === "ascend" ? "asc" : "desc"}`;
            }

            let statusFilter = tableParams?.filters;

            if (Array.isArray(statusFilter.isActive)) {
                request.isActive = statusFilter.isActive[0] as React.Key;
            }
            if (Array.isArray(statusFilter.sex)) {
                request.sex = statusFilter.sex[0] as React.Key;
            }

            dispatch(getClients({shop_id: shop.id, params: request}));
        }
    }, [dispatch, tableParams, shop, debouncedSearch]);

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: AnyObject[]) => {
            const templatesIds = selectedRows.flatMap((row) =>
                row?.templates?.map((template: ITemaplate) => template.id)
            );
            setSelectedRowKeys(selectedRowKeys);
            setSelectedClientData({
                template_id: templatesIds,
                client_id: [],
            });
        },
    };

    const handleTableChange: TableProps["onChange"] = (
        pagination,
        filters,
        sorter
    ) => {
        if (Array.isArray(sorter)) {
            sorter = sorter[0];
        }

        setTableParams({
            pagination,
            filters,
            field: sorter?.order ? sorter.field : null,
            order: sorter?.order ?? null,
        });
    };

    const overlayOnClick = () => {
        setOpenedSettingsPopup(false);
    };

    const handlerClickId = useCallback(
        (id: number, e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
            e.preventDefault();
            const findClient = clients?.find((o) => o.id === id);
            if (findClient) {
                dispatch(setSelectedClient(findClient));
                setOpenedSettingsPopup(true);
            }
        },
        [clients, dispatch]
    );

    const handleCloseNotifyModal = () => {
        setShow(false);
        setSelectAction(EClientsAction.BLOCK);
        setSelectValue(1);
    };

    const handleOpenNotifyModal = () => {
        setSelectAction(EClientsAction.SEND_NOTIFICATION);
        setShow(true);
    };

    useEffect(() => {
        if (shop) {
            dispatch(
                getTopStats({period: selectTopStats.value, shop_id: shop.id})
            );
            dispatch(getTemplates(shop.id));
        }
    }, [selectTopStats, dispatch, shop]);

    useEffect(() => {
        if (shop && selectedClient) {
            const data = {client_id: selectedClient.id, shop_id: shop.id};
            const {limit, page} = transfPagination;
            const query = {limit, offset: (page - 1) * limit};

            dispatch(
                getClientCardTransactions({
                    ...data,
                    query,
                })
            );
        }
    }, [shop, selectedClient, dispatch, transfPagination]);

    useEffect(() => {
        if (shop && selectedClient) {
            const data = {client_id: selectedClient.id, shop_id: shop.id};
            const {limit, page} = changesPagination;
            const query = {limit, offset: (page - 1) * limit};

            dispatch(getClientCardChanges({...data, query}));
        }
    }, [shop, selectedClient, dispatch, changesPagination]);

    useEffect(() => {
        if (shop && selectedClient) {
            const data = {client_id: selectedClient.id, shop_id: shop.id};
            dispatch(getClientCards(data));
        }
    }, [shop, selectedClient, dispatch]);

    useEffect(() => {
        if (!cardSpendStats && shop && selectedClient) {
            dispatch(
                getAllCardsSpendStats({
                    shop_id: shop.id,
                    client_id: selectedClient.id,
                })
            );
        }
    }, [shop, selectedClient, cardSpendStats, dispatch]);

    const onChangeSelect = (value: number) => {
        setSelectValue(value);
        setSelectAction(optionsSelect[value - 1].label);
        setShow(false);
    };

    const loadMore = () => {
        if (transactionCount > transfPagination.page * transfPagination.limit) {
            const newPage = transfPagination.page + 1;

            setTransfPagination((prev) => ({
                ...prev,
                page: newPage,
            }));
        }
    };

    const loadMoreCardChanges = () => {
        if (changesCount > changesPagination.page * changesPagination.limit) {
            const newPage = changesPagination.page + 1;

            setChangesPagination((prev) => ({
                ...prev,
                page: newPage,
            }));
        }
    };

    const onActionsToogler = () => {
        if (selectedRowKeys && selectedRowKeys.length > 0 && shop) {
            selectedRowKeys.forEach((id) => {
                if (selectValue === 1) {
                    blockClientRequest(id, shop.id)
                        .then((res) => {
                            if (checkIsResponseStatus(res.status)) {
                                dispatch(
                                    setBloket({id: Number(id), isActive: false})
                                );
                                openNotificationWithIcon(
                                    api,
                                    "info",
                                    "Ок",
                                    `Клиент ${id} заблокирован`
                                );
                            }
                        })
                        .catch((err) =>
                            openNotificationWithIcon(
                                api,
                                "error",
                                "Error",
                                err.response.data.message
                            )
                        );
                } else if (selectValue === 2) {
                    unBlockClientRequest(id, shop.id)
                        .then((res) => {
                            if (checkIsResponseStatus(res.status)) {
                                dispatch(
                                    setBloket({id: Number(id), isActive: true})
                                );
                                openNotificationWithIcon(
                                    api,
                                    "success",
                                    "Ок",
                                    `Клиент ${id} активирован`
                                );
                            }
                        })
                        .catch((err) =>
                            openNotificationWithIcon(
                                api,
                                "error",
                                "Error",
                                err.response.data.message
                            )
                        );
                } else if (selectValue === 6) {
                    deleteClientRequest(id, shop.id)
                        .then((res) => {
                            if (checkIsResponseStatus(res.status)) {
                                dispatch(setRemove(Number(id)));
                                openNotificationWithIcon(
                                    api,
                                    "error",
                                    "Ок",
                                    `Клиент ${id} удален`
                                );
                            }
                        })
                        .catch((err) =>
                            openNotificationWithIcon(
                                api,
                                "error",
                                "Error",
                                err.response.data.message
                            )
                        );
                } else if (selectValue === 3) {
                    setShow(true);
                }
            });
        }
    };

    const {useBreakpoint} = Grid;
    const screens = useBreakpoint();

    const renderTableFooter = () => {
        return (
            <FooterWrap>
                <ActionWrap>
                    <StyledSelect
                        options={optionsSelect}
                        value={selectValue}
                        onChange={(val) => onChangeSelect(val as number)}
                    />
                    <StyledActionButton onClick={() => onActionsToogler()}>
                        <span>Применить</span>
                    </StyledActionButton>
                    <StyledCheckbox>
                        <span>для всех</span>
                    </StyledCheckbox>
                </ActionWrap>
            </FooterWrap>
        );
    };

    const columns = getClientColumns(handlerClickId, screens);

    if (
        loading === "loading" &&
        !tableParams.order &&
        !Array.isArray(tableParams.filters.isActive) &&
        !Array.isArray(tableParams.filters.sex) &&
        !openedSettingsPopup
    ) {
        return <PuffLoader color="#E65E20" cssOverride={cssOverride} />;
    }

    return (
        <Wrapper>
            {contextHolder}
            <Header>
                <HeaderTitle>Клиенты</HeaderTitle>
            </Header>
            <HeaderInfo />
            <SearchWrap>
                <SearchInput
                    placeholder="Поиск..."
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                />
                <ButtonBlok>
                    <StyledButton>
                        <Icons.IconSearchVect1 />
                    </StyledButton>
                    <StyledButton>
                        <Icons.IconSearchVect2 />
                    </StyledButton>
                    <StyledButton>
                        <Icons.IconSearchVect3 />
                    </StyledButton>
                    <StyledButton onClick={handleOpenNotifyModal}>
                        <Icons.IconSearchVect4 />
                    </StyledButton>
                </ButtonBlok>
            </SearchWrap>
            <TableWrapper>
                <Table
                    size="small"
                    scroll={{x: 1200}}
                    rowSelection={rowSelection}
                    columns={columns}
                    rowKey={(rec) => rec.id}
                    dataSource={clients}
                    onChange={handleTableChange}
                    pagination={{
                        current: tableParams.pagination?.current,
                        pageSize: tableParams.pagination?.pageSize,
                        total,
                    }}
                    footer={renderTableFooter}
                />
            </TableWrapper>
            <Overlay
                onClick={overlayOnClick}
                display={
                    openedSettingsPopup || (show && isPushNotify)
                        ? "block"
                        : "none"
                }
            />
            {openedSettingsPopup && (
                <ClientModal
                    loadMoreCardChanges={loadMoreCardChanges}
                    loadMore={loadMore}
                    setOpeningSettingsPopup={setOpenedSettingsPopup}
                />
            )}
            {isPushNotify && show && (
                <PushNotifyModal
                    selectedClientData={selectedClientData}
                    modalVisibilityToggler={() => handleCloseNotifyModal()}
                />
            )}
        </Wrapper>
    );
};

export default ClientsPage;

const FooterWrap = styled.div`
    display: flex;
    flex-direction: raw;
`;

const ActionWrap = styled.div`
    display: flex;
    flex-direction: raw;
    justify-content: flex-start;
    gap: 10px;
`;

const StyledSelect = styled(Select)`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 175px;
    height: 38px;
`;
const StyledActionButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    gap: 10px;
    width: 98px;
    height: 38px;
    background: #a7388e;
    border-radius: 5px;
    border: none;

    cursor: pointer;
    & span {
        width: 78px;
        height: 28px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 28px;
        display: flex;
        align-items: center;
        color: #f7f7f7;
        flex: none;
        order: 0;
        flex-grow: 0;
    }
`;
const StyledCheckbox = styled(Checkbox)`
    display: flex;
    align-items: center;
`;

const SearchWrap = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;
const SearchInput = styled.input`
    width: 87%;
    padding-left: 20px;
    height: 33px;
    background: #ffffff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    border: none;
`;
const ButtonBlok = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 5px;
`;
const StyledButton = styled.button`
    width: 33px;
    height: 33px;
    background: #cd6ee5;
    box-sizing: border-box;
    border: 0.6875px solid #cd6ee5;
    box-shadow: -1.375px 1.375px 4.2625px 1.375px rgba(0, 0, 0, 0.24);
    border-radius: 2.75px;
    padding: 5px;
    cursor: pointer;
`;

const TableWrapper = styled.div`
    margin-top: 10px;
    max-width: 1500px;
    width: 100%;

    .ant-table-thead {
        height: 40px;
        background: rgba(148, 129, 205, 0.25);
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
        border-radius: 5px 5px 0px 0px;
    }
    .ant-table-thead > tr > th::before {
        content: none !important;
    }
    .ant-table-cell {
        border-right: none !important;
        border-left: none !important;
        font-size: 14px !important;
    }

    .ant-table-row .ant-table-cell {
        height: 40px !important;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
        align-items: center;
        color: #333333;

        & svg {
            margin-top: 9px;
        }
    }

    & td.leftAlign {
        text-align: left !important;
        padding-left: 5px !important;
    }

    & th.headerCenter {
        text-align: center !important;
    }

    .ant-table-header {
        overflow: hidden !important;
        .ant-table-fixed {
            .ant-table-thead > tr > th {
                background: #fafafa;
                z-index: 3;
                box-shadow: 1px 0 0 0 #f0f0f0;
                &::after {
                    content: "";
                    position: absolute;
                    right: -1px;
                    top: 0;
                    bottom: 0;
                    width: 1px;
                    background: #f0f0f0;
                    z-index: 4;
                }
            }
        }
    }
    @media (max-width: 575px) {
        .ant-table-cell-fix-left {
            background: rgba(148, 129, 205, 1);
        }

        .ant-table-thead .ant-table-cell-fix-left {
            background: rgba(148, 129, 205, 1);
            border-right: none !important;
            width: 7%;
        }
    }
`;

const HeaderTitle = styled.div`
    margin-top: 38px;
    width: 110px;
    height: 28px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 28px;
    align-items: center;
    color: #333333;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    top: 0px;
    gap: 16px;
    background-color: #f7f7f7;

    .ant-table-row {
        height: 14px !important;
        padding: 2px !important;
    }

    .ant-table-cell {
        height: 14px !important;
        padding: 2px !important;
    }

    .ant-table-tbody > tr > td {
        background-color: #ffffff;
        font-family: Inter;
        border: 1px solid #efefef;
        color: #333333;
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
        text-align: left;
        padding: 2px;
        height: 12px;
    }

    .ant-table-summary {
        background-color: #9481cd40;
        font-family: "Inter";
        font-size: 14px;
        font-weight: 600;
        line-height: 16.8px;
        text-align: left;
    }

    .ant-table-thead > tr > th {
        background-color: #9481cd40;
        font-family: "Inter";
        font-size: 12px;
        font-weight: 600;
        line-height: 13.2px;
        text-align: left;
    }
`;

const Header = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    top: 0px;
    margin-bottom: 20px;
`;

const Overlay = styled.div<{
    display: string;
}>`
    display: ${({display}) => display};
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(1px);
`;
