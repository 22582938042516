interface IPromouters {
  id: number,
  fio: string,
  commi: number,
  cntTables: number,
  profit: number,
  cleanProfit?: number,
  bonus?: number,
  sex?: boolean,
  createdAt?: string,
  phone?: string,
  birthdate: string,
  status: number,
}

export const promoutersData: IPromouters[] = [
  {id: 310028, createdAt: '19.06.2024', phone: '+79021230000', birthdate: '23.05.1983',  fio: 'Скорцова Светлана', commi: 15, cntTables: 12, profit: 78000, bonus: 23000, status: 0 },
  {id: 310128, createdAt: '19.06.2024', phone: '+79021230000', birthdate: '23.05.1983',  fio: 'Скорцова Светлана', commi: 15, cntTables: 12, profit: 78000, bonus: 23000, status: 1 },
  {id: 310228, createdAt: '19.06.2024', phone: '+79021230000', birthdate: '23.05.1983',  fio: 'Скорцова Светлана', commi: 15, cntTables: 12, profit: 78000, bonus: 23000, status: 2 },
  {id: 310328, createdAt: '19.06.2024', phone: '+79021230000', birthdate: '23.05.1983',  fio: 'Скорцова Светлана', commi: 15, cntTables: 12, profit: 78000, bonus: 23000, status: 0 },
  {id: 310428, createdAt: '19.06.2024', phone: '+79021230000', birthdate: '23.05.1983',  fio: 'Скорцова Светлана', commi: 15, cntTables: 12, profit: 78000, bonus: 23000, status: 1 },
  {id: 310528, createdAt: '19.06.2024', phone: '+79021230000', birthdate: '23.05.1983',  fio: 'Скорцова Светлана', commi: 15, cntTables: 12, profit: 78000, bonus: 23000, status: 2 },
  
]

export const data = [
  {
    key: 'Бронирование',
    range: ['2024-04-26', '2024-04-27', '2024-04-28', '2024-04-29', '2024-04-30', '2024-05-01', '2024-05-02', '2024-05-02'],
    values: [
      {
        key: 'Успешные',
        range: ['2024-04-26', '2024-04-27', '2024-04-28', '2024-04-29', '2024-04-30', '2024-05-01', '2024-05-02', '2024-05-02'],
        values: [8,11,12,9,18,18,12,11]
      },
      {
        key: 'Пропущенные',
        range: ['2024-04-26', '2024-04-27', '2024-04-28', '2024-04-29', '2024-04-30', '2024-05-01', '2024-05-02', '2024-05-02'],
        values: [2,3,4,2,1,1,1,2,3]
      },
      {
        key: 'Автоответчик',
        range: ['2024-04-26', '2024-04-27', '2024-04-28', '2024-04-29', '2024-04-30', '2024-05-01', '2024-05-02', '2024-05-02'],
        values: [2,6,3,12,1,11,7,4,3]
      },
      
    ]
  },
  {
    key: 'Акт-отдел',
    range: ['2024-04-26', '2024-04-27', '2024-04-28', '2024-04-29', '2024-04-30', '2024-05-01', '2024-05-02', '2024-05-02'],
    values: [
      {
        key: 'Успешные',
        range: ['2024-04-26', '2024-04-27', '2024-04-28', '2024-04-29', '2024-04-30', '2024-05-01', '2024-05-02', '2024-05-02'],
        values: [7,12,11,10,17,19,11,12]
      },
      {
        key: 'Пропущенные',
        range: ['2024-04-26', '2024-04-27', '2024-04-28', '2024-04-29', '2024-04-30', '2024-05-01', '2024-05-02', '2024-05-02'],
        values: [2,3,4,2,1,1,1,2,3]
      },
      {
        key: 'Автоответчик',
        range: ['2024-04-26', '2024-04-27', '2024-04-28', '2024-04-29', '2024-04-30', '2024-05-01', '2024-05-02', '2024-05-02'],
        values: [2,6,3,12,1,11,7,4,3]
      },
      
    ]
  },
  {
    key: 'Другое',
    range: ['2024-04-26', '2024-04-27', '2024-04-28', '2024-04-29', '2024-04-30', '2024-05-01', '2024-05-02', '2024-05-02'],
    values: [
      {
        key: 'Успешные',
        range: ['2024-04-26', '2024-04-27', '2024-04-28', '2024-04-29', '2024-04-30', '2024-05-01', '2024-05-02', '2024-05-02'],
        values: [7,12,11,10,17,19,11,12]
      },
      {
        key: 'Пропущенные',
        range: ['2024-04-26', '2024-04-27', '2024-04-28', '2024-04-29', '2024-04-30', '2024-05-01', '2024-05-02', '2024-05-02'],
        values: [2,3,4,2,1,1,1,2,3]
      },
      {
        key: 'Автоответчик',
        range: ['2024-04-26', '2024-04-27', '2024-04-28', '2024-04-29', '2024-04-30', '2024-05-01', '2024-05-02', '2024-05-02'],
        values: [8,11,12,9,18,18,12,11]
      },
      
    ]
  },
]

export const dataPromo = [
  {
    name: 'Матюшин Матвей',
    id: 121131,
    status: true,
    tableSales: 32,
    viruchka: 78000,
    zarabotok: 1000000,
    values: [
      {date: '2024-04-27', viruchka: 12000, zarabotok: 12000},
      {date: '2024-04-28', viruchka: 12000, zarabotok: 12000},
      {date: '2024-04-29', viruchka: 12000, zarabotok: 12000},
      {date: '2024-04-30', viruchka: 12000, zarabotok: 12000},
      {date: '2024-05-01', viruchka: 12000, zarabotok: 12000},
      {date: '2024-05-02', viruchka: 12000, zarabotok: 12000},
      {date: '2024-05-03', viruchka: 12000, zarabotok: 12000},
    ]
  },
  {
    name: 'Кристина Баракян',
    id: 708912,
    status: true,
    tableSales: 32,
    viruchka: 78000,
    zarabotok: 1000000,
    values: [
      {date: '2024-04-27', viruchka: 12000, zarabotok: 12000},
      {date: '2024-04-28', viruchka: 12000, zarabotok: 12000},
      {date: '2024-04-29', viruchka: 12000, zarabotok: 12000},
      {date: '2024-04-30', viruchka: 12000, zarabotok: 12000},
      {date: '2024-05-01', viruchka: 12000, zarabotok: 12000},
      {date: '2024-05-02', viruchka: 12000, zarabotok: 12000},
      {date: '2024-05-03', viruchka: 12000, zarabotok: 12000},
    ]
  },
  {
    name: 'Леонид Поустовский',
    id: 121234,
    status: true,
    tableSales: 32,
    viruchka: 78000,
    zarabotok: 1000000,
    values: [
      {date: '2024-04-27', viruchka: 12000, zarabotok: 12000},
      {date: '2024-04-28', viruchka: 12000, zarabotok: 12000},
      {date: '2024-04-29', viruchka: 12000, zarabotok: 12000},
      {date: '2024-04-30', viruchka: 12000, zarabotok: 12000},
      {date: '2024-05-01', viruchka: 12000, zarabotok: 12000},
      {date: '2024-05-02', viruchka: 12000, zarabotok: 12000},
      {date: '2024-05-03', viruchka: 12000, zarabotok: 12000},
    ]
  },
  {
    name: 'Магомед Рахим Оглы',
    id: 117131,
    status: true,
    tableSales: 32,
    viruchka: 78000,
    zarabotok: 1000000,
    values: [
      {date: '2024-04-27', viruchka: 12000, zarabotok: 12000},
      {date: '2024-04-28', viruchka: 12000, zarabotok: 12000},
      {date: '2024-04-29', viruchka: 12000, zarabotok: 12000},
      {date: '2024-04-30', viruchka: 12000, zarabotok: 12000},
      {date: '2024-05-01', viruchka: 12000, zarabotok: 12000},
      {date: '2024-05-02', viruchka: 12000, zarabotok: 12000},
      {date: '2024-05-03', viruchka: 12000, zarabotok: 12000},
    ]
  },
  {
    name: 'Ян Павлов',
    id: 121111,
    status: true,
    tableSales: 32,
    viruchka: 78000,
    zarabotok: 1000000,
    values: [
      {date: '2024-04-27', viruchka: 12000, zarabotok: 12000},
      {date: '2024-04-28', viruchka: 12000, zarabotok: 12000},
      {date: '2024-04-29', viruchka: 12000, zarabotok: 12000},
      {date: '2024-04-30', viruchka: 12000, zarabotok: 12000},
      {date: '2024-05-01', viruchka: 12000, zarabotok: 12000},
      {date: '2024-05-02', viruchka: 12000, zarabotok: 12000},
      {date: '2024-05-03', viruchka: 12000, zarabotok: 12000},
    ]
  },
]
