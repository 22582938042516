import { useState } from 'react';
import { Input, Button } from 'antd';

const NumericRangeFilter = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
  const [min, setMin] = useState(selectedKeys[0]?.min || '');
  const [max, setMax] = useState(selectedKeys[0]?.max || '');
  const handleSearch = () => {
    setSelectedKeys([{ min, max }]);
    confirm();
  };

  const handleReset = () => {
    setMin('');
    setMax('');
    clearFilters();
  };
 
  return (
    <div style={{ padding: 8 }}>
      <Input
        placeholder="От"
        value={min}
        onChange={e => setMin(e.target.value)}
        style={{ marginBottom: 8, display: 'block' }}
      />
      <Input
        placeholder="До"
        value={max}
        onChange={e => setMax(e.target.value)}
        style={{ marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={handleSearch}
        // icon="search"
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Поиск
      </Button>
      <Button onClick={handleReset} size="small" style={{ width: 90 }}>
        Сброс
      </Button>
    </div>
  );
};

export default NumericRangeFilter;