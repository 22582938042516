import React, {FC, useState} from "react";
import styled from "styled-components";
import moment from "moment";
import ChangeBonusForm from "./ChangeBonusForm";
import ChangeBalanceForm from "./ChangeBalanceForm";
import {useSelector} from "react-redux";
import {StyledTemplate} from "./StyledTemplate";
import {cardSelect} from "../../../../entities/card";

interface PropsClientModal {
    setOpeningSettingsPopup: (val: boolean) => void;
}

moment().format();

const ContentModalCards: FC<PropsClientModal> = ({setOpeningSettingsPopup}) => {
    const [showFormBalance, setShowFormBalance] = useState<boolean>(false);
    const [cardFormBalance, setCardFormBalance] = useState<number>(0);
    const [showFormBonus, setShowFormBonus] = useState<boolean>(false);
    const [cardFormBonus, setCardFormBonus] = useState<number>(0);
    const {clientCards} = useSelector(cardSelect);

    const toggleFormBalance = (id: number) => {
        setShowFormBonus(false);
        setShowFormBalance(!showFormBalance);
        setCardFormBalance(id);
    };

    const toggleFormBonus = (id: number) => {
        setShowFormBalance(false);
        setShowFormBonus(!showFormBonus);
        setCardFormBonus(id);
    };

    return (
        <Wrapper>
            <Hr />
            <StyledTableWrapper>
                <StyledTableHeader>
                    <HeaderTitle style={{width: "100px", paddingLeft: "5px"}}>
                        ID
                    </HeaderTitle>
                    <HeaderTitle style={{width: "100px"}}>Код</HeaderTitle>
                    <HeaderTitle style={{width: "120px"}}>Тип</HeaderTitle>
                    <HeaderTitle style={{width: "150px"}}>
                        Депозитный баланс
                    </HeaderTitle>
                    <HeaderTitle style={{width: "130px"}}>
                        Бонусный баланс
                    </HeaderTitle>
                </StyledTableHeader>
                <Br />
                <StyledTableBody>
                    {clientCards?.map((item, ind) => (
                        <React.Fragment key={`fragment1-${ind}`}>
                            <InfoWrap key={`infowrap1-${ind}`}>
                                <InfoCardNum key={`infocard1-${ind}`}>
                                    {item.id}
                                </InfoCardNum>
                                <InfoCardCode key={`infocardCode1-${ind}`}>
                                    {item?.code}
                                </InfoCardCode>
                                <InfoCardName key={`infoCardName1-${ind}`}>
                                    <StyledTemplate
                                        name={item?.template?.name as string}
                                        ind={ind}
                                    />
                                </InfoCardName>

                                <InfoSumm
                                    key={`infosumbalance-${ind}`}
                                    onClick={() => toggleFormBalance(item.id)}
                                >
                                    {item.balance
                                        ? new Intl.NumberFormat("ru-RU", {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                          }).format(Number(item.balance))
                                        : "0,00"}
                                </InfoSumm>

                                {showFormBonus && item.id === cardFormBonus && (
                                    <ChangeBonusForm
                                        card={item}
                                        setShowFormBonus={setShowFormBonus}
                                    />
                                )}

                                <InfoBalance
                                    key={`infosumbonus-${ind}`}
                                    onClick={() => toggleFormBonus(item.id)}
                                    style={{cursor: "pointer"}}
                                >
                                    {item.bonus
                                        ? new Intl.NumberFormat("ru-RU", {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                          }).format(Number(item.bonus))
                                        : "0,00"}
                                </InfoBalance>
                                {showFormBalance &&
                                    item.id === cardFormBalance && (
                                        <ChangeBalanceForm
                                            card={item}
                                            setShowFormBalance={
                                                setShowFormBalance
                                            }
                                        />
                                    )}
                            </InfoWrap>
                            <Br />
                        </React.Fragment>
                    ))}
                </StyledTableBody>
            </StyledTableWrapper>
            <StyledFooter>
                <StyledButtonClose
                    onClick={() => setOpeningSettingsPopup(false)}
                >
                    Закрыть
                </StyledButtonClose>
                <StyledButtonSave
                    onClick={() => setOpeningSettingsPopup(false)}
                >
                    <span>Сохранить</span>
                </StyledButtonSave>
            </StyledFooter>
        </Wrapper>
    );
};

export default ContentModalCards;

const CommonTd = styled.div`
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    width: 100%;
    display: flex;
    align-items: center;
    color: #333333;
`;

const StyledTableWrapper = styled.div`
    height: 643px;
`;

const StyledTableHeader = styled.div`
    display: flex;
    flex-direction: row nowrap;
    width: 100%;
    height: 46px;
    align-items: center;
    justify-content: space-between;
`;

const HeaderTitle = styled.div`
    height: 23px;
    text-align: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 23px;
    display: flex;
    align-items: center;
    color: #333333;
`;

const StyledTableBody = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 470px;
    overflow-y: auto;
    overflow-x: hidden;
`;

const InfoCardNum = styled(CommonTd)`
    max-width: 100px;
    padding-left: 5px;
`;
const InfoCardCode = styled(CommonTd)`
    max-width: 100px;
`;

const InfoCardName = styled(CommonTd)`
    width: 120px;
    color: #a7388e;
`;

const InfoSumm = styled(CommonTd)`
    width: 150px;
`;

const InfoBalance = styled(CommonTd)`
    width: 130px;
`;

const InfoWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 46px;
    align-items: center;
    justify-content: space-between;
`;

const StyledButtonClose = styled.div`
    width: 59px;
    height: 28px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #8f8f8f;
    cursor: pointer;
`;

const StyledButtonSave = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 58px;
    gap: 10px;
    cursor: pointer;
    width: 195px;
    height: 40px;
    background: #a7388e;
    border-radius: 5px;

    flex: none;
    order: 1;
    flex-grow: 0;

    & span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
        align-items: center;
        text-align: center;
        color: #ffffff;
    }
`;

const StyledFooter = styled.div`
    display: flex;
    gap: 34px;
    flex-direction: row;
    justify-content: flex-end;
    height: 40px;
    margin-top: 11px;
    align-items: center;
    position: absolute;
    bottom: 10px;
    right: 10px;
`;

const Hr = styled.hr`
    width: 675px;
    height: 0px;
    margin-bottom: 15px;
    border: 3px solid rgba(143, 143, 143, 0.3);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
`;

const Br = styled.hr`
    margin: 5px 0px;
    width: 675px;
    height: 0px;
    border: 1px solid rgba(143, 143, 143, 0.3);
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 675px;
    height: 524px;
    z-index: 1205;
    flex: none;
    flex-grow: 0;
`;
